:root {
  --colors-light-white: white;
  --colors-light-failure: #ED4B4B;
  --colors-light-failure33: #ED4B9E33;
  --colors-light-primary: #4690c2;
  --colors-light-primary0f: #1FC7D40f;
  --colors-light-primary3D: #1FC7D43D;
  --colors-light-primaryBright: #53DEE9;
  --colors-light-primaryDark: #0098A1;
  --colors-light-success: #31D0AA;
  --colors-light-success19: #31D0AA19;
  --colors-light-warning: #FFB237;
  --colors-light-warning2D: #ED4B9E2D;
  --colors-light-warning33: #ED4B9E33;
  --colors-light-binance: #F0B90B;
  --colors-light-overlay: #452a7a;
  --colors-light-gold: #FFC700;
  --colors-light-silver: #B2B2B2;
  --colors-light-bronze: #E7974D;
  --colors-light-secondary: #FFA814;
  --colors-light-secondary80: #7645D980;
  --colors-light-background: #FAF9FA;
  --colors-light-backgroundDisabled: #E9EAEB;
  --colors-light-backgroundAlt: #FFFFFF;
  --colors-light-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-light-backgroundAlt3: #131317;
  --colors-light-cardBorder: #E7E3EB;
  --colors-light-contrast: #191326;
  --colors-light-dropdown: #F6F6F6;
  --colors-light-dropdownDeep: #EEEEEE;
  --colors-light-invertedContrast: #FFFFFF;
  --colors-light-input: #eeeaf4;
  --colors-light-inputSecondary: #d7caec;
  --colors-light-inputThird: #131317;
  --colors-light-tertiary: #EFF4F5;
  --colors-light-text: #280D5F;
  --colors-light-text99: #280D5F99;
  --colors-light-textDisabled: #BDC2C4;
  --colors-light-textSubtle: #7A6EAA;
  --colors-light-disabled: #E9EAEB;
  --colors-light-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F0FFEF 100%);
  --colors-light-gradientInverseBubblegum: linear-gradient(139.73deg, #F0FFEF 0%, #E5FDFF 100%);
  --colors-light-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-light-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-light-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-light-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-light-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-light-gradientBold: linear-gradient(#53DEE9, #7645D9);
  --colors-light-gradientObsidian: linear-gradient(45deg, rgb(109, 42, 151), rgb(0, 138, 209) 80%);
  --colors-dark-white: white;
  --colors-dark-failure: #ED4B4B;
  --colors-dark-failure33: #ED4B9E33;
  --colors-dark-primary: #4690c2;
  --colors-dark-primary0f: #1FC7D40f;
  --colors-dark-primary3D: #1FC7D43D;
  --colors-dark-primaryBright: #53DEE9;
  --colors-dark-primaryDark: #0098A1;
  --colors-dark-success: #31D0AA;
  --colors-dark-success19: #31D0AA19;
  --colors-dark-warning: #FFB237;
  --colors-dark-warning2D: #ED4B9E2D;
  --colors-dark-warning33: #ED4B9E33;
  --colors-dark-binance: #F0B90B;
  --colors-dark-overlay: #452a7a;
  --colors-dark-gold: #FFC700;
  --colors-dark-silver: #B2B2B2;
  --colors-dark-bronze: #E7974D;
  --colors-dark-secondary: #3664A7;
  --colors-dark-secondary80: #9A6AFF80;
  --colors-dark-background: #08060B;
  --colors-dark-backgroundDisabled: #393742;
  --colors-dark-backgroundAlt: rgb(8 8 10);
  --colors-dark-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-dark-backgroundAlt3: #131317;
  --colors-dark-cardBorder: #383241;
  --colors-dark-contrast: #FFFFFF;
  --colors-dark-dropdown: #1E1D20;
  --colors-dark-dropdownDeep: #100C18;
  --colors-dark-invertedContrast: #191326;
  --colors-dark-input: #2E3035;
  --colors-dark-inputSecondary: #262130;
  --colors-dark-inputThird: #131317;
  --colors-dark-tertiary: #1b1b1f;
  --colors-dark-text: #F4EEFF;
  --colors-dark-text99: #F4EEFF99;
  --colors-dark-textDisabled: #666171;
  --colors-dark-textSubtle: #ADB1B8;
  --colors-dark-disabled: #524B63;
  --colors-dark-gradientBubblegum: #000;
  --colors-dark-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-dark-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #212630 100%);
  --colors-dark-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-dark-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-dark-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-dark-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-dark-gradientBold: linear-gradient(#53DEE9, #9A6AFF);
  --colors-dark-gradientObsidian: linear-gradient(45deg, rgb(109, 42, 151), rgb(0, 138, 209) 80%);
  --fonts-normal: 'Kanit', sans-serif;
  --fonts-mono: SFMono, ui-monospace, monospace;
  --space-0px: 0px;
  --space-1rem: 1rem;
  --space-1px: 1px;
  --space-2px: 2px;
  --space-6px: 6px;
  --space-4px: 4px;
  --space-8px: 8px;
  --space-12px: 12px;
  --space-14px: 14px;
  --space-16px: 16px;
  --space-20px: 20px;
  --space-24px: 24px;
  --space-32px: 32px;
  --space-48px: 48px;
  --space-56px: 56px;
  --space-64px: 64px;
  --borderWidths-0: 0px;
  --borderWidths-1: 1px;
  --borderWidths-2: 2px;
  --radii-0: 0px;
  --radii-8px: 8px;
  --radii-12px: 12px;
  --radii-20px: 20px;
  --radii-32px: 32px;
  --radii-small: 4px;
  --radii-default: 16px;
  --radii-card: 24px;
  --radii-circle: 50%;
  --fontSizes-10px: 10px;
  --fontSizes-12px: 12px;
  --fontSizes-16px: 16px;
  --fontSizes-14px: 14px;
  --fontSizes-20px: 20px;
  --fontSizes-40px: 40px;
  --shadows-level1: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  --shadows-active: 0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4);
  --shadows-success: 0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
  --shadows-warning: 0px 0px 0px 1px #D67E0A, 0px 0px 0px 4px rgba(214, 126, 10, 0.2);
  --shadows-danger: 0px 0px 0px 1px #ED4B4B, 0px 0px 0px 4px rgba(237, 75, 158, 0.2);
  --shadows-focus: 0px 0px 0px 0px #7645D9, 0px 0px 0px 0px rgba(118, 69, 217, 0);
  --shadows-inset: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
  --shadows-tooltip: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1);
}
[data-theme="light"] {
  --colors-white: white;
  --colors-failure: #ED4B4B;
  --colors-failure33: #ED4B9E33;
  --colors-primary: #4690c2;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #31D0AA;
  --colors-success19: #31D0AA19;
  --colors-warning: #FFB237;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #FFC700;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #FFA814;
  --colors-secondary80: #7645D980;
  --colors-background: #FAF9FA;
  --colors-backgroundDisabled: #E9EAEB;
  --colors-backgroundAlt: #FFFFFF;
  --colors-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-backgroundAlt3: #131317;
  --colors-cardBorder: #E7E3EB;
  --colors-contrast: #191326;
  --colors-dropdown: #F6F6F6;
  --colors-dropdownDeep: #EEEEEE;
  --colors-invertedContrast: #FFFFFF;
  --colors-input: #eeeaf4;
  --colors-inputSecondary: #d7caec;
  --colors-inputThird: #131317;
  --colors-tertiary: #EFF4F5;
  --colors-text: #280D5F;
  --colors-text99: #280D5F99;
  --colors-textDisabled: #BDC2C4;
  --colors-textSubtle: #7A6EAA;
  --colors-disabled: #E9EAEB;
  --colors-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F0FFEF 100%);
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #F0FFEF 0%, #E5FDFF 100%);
  --colors-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-gradientBold: linear-gradient(#53DEE9, #7645D9);
  --colors-gradientObsidian: linear-gradient(45deg, rgb(109, 42, 151), rgb(0, 138, 209) 80%);
}
[data-theme="dark"] {
  --colors-white: white;
  --colors-failure: #ED4B4B;
  --colors-failure33: #ED4B9E33;
  --colors-primary: #4690c2;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #31D0AA;
  --colors-success19: #31D0AA19;
  --colors-warning: #FFB237;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #FFC700;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #3664A7;
  --colors-secondary80: #9A6AFF80;
  --colors-background: #08060B;
  --colors-backgroundDisabled: #393742;
  --colors-backgroundAlt: rgb(8 8 10);
  --colors-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-backgroundAlt3: #131317;
  --colors-cardBorder: #383241;
  --colors-contrast: #FFFFFF;
  --colors-dropdown: #1E1D20;
  --colors-dropdownDeep: #100C18;
  --colors-invertedContrast: #191326;
  --colors-input: #2E3035;
  --colors-inputSecondary: #262130;
  --colors-inputThird: #131317;
  --colors-tertiary: #1b1b1f;
  --colors-text: #F4EEFF;
  --colors-text99: #F4EEFF99;
  --colors-textDisabled: #666171;
  --colors-textSubtle: #ADB1B8;
  --colors-disabled: #524B63;
  --colors-gradientBubblegum: #000;
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #212630 100%);
  --colors-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-gradientBold: linear-gradient(#53DEE9, #9A6AFF);
  --colors-gradientObsidian: linear-gradient(45deg, rgb(109, 42, 151), rgb(0, 138, 209) 80%);
}
._1a5xov70 {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
._1a5xov71 {
  background: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
._1a5xov72 {
  display: block;
}
._1a5xov73 {
  line-height: 1;
}
._1a5xov74 {
  list-style: none;
}
._1a5xov75 {
  quotes: none;
}
._1a5xov75:before, ._1a5xov75:after {
  content: '';
}
._1a5xov76 {
  border-collapse: collapse;
  border-spacing: 0;
}
._1a5xov77 {
  background-color: transparent;
  color: inherit;
}
._1a5xov78 {
  -webkit-appearance: none;
          appearance: none;
}
._1a5xov78::-ms-expand {
  display: none;
}
._1qhetbf0 {
  display: block;
}
._1qhetbf6 {
  display: flex;
}
._1qhetbfc {
  display: grid;
}
._1qhetbfi {
  display: inline;
}
._1qhetbfo {
  display: inline-flex;
}
._1qhetbfu {
  display: inline-block;
}
._1qhetbf10 {
  display: none;
}
._1qhetbf16 {
  flex-direction: column;
}
._1qhetbf1c {
  flex-direction: row;
}
._1qhetbf1i {
  flex-direction: column-reverse;
}
._1qhetbf1o {
  align-items: center;
}
._1qhetbf1u {
  align-items: end;
}
._1qhetbf20 {
  align-items: baseLine;
}
._1qhetbf26 {
  align-items: inherit;
}
._1qhetbf2c {
  align-items: flex-start;
}
._1qhetbf2i {
  align-items: center;
}
._1qhetbf2o {
  align-items: start;
}
._1qhetbf2u {
  align-items: flex-end;
}
._1qhetbf30 {
  align-items: stretch;
}
._1qhetbf36 {
  align-self: flex-start;
}
._1qhetbf3c {
  align-self: center;
}
._1qhetbf3i {
  align-self: start;
}
._1qhetbf3o {
  align-self: flex-end;
}
._1qhetbf3u {
  align-self: stretch;
}
._1qhetbf40 {
  flex-wrap: wrap;
}
._1qhetbf46 {
  flex-wrap: nowrap;
}
._1qhetbf4c {
  flex-grow: 1;
}
._1qhetbf4i {
  overflow: auto;
}
._1qhetbf4o {
  overflow: hidden;
}
._1qhetbf4u {
  overflow: scroll;
}
._1qhetbf50 {
  overflow: unset;
}
._1qhetbf56 {
  overflow-y: auto;
}
._1qhetbf5c {
  overflow-y: hidden;
}
._1qhetbf5i {
  overflow-y: scroll;
}
._1qhetbf5o {
  overflow-x: auto;
}
._1qhetbf5u {
  overflow-x: hidden;
}
._1qhetbf60 {
  overflow-x: scroll;
}
._1qhetbf66 {
  position: absolute;
}
._1qhetbf6c {
  position: fixed;
}
._1qhetbf6i {
  position: relative;
}
._1qhetbf6o {
  position: sticky;
}
._1qhetbf6u {
  text-align: center;
}
._1qhetbf70 {
  text-align: left;
}
._1qhetbf76 {
  text-align: right;
}
._1qhetbf7c {
  justify-content: flex-start;
}
._1qhetbf7i {
  justify-content: center;
}
._1qhetbf7o {
  justify-content: start;
}
._1qhetbf7u {
  justify-content: flex-end;
}
._1qhetbf80 {
  justify-content: stretch;
}
._1qhetbf86 {
  justify-content: space-around;
}
._1qhetbf8c {
  justify-content: space-between;
}
._1qhetbf8i {
  justify-items: flex-start;
}
._1qhetbf8o {
  justify-items: center;
}
._1qhetbf8u {
  justify-items: start;
}
._1qhetbf90 {
  justify-items: flex-end;
}
._1qhetbf96 {
  justify-items: stretch;
}
._1qhetbf9c {
  justify-items: space-around;
}
._1qhetbf9i {
  justify-items: space-between;
}
._1qhetbf9o {
  justify-self: flex-start;
}
._1qhetbf9u {
  justify-self: center;
}
._1qhetbfa0 {
  justify-self: start;
}
._1qhetbfa6 {
  justify-self: flex-end;
}
._1qhetbfac {
  justify-self: stretch;
}
._1qhetbfai {
  inset: 0px;
}
._1qhetbfao {
  height: var(--space-0px);
}
._1qhetbfau {
  height: var(--space-1rem);
}
._1qhetbfb0 {
  height: var(--space-1px);
}
._1qhetbfb6 {
  height: var(--space-2px);
}
._1qhetbfbc {
  height: var(--space-6px);
}
._1qhetbfbi {
  height: var(--space-4px);
}
._1qhetbfbo {
  height: var(--space-8px);
}
._1qhetbfbu {
  height: var(--space-12px);
}
._1qhetbfc0 {
  height: var(--space-14px);
}
._1qhetbfc6 {
  height: var(--space-16px);
}
._1qhetbfcc {
  height: var(--space-20px);
}
._1qhetbfci {
  height: var(--space-24px);
}
._1qhetbfco {
  height: var(--space-32px);
}
._1qhetbfcu {
  height: var(--space-48px);
}
._1qhetbfd0 {
  height: var(--space-56px);
}
._1qhetbfd6 {
  height: var(--space-64px);
}
._1qhetbfdc {
  height: 100%;
}
._1qhetbfdi {
  height: auto;
}
._1qhetbfdo {
  height: -moz-fit-content;
  height: fit-content;
}
._1qhetbfdu {
  height: 420px;
}
._1qhetbfe0 {
  height: 576px;
}
._1qhetbfe6 {
  height: 852px;
}
._1qhetbfec {
  height: 968px;
}
._1qhetbfei {
  height: 1080px;
}
._1qhetbfeo {
  height: 100vh;
}
._1qhetbfeu {
  margin-bottom: var(--space-0px);
}
._1qhetbff0 {
  margin-bottom: var(--space-1rem);
}
._1qhetbff6 {
  margin-bottom: var(--space-1px);
}
._1qhetbffc {
  margin-bottom: var(--space-2px);
}
._1qhetbffi {
  margin-bottom: var(--space-6px);
}
._1qhetbffo {
  margin-bottom: var(--space-4px);
}
._1qhetbffu {
  margin-bottom: var(--space-8px);
}
._1qhetbfg0 {
  margin-bottom: var(--space-12px);
}
._1qhetbfg6 {
  margin-bottom: var(--space-14px);
}
._1qhetbfgc {
  margin-bottom: var(--space-16px);
}
._1qhetbfgi {
  margin-bottom: var(--space-20px);
}
._1qhetbfgo {
  margin-bottom: var(--space-24px);
}
._1qhetbfgu {
  margin-bottom: var(--space-32px);
}
._1qhetbfh0 {
  margin-bottom: var(--space-48px);
}
._1qhetbfh6 {
  margin-bottom: var(--space-56px);
}
._1qhetbfhc {
  margin-bottom: var(--space-64px);
}
._1qhetbfhi {
  margin-bottom: auto;
}
._1qhetbfho {
  margin-left: var(--space-0px);
}
._1qhetbfhu {
  margin-left: var(--space-1rem);
}
._1qhetbfi0 {
  margin-left: var(--space-1px);
}
._1qhetbfi6 {
  margin-left: var(--space-2px);
}
._1qhetbfic {
  margin-left: var(--space-6px);
}
._1qhetbfii {
  margin-left: var(--space-4px);
}
._1qhetbfio {
  margin-left: var(--space-8px);
}
._1qhetbfiu {
  margin-left: var(--space-12px);
}
._1qhetbfj0 {
  margin-left: var(--space-14px);
}
._1qhetbfj6 {
  margin-left: var(--space-16px);
}
._1qhetbfjc {
  margin-left: var(--space-20px);
}
._1qhetbfji {
  margin-left: var(--space-24px);
}
._1qhetbfjo {
  margin-left: var(--space-32px);
}
._1qhetbfju {
  margin-left: var(--space-48px);
}
._1qhetbfk0 {
  margin-left: var(--space-56px);
}
._1qhetbfk6 {
  margin-left: var(--space-64px);
}
._1qhetbfkc {
  margin-left: auto;
}
._1qhetbfki {
  margin-right: var(--space-0px);
}
._1qhetbfko {
  margin-right: var(--space-1rem);
}
._1qhetbfku {
  margin-right: var(--space-1px);
}
._1qhetbfl0 {
  margin-right: var(--space-2px);
}
._1qhetbfl6 {
  margin-right: var(--space-6px);
}
._1qhetbflc {
  margin-right: var(--space-4px);
}
._1qhetbfli {
  margin-right: var(--space-8px);
}
._1qhetbflo {
  margin-right: var(--space-12px);
}
._1qhetbflu {
  margin-right: var(--space-14px);
}
._1qhetbfm0 {
  margin-right: var(--space-16px);
}
._1qhetbfm6 {
  margin-right: var(--space-20px);
}
._1qhetbfmc {
  margin-right: var(--space-24px);
}
._1qhetbfmi {
  margin-right: var(--space-32px);
}
._1qhetbfmo {
  margin-right: var(--space-48px);
}
._1qhetbfmu {
  margin-right: var(--space-56px);
}
._1qhetbfn0 {
  margin-right: var(--space-64px);
}
._1qhetbfn6 {
  margin-right: auto;
}
._1qhetbfnc {
  margin-top: var(--space-0px);
}
._1qhetbfni {
  margin-top: var(--space-1rem);
}
._1qhetbfno {
  margin-top: var(--space-1px);
}
._1qhetbfnu {
  margin-top: var(--space-2px);
}
._1qhetbfo0 {
  margin-top: var(--space-6px);
}
._1qhetbfo6 {
  margin-top: var(--space-4px);
}
._1qhetbfoc {
  margin-top: var(--space-8px);
}
._1qhetbfoi {
  margin-top: var(--space-12px);
}
._1qhetbfoo {
  margin-top: var(--space-14px);
}
._1qhetbfou {
  margin-top: var(--space-16px);
}
._1qhetbfp0 {
  margin-top: var(--space-20px);
}
._1qhetbfp6 {
  margin-top: var(--space-24px);
}
._1qhetbfpc {
  margin-top: var(--space-32px);
}
._1qhetbfpi {
  margin-top: var(--space-48px);
}
._1qhetbfpo {
  margin-top: var(--space-56px);
}
._1qhetbfpu {
  margin-top: var(--space-64px);
}
._1qhetbfq0 {
  margin-top: auto;
}
._1qhetbfq6 {
  margin: var(--space-0px);
}
._1qhetbfqc {
  margin: var(--space-1rem);
}
._1qhetbfqi {
  margin: var(--space-1px);
}
._1qhetbfqo {
  margin: var(--space-2px);
}
._1qhetbfqu {
  margin: var(--space-6px);
}
._1qhetbfr0 {
  margin: var(--space-4px);
}
._1qhetbfr6 {
  margin: var(--space-8px);
}
._1qhetbfrc {
  margin: var(--space-12px);
}
._1qhetbfri {
  margin: var(--space-14px);
}
._1qhetbfro {
  margin: var(--space-16px);
}
._1qhetbfru {
  margin: var(--space-20px);
}
._1qhetbfs0 {
  margin: var(--space-24px);
}
._1qhetbfs6 {
  margin: var(--space-32px);
}
._1qhetbfsc {
  margin: var(--space-48px);
}
._1qhetbfsi {
  margin: var(--space-56px);
}
._1qhetbfso {
  margin: var(--space-64px);
}
._1qhetbfsu {
  margin: auto;
}
._1qhetbft0 {
  padding: var(--space-0px);
}
._1qhetbft6 {
  padding: var(--space-1rem);
}
._1qhetbftc {
  padding: var(--space-1px);
}
._1qhetbfti {
  padding: var(--space-2px);
}
._1qhetbfto {
  padding: var(--space-6px);
}
._1qhetbftu {
  padding: var(--space-4px);
}
._1qhetbfu0 {
  padding: var(--space-8px);
}
._1qhetbfu6 {
  padding: var(--space-12px);
}
._1qhetbfuc {
  padding: var(--space-14px);
}
._1qhetbfui {
  padding: var(--space-16px);
}
._1qhetbfuo {
  padding: var(--space-20px);
}
._1qhetbfuu {
  padding: var(--space-24px);
}
._1qhetbfv0 {
  padding: var(--space-32px);
}
._1qhetbfv6 {
  padding: var(--space-48px);
}
._1qhetbfvc {
  padding: var(--space-56px);
}
._1qhetbfvi {
  padding: var(--space-64px);
}
._1qhetbfvo {
  max-height: var(--space-0px);
}
._1qhetbfvu {
  max-height: var(--space-1rem);
}
._1qhetbfw0 {
  max-height: var(--space-1px);
}
._1qhetbfw6 {
  max-height: var(--space-2px);
}
._1qhetbfwc {
  max-height: var(--space-6px);
}
._1qhetbfwi {
  max-height: var(--space-4px);
}
._1qhetbfwo {
  max-height: var(--space-8px);
}
._1qhetbfwu {
  max-height: var(--space-12px);
}
._1qhetbfx0 {
  max-height: var(--space-14px);
}
._1qhetbfx6 {
  max-height: var(--space-16px);
}
._1qhetbfxc {
  max-height: var(--space-20px);
}
._1qhetbfxi {
  max-height: var(--space-24px);
}
._1qhetbfxo {
  max-height: var(--space-32px);
}
._1qhetbfxu {
  max-height: var(--space-48px);
}
._1qhetbfy0 {
  max-height: var(--space-56px);
}
._1qhetbfy6 {
  max-height: var(--space-64px);
}
._1qhetbfyc {
  max-width: var(--space-0px);
}
._1qhetbfyi {
  max-width: var(--space-1rem);
}
._1qhetbfyo {
  max-width: var(--space-1px);
}
._1qhetbfyu {
  max-width: var(--space-2px);
}
._1qhetbfz0 {
  max-width: var(--space-6px);
}
._1qhetbfz6 {
  max-width: var(--space-4px);
}
._1qhetbfzc {
  max-width: var(--space-8px);
}
._1qhetbfzi {
  max-width: var(--space-12px);
}
._1qhetbfzo {
  max-width: var(--space-14px);
}
._1qhetbfzu {
  max-width: var(--space-16px);
}
._1qhetbf100 {
  max-width: var(--space-20px);
}
._1qhetbf106 {
  max-width: var(--space-24px);
}
._1qhetbf10c {
  max-width: var(--space-32px);
}
._1qhetbf10i {
  max-width: var(--space-48px);
}
._1qhetbf10o {
  max-width: var(--space-56px);
}
._1qhetbf10u {
  max-width: var(--space-64px);
}
._1qhetbf110 {
  max-width: 100%;
}
._1qhetbf116 {
  max-width: auto;
}
._1qhetbf11c {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
._1qhetbf11i {
  max-width: 420px;
}
._1qhetbf11o {
  max-width: 576px;
}
._1qhetbf11u {
  max-width: 852px;
}
._1qhetbf120 {
  max-width: 968px;
}
._1qhetbf126 {
  max-width: 1080px;
}
._1qhetbf12c {
  max-width: none;
}
._1qhetbf12i {
  min-height: var(--space-0px);
}
._1qhetbf12o {
  min-height: var(--space-1rem);
}
._1qhetbf12u {
  min-height: var(--space-1px);
}
._1qhetbf130 {
  min-height: var(--space-2px);
}
._1qhetbf136 {
  min-height: var(--space-6px);
}
._1qhetbf13c {
  min-height: var(--space-4px);
}
._1qhetbf13i {
  min-height: var(--space-8px);
}
._1qhetbf13o {
  min-height: var(--space-12px);
}
._1qhetbf13u {
  min-height: var(--space-14px);
}
._1qhetbf140 {
  min-height: var(--space-16px);
}
._1qhetbf146 {
  min-height: var(--space-20px);
}
._1qhetbf14c {
  min-height: var(--space-24px);
}
._1qhetbf14i {
  min-height: var(--space-32px);
}
._1qhetbf14o {
  min-height: var(--space-48px);
}
._1qhetbf14u {
  min-height: var(--space-56px);
}
._1qhetbf150 {
  min-height: var(--space-64px);
}
._1qhetbf156 {
  min-height: 100%;
}
._1qhetbf15c {
  min-height: auto;
}
._1qhetbf15i {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
._1qhetbf15o {
  min-height: 420px;
}
._1qhetbf15u {
  min-height: 576px;
}
._1qhetbf160 {
  min-height: 852px;
}
._1qhetbf166 {
  min-height: 968px;
}
._1qhetbf16c {
  min-height: 1080px;
}
._1qhetbf16i {
  min-height: 100vh;
}
._1qhetbf16o {
  min-width: var(--space-0px);
}
._1qhetbf16u {
  min-width: var(--space-1rem);
}
._1qhetbf170 {
  min-width: var(--space-1px);
}
._1qhetbf176 {
  min-width: var(--space-2px);
}
._1qhetbf17c {
  min-width: var(--space-6px);
}
._1qhetbf17i {
  min-width: var(--space-4px);
}
._1qhetbf17o {
  min-width: var(--space-8px);
}
._1qhetbf17u {
  min-width: var(--space-12px);
}
._1qhetbf180 {
  min-width: var(--space-14px);
}
._1qhetbf186 {
  min-width: var(--space-16px);
}
._1qhetbf18c {
  min-width: var(--space-20px);
}
._1qhetbf18i {
  min-width: var(--space-24px);
}
._1qhetbf18o {
  min-width: var(--space-32px);
}
._1qhetbf18u {
  min-width: var(--space-48px);
}
._1qhetbf190 {
  min-width: var(--space-56px);
}
._1qhetbf196 {
  min-width: var(--space-64px);
}
._1qhetbf19c {
  padding-bottom: var(--space-0px);
}
._1qhetbf19i {
  padding-bottom: var(--space-1rem);
}
._1qhetbf19o {
  padding-bottom: var(--space-1px);
}
._1qhetbf19u {
  padding-bottom: var(--space-2px);
}
._1qhetbf1a0 {
  padding-bottom: var(--space-6px);
}
._1qhetbf1a6 {
  padding-bottom: var(--space-4px);
}
._1qhetbf1ac {
  padding-bottom: var(--space-8px);
}
._1qhetbf1ai {
  padding-bottom: var(--space-12px);
}
._1qhetbf1ao {
  padding-bottom: var(--space-14px);
}
._1qhetbf1au {
  padding-bottom: var(--space-16px);
}
._1qhetbf1b0 {
  padding-bottom: var(--space-20px);
}
._1qhetbf1b6 {
  padding-bottom: var(--space-24px);
}
._1qhetbf1bc {
  padding-bottom: var(--space-32px);
}
._1qhetbf1bi {
  padding-bottom: var(--space-48px);
}
._1qhetbf1bo {
  padding-bottom: var(--space-56px);
}
._1qhetbf1bu {
  padding-bottom: var(--space-64px);
}
._1qhetbf1c0 {
  padding-left: var(--space-0px);
}
._1qhetbf1c6 {
  padding-left: var(--space-1rem);
}
._1qhetbf1cc {
  padding-left: var(--space-1px);
}
._1qhetbf1ci {
  padding-left: var(--space-2px);
}
._1qhetbf1co {
  padding-left: var(--space-6px);
}
._1qhetbf1cu {
  padding-left: var(--space-4px);
}
._1qhetbf1d0 {
  padding-left: var(--space-8px);
}
._1qhetbf1d6 {
  padding-left: var(--space-12px);
}
._1qhetbf1dc {
  padding-left: var(--space-14px);
}
._1qhetbf1di {
  padding-left: var(--space-16px);
}
._1qhetbf1do {
  padding-left: var(--space-20px);
}
._1qhetbf1du {
  padding-left: var(--space-24px);
}
._1qhetbf1e0 {
  padding-left: var(--space-32px);
}
._1qhetbf1e6 {
  padding-left: var(--space-48px);
}
._1qhetbf1ec {
  padding-left: var(--space-56px);
}
._1qhetbf1ei {
  padding-left: var(--space-64px);
}
._1qhetbf1eo {
  padding-right: var(--space-0px);
}
._1qhetbf1eu {
  padding-right: var(--space-1rem);
}
._1qhetbf1f0 {
  padding-right: var(--space-1px);
}
._1qhetbf1f6 {
  padding-right: var(--space-2px);
}
._1qhetbf1fc {
  padding-right: var(--space-6px);
}
._1qhetbf1fi {
  padding-right: var(--space-4px);
}
._1qhetbf1fo {
  padding-right: var(--space-8px);
}
._1qhetbf1fu {
  padding-right: var(--space-12px);
}
._1qhetbf1g0 {
  padding-right: var(--space-14px);
}
._1qhetbf1g6 {
  padding-right: var(--space-16px);
}
._1qhetbf1gc {
  padding-right: var(--space-20px);
}
._1qhetbf1gi {
  padding-right: var(--space-24px);
}
._1qhetbf1go {
  padding-right: var(--space-32px);
}
._1qhetbf1gu {
  padding-right: var(--space-48px);
}
._1qhetbf1h0 {
  padding-right: var(--space-56px);
}
._1qhetbf1h6 {
  padding-right: var(--space-64px);
}
._1qhetbf1hc {
  padding-top: var(--space-0px);
}
._1qhetbf1hi {
  padding-top: var(--space-1rem);
}
._1qhetbf1ho {
  padding-top: var(--space-1px);
}
._1qhetbf1hu {
  padding-top: var(--space-2px);
}
._1qhetbf1i0 {
  padding-top: var(--space-6px);
}
._1qhetbf1i6 {
  padding-top: var(--space-4px);
}
._1qhetbf1ic {
  padding-top: var(--space-8px);
}
._1qhetbf1ii {
  padding-top: var(--space-12px);
}
._1qhetbf1io {
  padding-top: var(--space-14px);
}
._1qhetbf1iu {
  padding-top: var(--space-16px);
}
._1qhetbf1j0 {
  padding-top: var(--space-20px);
}
._1qhetbf1j6 {
  padding-top: var(--space-24px);
}
._1qhetbf1jc {
  padding-top: var(--space-32px);
}
._1qhetbf1ji {
  padding-top: var(--space-48px);
}
._1qhetbf1jo {
  padding-top: var(--space-56px);
}
._1qhetbf1ju {
  padding-top: var(--space-64px);
}
._1qhetbf1k0 {
  font-size: var(--fontSizes-10px);
}
._1qhetbf1k6 {
  font-size: var(--fontSizes-12px);
}
._1qhetbf1kc {
  font-size: var(--fontSizes-16px);
}
._1qhetbf1ki {
  font-size: var(--fontSizes-14px);
}
._1qhetbf1ko {
  font-size: var(--fontSizes-20px);
}
._1qhetbf1ku {
  font-size: var(--fontSizes-40px);
}
._1qhetbf1l0 {
  font-size: inherit;
}
._1qhetbf1l6 {
  flex: 1 1;
}
._1qhetbf1lc {
  flex: 1 1 auto;
}
._1qhetbf1li {
  flex: 0 1 auto;
}
._1qhetbf1lo {
  flex: none;
}
._1qhetbf1lu {
  box-shadow: var(--shadows-level1);
}
._1qhetbf1m0 {
  box-shadow: var(--shadows-active);
}
._1qhetbf1m6 {
  box-shadow: var(--shadows-success);
}
._1qhetbf1mc {
  box-shadow: var(--shadows-warning);
}
._1qhetbf1mi {
  box-shadow: var(--shadows-danger);
}
._1qhetbf1mo {
  box-shadow: var(--shadows-focus);
}
._1qhetbf1mu {
  box-shadow: var(--shadows-inset);
}
._1qhetbf1n0 {
  box-shadow: var(--shadows-tooltip);
}
._1qhetbf1n6 {
  width: var(--space-0px);
}
._1qhetbf1nc {
  width: var(--space-1rem);
}
._1qhetbf1ni {
  width: var(--space-1px);
}
._1qhetbf1no {
  width: var(--space-2px);
}
._1qhetbf1nu {
  width: var(--space-6px);
}
._1qhetbf1o0 {
  width: var(--space-4px);
}
._1qhetbf1o6 {
  width: var(--space-8px);
}
._1qhetbf1oc {
  width: var(--space-12px);
}
._1qhetbf1oi {
  width: var(--space-14px);
}
._1qhetbf1oo {
  width: var(--space-16px);
}
._1qhetbf1ou {
  width: var(--space-20px);
}
._1qhetbf1p0 {
  width: var(--space-24px);
}
._1qhetbf1p6 {
  width: var(--space-32px);
}
._1qhetbf1pc {
  width: var(--space-48px);
}
._1qhetbf1pi {
  width: var(--space-56px);
}
._1qhetbf1po {
  width: var(--space-64px);
}
._1qhetbf1pu {
  width: 100%;
}
._1qhetbf1q0 {
  width: auto;
}
._1qhetbf1q6 {
  width: -moz-fit-content;
  width: fit-content;
}
._1qhetbf1qc {
  width: 420px;
}
._1qhetbf1qi {
  width: 576px;
}
._1qhetbf1qo {
  width: 852px;
}
._1qhetbf1qu {
  width: 968px;
}
._1qhetbf1r0 {
  width: 1080px;
}
._1qhetbf1r6 {
  z-index: 0;
}
._1qhetbf1rc {
  z-index: 1;
}
._1qhetbf1ri {
  z-index: 10;
}
._1qhetbf1ro {
  z-index: 20;
}
._1qhetbf1ru {
  z-index: 30;
}
._1qhetbf1s0 {
  z-index: 40;
}
._1qhetbf1s6 {
  z-index: 50;
}
._1qhetbf1sc {
  z-index: 75;
}
._1qhetbf1si {
  z-index: 99;
}
._1qhetbf1so {
  z-index: 100;
}
._1qhetbf1su {
  z-index: 9;
}
._1qhetbf1t0 {
  z-index: 10;
}
._1qhetbf1t6 {
  z-index: 100;
}
._1qhetbf1tc {
  z-index: auto;
}
._1qhetbf1ti {
  border-top: 1px solid var(--colors-cardBorder);
}
._1qhetbf1to {
  border-radius: var(--radii-0);
}
._1qhetbf1tu {
  border-radius: var(--radii-8px);
}
._1qhetbf1u0 {
  border-radius: var(--radii-12px);
}
._1qhetbf1u6 {
  border-radius: var(--radii-20px);
}
._1qhetbf1uc {
  border-radius: var(--radii-32px);
}
._1qhetbf1ui {
  border-radius: var(--radii-small);
}
._1qhetbf1uo {
  border-radius: var(--radii-default);
}
._1qhetbf1uu {
  border-radius: var(--radii-card);
}
._1qhetbf1v0 {
  border-radius: var(--radii-circle);
}
._1qhetbf1v6 {
  border-top-left-radius: var(--radii-0);
}
._1qhetbf1vc {
  border-top-left-radius: var(--radii-8px);
}
._1qhetbf1vi {
  border-top-left-radius: var(--radii-12px);
}
._1qhetbf1vo {
  border-top-left-radius: var(--radii-20px);
}
._1qhetbf1vu {
  border-top-left-radius: var(--radii-32px);
}
._1qhetbf1w0 {
  border-top-left-radius: var(--radii-small);
}
._1qhetbf1w6 {
  border-top-left-radius: var(--radii-default);
}
._1qhetbf1wc {
  border-top-left-radius: var(--radii-card);
}
._1qhetbf1wi {
  border-top-left-radius: var(--radii-circle);
}
._1qhetbf1wo {
  border-bottom-right-radius: var(--radii-0);
}
._1qhetbf1wu {
  border-bottom-right-radius: var(--radii-8px);
}
._1qhetbf1x0 {
  border-bottom-right-radius: var(--radii-12px);
}
._1qhetbf1x6 {
  border-bottom-right-radius: var(--radii-20px);
}
._1qhetbf1xc {
  border-bottom-right-radius: var(--radii-32px);
}
._1qhetbf1xi {
  border-bottom-right-radius: var(--radii-small);
}
._1qhetbf1xo {
  border-bottom-right-radius: var(--radii-default);
}
._1qhetbf1xu {
  border-bottom-right-radius: var(--radii-card);
}
._1qhetbf1y0 {
  border-bottom-right-radius: var(--radii-circle);
}
._1qhetbf1y6 {
  border-top-right-radius: var(--radii-0);
}
._1qhetbf1yc {
  border-top-right-radius: var(--radii-8px);
}
._1qhetbf1yi {
  border-top-right-radius: var(--radii-12px);
}
._1qhetbf1yo {
  border-top-right-radius: var(--radii-20px);
}
._1qhetbf1yu {
  border-top-right-radius: var(--radii-32px);
}
._1qhetbf1z0 {
  border-top-right-radius: var(--radii-small);
}
._1qhetbf1z6 {
  border-top-right-radius: var(--radii-default);
}
._1qhetbf1zc {
  border-top-right-radius: var(--radii-card);
}
._1qhetbf1zi {
  border-top-right-radius: var(--radii-circle);
}
._1qhetbf1zo {
  border-bottom-left-radius: var(--radii-0);
}
._1qhetbf1zu {
  border-bottom-left-radius: var(--radii-8px);
}
._1qhetbf200 {
  border-bottom-left-radius: var(--radii-12px);
}
._1qhetbf206 {
  border-bottom-left-radius: var(--radii-20px);
}
._1qhetbf20c {
  border-bottom-left-radius: var(--radii-32px);
}
._1qhetbf20i {
  border-bottom-left-radius: var(--radii-small);
}
._1qhetbf20o {
  border-bottom-left-radius: var(--radii-default);
}
._1qhetbf20u {
  border-bottom-left-radius: var(--radii-card);
}
._1qhetbf210 {
  border-bottom-left-radius: var(--radii-circle);
}
._1qhetbf216 {
  gap: var(--space-0px);
}
._1qhetbf21c {
  gap: var(--space-1rem);
}
._1qhetbf21i {
  gap: var(--space-1px);
}
._1qhetbf21o {
  gap: var(--space-2px);
}
._1qhetbf21u {
  gap: var(--space-6px);
}
._1qhetbf220 {
  gap: var(--space-4px);
}
._1qhetbf226 {
  gap: var(--space-8px);
}
._1qhetbf22c {
  gap: var(--space-12px);
}
._1qhetbf22i {
  gap: var(--space-14px);
}
._1qhetbf22o {
  gap: var(--space-16px);
}
._1qhetbf22u {
  gap: var(--space-20px);
}
._1qhetbf230 {
  gap: var(--space-24px);
}
._1qhetbf236 {
  gap: var(--space-32px);
}
._1qhetbf23c {
  gap: var(--space-48px);
}
._1qhetbf23i {
  gap: var(--space-56px);
}
._1qhetbf23o {
  gap: var(--space-64px);
}
._1qhetbf23u {
  gap: var(--space-8px);
}
._1qhetbf240 {
  gap: var(--space-12px);
}
._1qhetbf246 {
  gap: var(--space-24px);
}
._1qhetbf24c {
  row-gap: var(--space-0px);
}
._1qhetbf24i {
  row-gap: var(--space-1rem);
}
._1qhetbf24o {
  row-gap: var(--space-1px);
}
._1qhetbf24u {
  row-gap: var(--space-2px);
}
._1qhetbf250 {
  row-gap: var(--space-6px);
}
._1qhetbf256 {
  row-gap: var(--space-4px);
}
._1qhetbf25c {
  row-gap: var(--space-8px);
}
._1qhetbf25i {
  row-gap: var(--space-12px);
}
._1qhetbf25o {
  row-gap: var(--space-14px);
}
._1qhetbf25u {
  row-gap: var(--space-16px);
}
._1qhetbf260 {
  row-gap: var(--space-20px);
}
._1qhetbf266 {
  row-gap: var(--space-24px);
}
._1qhetbf26c {
  row-gap: var(--space-32px);
}
._1qhetbf26i {
  row-gap: var(--space-48px);
}
._1qhetbf26o {
  row-gap: var(--space-56px);
}
._1qhetbf26u {
  row-gap: var(--space-64px);
}
._1qhetbf270 {
  row-gap: var(--space-8px);
}
._1qhetbf276 {
  row-gap: var(--space-12px);
}
._1qhetbf27c {
  row-gap: var(--space-24px);
}
._1qhetbf27i {
  column-gap: var(--space-0px);
}
._1qhetbf27o {
  column-gap: var(--space-1rem);
}
._1qhetbf27u {
  column-gap: var(--space-1px);
}
._1qhetbf280 {
  column-gap: var(--space-2px);
}
._1qhetbf286 {
  column-gap: var(--space-6px);
}
._1qhetbf28c {
  column-gap: var(--space-4px);
}
._1qhetbf28i {
  column-gap: var(--space-8px);
}
._1qhetbf28o {
  column-gap: var(--space-12px);
}
._1qhetbf28u {
  column-gap: var(--space-14px);
}
._1qhetbf290 {
  column-gap: var(--space-16px);
}
._1qhetbf296 {
  column-gap: var(--space-20px);
}
._1qhetbf29c {
  column-gap: var(--space-24px);
}
._1qhetbf29i {
  column-gap: var(--space-32px);
}
._1qhetbf29o {
  column-gap: var(--space-48px);
}
._1qhetbf29u {
  column-gap: var(--space-56px);
}
._1qhetbf2a0 {
  column-gap: var(--space-64px);
}
._1qhetbf2a6 {
  column-gap: var(--space-8px);
}
._1qhetbf2ac {
  column-gap: var(--space-12px);
}
._1qhetbf2ai {
  column-gap: var(--space-24px);
}
._1qhetbf2ao {
  grid-auto-rows: auto;
}
._1qhetbf2au {
  opacity: 0.5;
}
._1qhetbf2b0 {
  opacity: 0.6;
}
._1qhetbf2b6 {
  line-height: var(--space-16px);
}
._1qhetbf2bc {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2bi {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2bo {
  border-bottom-color: var(--colors-white);
}
._1qhetbf2bu {
  border-bottom-color: var(--colors-failure);
}
._1qhetbf2c0 {
  border-bottom-color: var(--colors-failure33);
}
._1qhetbf2c6 {
  border-bottom-color: var(--colors-primary);
}
._1qhetbf2cc {
  border-bottom-color: var(--colors-primary0f);
}
._1qhetbf2ci {
  border-bottom-color: var(--colors-primary3D);
}
._1qhetbf2co {
  border-bottom-color: var(--colors-primaryBright);
}
._1qhetbf2cu {
  border-bottom-color: var(--colors-primaryDark);
}
._1qhetbf2d0 {
  border-bottom-color: var(--colors-success);
}
._1qhetbf2d6 {
  border-bottom-color: var(--colors-success19);
}
._1qhetbf2dc {
  border-bottom-color: var(--colors-warning);
}
._1qhetbf2di {
  border-bottom-color: var(--colors-warning2D);
}
._1qhetbf2do {
  border-bottom-color: var(--colors-warning33);
}
._1qhetbf2du {
  border-bottom-color: var(--colors-binance);
}
._1qhetbf2e0 {
  border-bottom-color: var(--colors-overlay);
}
._1qhetbf2e6 {
  border-bottom-color: var(--colors-gold);
}
._1qhetbf2ec {
  border-bottom-color: var(--colors-silver);
}
._1qhetbf2ei {
  border-bottom-color: var(--colors-bronze);
}
._1qhetbf2eo {
  border-bottom-color: var(--colors-secondary);
}
._1qhetbf2eu {
  border-bottom-color: var(--colors-secondary80);
}
._1qhetbf2f0 {
  border-bottom-color: var(--colors-background);
}
._1qhetbf2f6 {
  border-bottom-color: var(--colors-backgroundDisabled);
}
._1qhetbf2fc {
  border-bottom-color: var(--colors-backgroundAlt);
}
._1qhetbf2fi {
  border-bottom-color: var(--colors-backgroundAlt2);
}
._1qhetbf2fo {
  border-bottom-color: var(--colors-backgroundAlt3);
}
._1qhetbf2fu {
  border-bottom-color: var(--colors-cardBorder);
}
._1qhetbf2g0 {
  border-bottom-color: var(--colors-contrast);
}
._1qhetbf2g6 {
  border-bottom-color: var(--colors-dropdown);
}
._1qhetbf2gc {
  border-bottom-color: var(--colors-dropdownDeep);
}
._1qhetbf2gi {
  border-bottom-color: var(--colors-invertedContrast);
}
._1qhetbf2go {
  border-bottom-color: var(--colors-input);
}
._1qhetbf2gu {
  border-bottom-color: var(--colors-inputSecondary);
}
._1qhetbf2h0 {
  border-bottom-color: var(--colors-inputThird);
}
._1qhetbf2h6 {
  border-bottom-color: var(--colors-tertiary);
}
._1qhetbf2hc {
  border-bottom-color: var(--colors-text);
}
._1qhetbf2hi {
  border-bottom-color: var(--colors-text99);
}
._1qhetbf2ho {
  border-bottom-color: var(--colors-textDisabled);
}
._1qhetbf2hu {
  border-bottom-color: var(--colors-textSubtle);
}
._1qhetbf2i0 {
  border-bottom-color: var(--colors-disabled);
}
._1qhetbf2i6 {
  border-bottom-color: var(--colors-gradientBubblegum);
}
._1qhetbf2ic {
  border-bottom-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2ii {
  border-bottom-color: var(--colors-gradientCardHeader);
}
._1qhetbf2io {
  border-bottom-color: var(--colors-gradientBlue);
}
._1qhetbf2iu {
  border-bottom-color: var(--colors-gradientViolet);
}
._1qhetbf2j0 {
  border-bottom-color: var(--colors-gradientVioletAlt);
}
._1qhetbf2j6 {
  border-bottom-color: var(--colors-gradientGold);
}
._1qhetbf2jc {
  border-bottom-color: var(--colors-gradientBold);
}
._1qhetbf2ji {
  border-bottom-color: var(--colors-gradientObsidian);
}
._1qhetbf2jo {
  border: 1px solid var(--colors-cardBorder);
}
._1qhetbf2ju {
  border-bottom: 1px solid var(--colors-cardBorder);
}
._1qhetbf2k0 {
  isolation: isolate;
}
._1qhetbf2k1 {
  object-fit: contain;
}
._1qhetbf2k2 {
  object-fit: cover;
}
._1qhetbf2k3 {
  object-fit: none;
}
._1qhetbf2k4 {
  pointer-events: none;
}
._1qhetbf2k5 {
  text-transform: capitalize;
}
._1qhetbf2k6 {
  text-transform: lowercase;
}
._1qhetbf2k7 {
  text-transform: uppercase;
}
._1qhetbf2k8 {
  cursor: default;
}
._1qhetbf2k9 {
  cursor: pointer;
}
._1qhetbf2ka {
  cursor: not-allowed;
}
._1qhetbf2kb {
  visibility: hidden;
}
._1qhetbf2kc {
  visibility: visible;
}
._1qhetbf2kd {
  white-space: normal;
}
._1qhetbf2ke {
  white-space: nowrap;
}
._1qhetbf2kf {
  white-space: pre;
}
._1qhetbf2kg {
  white-space: pre-line;
}
._1qhetbf2kh {
  white-space: pre-wrap;
}
._1qhetbf2ki {
  word-break: break-word;
}
._1qhetbf2kj {
  word-wrap: normal;
}
._1qhetbf2kk {
  word-wrap: break-word;
}
._1qhetbf2kl {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2km:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2kn:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2ko:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2kp {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2kq:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2kr:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2ks:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2kt {
  background: var(--colors-white);
}
._1qhetbf2ku:hover {
  background: var(--colors-white);
}
._1qhetbf2kv:focus {
  background: var(--colors-white);
}
._1qhetbf2kw:active {
  background: var(--colors-white);
}
._1qhetbf2kx {
  background: var(--colors-failure);
}
._1qhetbf2ky:hover {
  background: var(--colors-failure);
}
._1qhetbf2kz:focus {
  background: var(--colors-failure);
}
._1qhetbf2l0:active {
  background: var(--colors-failure);
}
._1qhetbf2l1 {
  background: var(--colors-failure33);
}
._1qhetbf2l2:hover {
  background: var(--colors-failure33);
}
._1qhetbf2l3:focus {
  background: var(--colors-failure33);
}
._1qhetbf2l4:active {
  background: var(--colors-failure33);
}
._1qhetbf2l5 {
  background: var(--colors-primary);
}
._1qhetbf2l6:hover {
  background: var(--colors-primary);
}
._1qhetbf2l7:focus {
  background: var(--colors-primary);
}
._1qhetbf2l8:active {
  background: var(--colors-primary);
}
._1qhetbf2l9 {
  background: var(--colors-primary0f);
}
._1qhetbf2la:hover {
  background: var(--colors-primary0f);
}
._1qhetbf2lb:focus {
  background: var(--colors-primary0f);
}
._1qhetbf2lc:active {
  background: var(--colors-primary0f);
}
._1qhetbf2ld {
  background: var(--colors-primary3D);
}
._1qhetbf2le:hover {
  background: var(--colors-primary3D);
}
._1qhetbf2lf:focus {
  background: var(--colors-primary3D);
}
._1qhetbf2lg:active {
  background: var(--colors-primary3D);
}
._1qhetbf2lh {
  background: var(--colors-primaryBright);
}
._1qhetbf2li:hover {
  background: var(--colors-primaryBright);
}
._1qhetbf2lj:focus {
  background: var(--colors-primaryBright);
}
._1qhetbf2lk:active {
  background: var(--colors-primaryBright);
}
._1qhetbf2ll {
  background: var(--colors-primaryDark);
}
._1qhetbf2lm:hover {
  background: var(--colors-primaryDark);
}
._1qhetbf2ln:focus {
  background: var(--colors-primaryDark);
}
._1qhetbf2lo:active {
  background: var(--colors-primaryDark);
}
._1qhetbf2lp {
  background: var(--colors-success);
}
._1qhetbf2lq:hover {
  background: var(--colors-success);
}
._1qhetbf2lr:focus {
  background: var(--colors-success);
}
._1qhetbf2ls:active {
  background: var(--colors-success);
}
._1qhetbf2lt {
  background: var(--colors-success19);
}
._1qhetbf2lu:hover {
  background: var(--colors-success19);
}
._1qhetbf2lv:focus {
  background: var(--colors-success19);
}
._1qhetbf2lw:active {
  background: var(--colors-success19);
}
._1qhetbf2lx {
  background: var(--colors-warning);
}
._1qhetbf2ly:hover {
  background: var(--colors-warning);
}
._1qhetbf2lz:focus {
  background: var(--colors-warning);
}
._1qhetbf2m0:active {
  background: var(--colors-warning);
}
._1qhetbf2m1 {
  background: var(--colors-warning2D);
}
._1qhetbf2m2:hover {
  background: var(--colors-warning2D);
}
._1qhetbf2m3:focus {
  background: var(--colors-warning2D);
}
._1qhetbf2m4:active {
  background: var(--colors-warning2D);
}
._1qhetbf2m5 {
  background: var(--colors-warning33);
}
._1qhetbf2m6:hover {
  background: var(--colors-warning33);
}
._1qhetbf2m7:focus {
  background: var(--colors-warning33);
}
._1qhetbf2m8:active {
  background: var(--colors-warning33);
}
._1qhetbf2m9 {
  background: var(--colors-binance);
}
._1qhetbf2ma:hover {
  background: var(--colors-binance);
}
._1qhetbf2mb:focus {
  background: var(--colors-binance);
}
._1qhetbf2mc:active {
  background: var(--colors-binance);
}
._1qhetbf2md {
  background: var(--colors-overlay);
}
._1qhetbf2me:hover {
  background: var(--colors-overlay);
}
._1qhetbf2mf:focus {
  background: var(--colors-overlay);
}
._1qhetbf2mg:active {
  background: var(--colors-overlay);
}
._1qhetbf2mh {
  background: var(--colors-gold);
}
._1qhetbf2mi:hover {
  background: var(--colors-gold);
}
._1qhetbf2mj:focus {
  background: var(--colors-gold);
}
._1qhetbf2mk:active {
  background: var(--colors-gold);
}
._1qhetbf2ml {
  background: var(--colors-silver);
}
._1qhetbf2mm:hover {
  background: var(--colors-silver);
}
._1qhetbf2mn:focus {
  background: var(--colors-silver);
}
._1qhetbf2mo:active {
  background: var(--colors-silver);
}
._1qhetbf2mp {
  background: var(--colors-bronze);
}
._1qhetbf2mq:hover {
  background: var(--colors-bronze);
}
._1qhetbf2mr:focus {
  background: var(--colors-bronze);
}
._1qhetbf2ms:active {
  background: var(--colors-bronze);
}
._1qhetbf2mt {
  background: var(--colors-secondary);
}
._1qhetbf2mu:hover {
  background: var(--colors-secondary);
}
._1qhetbf2mv:focus {
  background: var(--colors-secondary);
}
._1qhetbf2mw:active {
  background: var(--colors-secondary);
}
._1qhetbf2mx {
  background: var(--colors-secondary80);
}
._1qhetbf2my:hover {
  background: var(--colors-secondary80);
}
._1qhetbf2mz:focus {
  background: var(--colors-secondary80);
}
._1qhetbf2n0:active {
  background: var(--colors-secondary80);
}
._1qhetbf2n1 {
  background: var(--colors-background);
}
._1qhetbf2n2:hover {
  background: var(--colors-background);
}
._1qhetbf2n3:focus {
  background: var(--colors-background);
}
._1qhetbf2n4:active {
  background: var(--colors-background);
}
._1qhetbf2n5 {
  background: var(--colors-backgroundDisabled);
}
._1qhetbf2n6:hover {
  background: var(--colors-backgroundDisabled);
}
._1qhetbf2n7:focus {
  background: var(--colors-backgroundDisabled);
}
._1qhetbf2n8:active {
  background: var(--colors-backgroundDisabled);
}
._1qhetbf2n9 {
  background: var(--colors-backgroundAlt);
}
._1qhetbf2na:hover {
  background: var(--colors-backgroundAlt);
}
._1qhetbf2nb:focus {
  background: var(--colors-backgroundAlt);
}
._1qhetbf2nc:active {
  background: var(--colors-backgroundAlt);
}
._1qhetbf2nd {
  background: var(--colors-backgroundAlt2);
}
._1qhetbf2ne:hover {
  background: var(--colors-backgroundAlt2);
}
._1qhetbf2nf:focus {
  background: var(--colors-backgroundAlt2);
}
._1qhetbf2ng:active {
  background: var(--colors-backgroundAlt2);
}
._1qhetbf2nh {
  background: var(--colors-backgroundAlt3);
}
._1qhetbf2ni:hover {
  background: var(--colors-backgroundAlt3);
}
._1qhetbf2nj:focus {
  background: var(--colors-backgroundAlt3);
}
._1qhetbf2nk:active {
  background: var(--colors-backgroundAlt3);
}
._1qhetbf2nl {
  background: var(--colors-cardBorder);
}
._1qhetbf2nm:hover {
  background: var(--colors-cardBorder);
}
._1qhetbf2nn:focus {
  background: var(--colors-cardBorder);
}
._1qhetbf2no:active {
  background: var(--colors-cardBorder);
}
._1qhetbf2np {
  background: var(--colors-contrast);
}
._1qhetbf2nq:hover {
  background: var(--colors-contrast);
}
._1qhetbf2nr:focus {
  background: var(--colors-contrast);
}
._1qhetbf2ns:active {
  background: var(--colors-contrast);
}
._1qhetbf2nt {
  background: var(--colors-dropdown);
}
._1qhetbf2nu:hover {
  background: var(--colors-dropdown);
}
._1qhetbf2nv:focus {
  background: var(--colors-dropdown);
}
._1qhetbf2nw:active {
  background: var(--colors-dropdown);
}
._1qhetbf2nx {
  background: var(--colors-dropdownDeep);
}
._1qhetbf2ny:hover {
  background: var(--colors-dropdownDeep);
}
._1qhetbf2nz:focus {
  background: var(--colors-dropdownDeep);
}
._1qhetbf2o0:active {
  background: var(--colors-dropdownDeep);
}
._1qhetbf2o1 {
  background: var(--colors-invertedContrast);
}
._1qhetbf2o2:hover {
  background: var(--colors-invertedContrast);
}
._1qhetbf2o3:focus {
  background: var(--colors-invertedContrast);
}
._1qhetbf2o4:active {
  background: var(--colors-invertedContrast);
}
._1qhetbf2o5 {
  background: var(--colors-input);
}
._1qhetbf2o6:hover {
  background: var(--colors-input);
}
._1qhetbf2o7:focus {
  background: var(--colors-input);
}
._1qhetbf2o8:active {
  background: var(--colors-input);
}
._1qhetbf2o9 {
  background: var(--colors-inputSecondary);
}
._1qhetbf2oa:hover {
  background: var(--colors-inputSecondary);
}
._1qhetbf2ob:focus {
  background: var(--colors-inputSecondary);
}
._1qhetbf2oc:active {
  background: var(--colors-inputSecondary);
}
._1qhetbf2od {
  background: var(--colors-inputThird);
}
._1qhetbf2oe:hover {
  background: var(--colors-inputThird);
}
._1qhetbf2of:focus {
  background: var(--colors-inputThird);
}
._1qhetbf2og:active {
  background: var(--colors-inputThird);
}
._1qhetbf2oh {
  background: var(--colors-tertiary);
}
._1qhetbf2oi:hover {
  background: var(--colors-tertiary);
}
._1qhetbf2oj:focus {
  background: var(--colors-tertiary);
}
._1qhetbf2ok:active {
  background: var(--colors-tertiary);
}
._1qhetbf2ol {
  background: var(--colors-text);
}
._1qhetbf2om:hover {
  background: var(--colors-text);
}
._1qhetbf2on:focus {
  background: var(--colors-text);
}
._1qhetbf2oo:active {
  background: var(--colors-text);
}
._1qhetbf2op {
  background: var(--colors-text99);
}
._1qhetbf2oq:hover {
  background: var(--colors-text99);
}
._1qhetbf2or:focus {
  background: var(--colors-text99);
}
._1qhetbf2os:active {
  background: var(--colors-text99);
}
._1qhetbf2ot {
  background: var(--colors-textDisabled);
}
._1qhetbf2ou:hover {
  background: var(--colors-textDisabled);
}
._1qhetbf2ov:focus {
  background: var(--colors-textDisabled);
}
._1qhetbf2ow:active {
  background: var(--colors-textDisabled);
}
._1qhetbf2ox {
  background: var(--colors-textSubtle);
}
._1qhetbf2oy:hover {
  background: var(--colors-textSubtle);
}
._1qhetbf2oz:focus {
  background: var(--colors-textSubtle);
}
._1qhetbf2p0:active {
  background: var(--colors-textSubtle);
}
._1qhetbf2p1 {
  background: var(--colors-disabled);
}
._1qhetbf2p2:hover {
  background: var(--colors-disabled);
}
._1qhetbf2p3:focus {
  background: var(--colors-disabled);
}
._1qhetbf2p4:active {
  background: var(--colors-disabled);
}
._1qhetbf2p5 {
  background: var(--colors-gradientBubblegum);
}
._1qhetbf2p6:hover {
  background: var(--colors-gradientBubblegum);
}
._1qhetbf2p7:focus {
  background: var(--colors-gradientBubblegum);
}
._1qhetbf2p8:active {
  background: var(--colors-gradientBubblegum);
}
._1qhetbf2p9 {
  background: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2pa:hover {
  background: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2pb:focus {
  background: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2pc:active {
  background: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2pd {
  background: var(--colors-gradientCardHeader);
}
._1qhetbf2pe:hover {
  background: var(--colors-gradientCardHeader);
}
._1qhetbf2pf:focus {
  background: var(--colors-gradientCardHeader);
}
._1qhetbf2pg:active {
  background: var(--colors-gradientCardHeader);
}
._1qhetbf2ph {
  background: var(--colors-gradientBlue);
}
._1qhetbf2pi:hover {
  background: var(--colors-gradientBlue);
}
._1qhetbf2pj:focus {
  background: var(--colors-gradientBlue);
}
._1qhetbf2pk:active {
  background: var(--colors-gradientBlue);
}
._1qhetbf2pl {
  background: var(--colors-gradientViolet);
}
._1qhetbf2pm:hover {
  background: var(--colors-gradientViolet);
}
._1qhetbf2pn:focus {
  background: var(--colors-gradientViolet);
}
._1qhetbf2po:active {
  background: var(--colors-gradientViolet);
}
._1qhetbf2pp {
  background: var(--colors-gradientVioletAlt);
}
._1qhetbf2pq:hover {
  background: var(--colors-gradientVioletAlt);
}
._1qhetbf2pr:focus {
  background: var(--colors-gradientVioletAlt);
}
._1qhetbf2ps:active {
  background: var(--colors-gradientVioletAlt);
}
._1qhetbf2pt {
  background: var(--colors-gradientGold);
}
._1qhetbf2pu:hover {
  background: var(--colors-gradientGold);
}
._1qhetbf2pv:focus {
  background: var(--colors-gradientGold);
}
._1qhetbf2pw:active {
  background: var(--colors-gradientGold);
}
._1qhetbf2px {
  background: var(--colors-gradientBold);
}
._1qhetbf2py:hover {
  background: var(--colors-gradientBold);
}
._1qhetbf2pz:focus {
  background: var(--colors-gradientBold);
}
._1qhetbf2q0:active {
  background: var(--colors-gradientBold);
}
._1qhetbf2q1 {
  background: var(--colors-gradientObsidian);
}
._1qhetbf2q2:hover {
  background: var(--colors-gradientObsidian);
}
._1qhetbf2q3:focus {
  background: var(--colors-gradientObsidian);
}
._1qhetbf2q4:active {
  background: var(--colors-gradientObsidian);
}
._1qhetbf2q5 {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2q6:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2q7:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2q8:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2q9 {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2qa:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2qb:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2qc:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2qd {
  background-color: var(--colors-white);
}
._1qhetbf2qe:hover {
  background-color: var(--colors-white);
}
._1qhetbf2qf:focus {
  background-color: var(--colors-white);
}
._1qhetbf2qg:active {
  background-color: var(--colors-white);
}
._1qhetbf2qh {
  background-color: var(--colors-failure);
}
._1qhetbf2qi:hover {
  background-color: var(--colors-failure);
}
._1qhetbf2qj:focus {
  background-color: var(--colors-failure);
}
._1qhetbf2qk:active {
  background-color: var(--colors-failure);
}
._1qhetbf2ql {
  background-color: var(--colors-failure33);
}
._1qhetbf2qm:hover {
  background-color: var(--colors-failure33);
}
._1qhetbf2qn:focus {
  background-color: var(--colors-failure33);
}
._1qhetbf2qo:active {
  background-color: var(--colors-failure33);
}
._1qhetbf2qp {
  background-color: var(--colors-primary);
}
._1qhetbf2qq:hover {
  background-color: var(--colors-primary);
}
._1qhetbf2qr:focus {
  background-color: var(--colors-primary);
}
._1qhetbf2qs:active {
  background-color: var(--colors-primary);
}
._1qhetbf2qt {
  background-color: var(--colors-primary0f);
}
._1qhetbf2qu:hover {
  background-color: var(--colors-primary0f);
}
._1qhetbf2qv:focus {
  background-color: var(--colors-primary0f);
}
._1qhetbf2qw:active {
  background-color: var(--colors-primary0f);
}
._1qhetbf2qx {
  background-color: var(--colors-primary3D);
}
._1qhetbf2qy:hover {
  background-color: var(--colors-primary3D);
}
._1qhetbf2qz:focus {
  background-color: var(--colors-primary3D);
}
._1qhetbf2r0:active {
  background-color: var(--colors-primary3D);
}
._1qhetbf2r1 {
  background-color: var(--colors-primaryBright);
}
._1qhetbf2r2:hover {
  background-color: var(--colors-primaryBright);
}
._1qhetbf2r3:focus {
  background-color: var(--colors-primaryBright);
}
._1qhetbf2r4:active {
  background-color: var(--colors-primaryBright);
}
._1qhetbf2r5 {
  background-color: var(--colors-primaryDark);
}
._1qhetbf2r6:hover {
  background-color: var(--colors-primaryDark);
}
._1qhetbf2r7:focus {
  background-color: var(--colors-primaryDark);
}
._1qhetbf2r8:active {
  background-color: var(--colors-primaryDark);
}
._1qhetbf2r9 {
  background-color: var(--colors-success);
}
._1qhetbf2ra:hover {
  background-color: var(--colors-success);
}
._1qhetbf2rb:focus {
  background-color: var(--colors-success);
}
._1qhetbf2rc:active {
  background-color: var(--colors-success);
}
._1qhetbf2rd {
  background-color: var(--colors-success19);
}
._1qhetbf2re:hover {
  background-color: var(--colors-success19);
}
._1qhetbf2rf:focus {
  background-color: var(--colors-success19);
}
._1qhetbf2rg:active {
  background-color: var(--colors-success19);
}
._1qhetbf2rh {
  background-color: var(--colors-warning);
}
._1qhetbf2ri:hover {
  background-color: var(--colors-warning);
}
._1qhetbf2rj:focus {
  background-color: var(--colors-warning);
}
._1qhetbf2rk:active {
  background-color: var(--colors-warning);
}
._1qhetbf2rl {
  background-color: var(--colors-warning2D);
}
._1qhetbf2rm:hover {
  background-color: var(--colors-warning2D);
}
._1qhetbf2rn:focus {
  background-color: var(--colors-warning2D);
}
._1qhetbf2ro:active {
  background-color: var(--colors-warning2D);
}
._1qhetbf2rp {
  background-color: var(--colors-warning33);
}
._1qhetbf2rq:hover {
  background-color: var(--colors-warning33);
}
._1qhetbf2rr:focus {
  background-color: var(--colors-warning33);
}
._1qhetbf2rs:active {
  background-color: var(--colors-warning33);
}
._1qhetbf2rt {
  background-color: var(--colors-binance);
}
._1qhetbf2ru:hover {
  background-color: var(--colors-binance);
}
._1qhetbf2rv:focus {
  background-color: var(--colors-binance);
}
._1qhetbf2rw:active {
  background-color: var(--colors-binance);
}
._1qhetbf2rx {
  background-color: var(--colors-overlay);
}
._1qhetbf2ry:hover {
  background-color: var(--colors-overlay);
}
._1qhetbf2rz:focus {
  background-color: var(--colors-overlay);
}
._1qhetbf2s0:active {
  background-color: var(--colors-overlay);
}
._1qhetbf2s1 {
  background-color: var(--colors-gold);
}
._1qhetbf2s2:hover {
  background-color: var(--colors-gold);
}
._1qhetbf2s3:focus {
  background-color: var(--colors-gold);
}
._1qhetbf2s4:active {
  background-color: var(--colors-gold);
}
._1qhetbf2s5 {
  background-color: var(--colors-silver);
}
._1qhetbf2s6:hover {
  background-color: var(--colors-silver);
}
._1qhetbf2s7:focus {
  background-color: var(--colors-silver);
}
._1qhetbf2s8:active {
  background-color: var(--colors-silver);
}
._1qhetbf2s9 {
  background-color: var(--colors-bronze);
}
._1qhetbf2sa:hover {
  background-color: var(--colors-bronze);
}
._1qhetbf2sb:focus {
  background-color: var(--colors-bronze);
}
._1qhetbf2sc:active {
  background-color: var(--colors-bronze);
}
._1qhetbf2sd {
  background-color: var(--colors-secondary);
}
._1qhetbf2se:hover {
  background-color: var(--colors-secondary);
}
._1qhetbf2sf:focus {
  background-color: var(--colors-secondary);
}
._1qhetbf2sg:active {
  background-color: var(--colors-secondary);
}
._1qhetbf2sh {
  background-color: var(--colors-secondary80);
}
._1qhetbf2si:hover {
  background-color: var(--colors-secondary80);
}
._1qhetbf2sj:focus {
  background-color: var(--colors-secondary80);
}
._1qhetbf2sk:active {
  background-color: var(--colors-secondary80);
}
._1qhetbf2sl {
  background-color: var(--colors-background);
}
._1qhetbf2sm:hover {
  background-color: var(--colors-background);
}
._1qhetbf2sn:focus {
  background-color: var(--colors-background);
}
._1qhetbf2so:active {
  background-color: var(--colors-background);
}
._1qhetbf2sp {
  background-color: var(--colors-backgroundDisabled);
}
._1qhetbf2sq:hover {
  background-color: var(--colors-backgroundDisabled);
}
._1qhetbf2sr:focus {
  background-color: var(--colors-backgroundDisabled);
}
._1qhetbf2ss:active {
  background-color: var(--colors-backgroundDisabled);
}
._1qhetbf2st {
  background-color: var(--colors-backgroundAlt);
}
._1qhetbf2su:hover {
  background-color: var(--colors-backgroundAlt);
}
._1qhetbf2sv:focus {
  background-color: var(--colors-backgroundAlt);
}
._1qhetbf2sw:active {
  background-color: var(--colors-backgroundAlt);
}
._1qhetbf2sx {
  background-color: var(--colors-backgroundAlt2);
}
._1qhetbf2sy:hover {
  background-color: var(--colors-backgroundAlt2);
}
._1qhetbf2sz:focus {
  background-color: var(--colors-backgroundAlt2);
}
._1qhetbf2t0:active {
  background-color: var(--colors-backgroundAlt2);
}
._1qhetbf2t1 {
  background-color: var(--colors-backgroundAlt3);
}
._1qhetbf2t2:hover {
  background-color: var(--colors-backgroundAlt3);
}
._1qhetbf2t3:focus {
  background-color: var(--colors-backgroundAlt3);
}
._1qhetbf2t4:active {
  background-color: var(--colors-backgroundAlt3);
}
._1qhetbf2t5 {
  background-color: var(--colors-cardBorder);
}
._1qhetbf2t6:hover {
  background-color: var(--colors-cardBorder);
}
._1qhetbf2t7:focus {
  background-color: var(--colors-cardBorder);
}
._1qhetbf2t8:active {
  background-color: var(--colors-cardBorder);
}
._1qhetbf2t9 {
  background-color: var(--colors-contrast);
}
._1qhetbf2ta:hover {
  background-color: var(--colors-contrast);
}
._1qhetbf2tb:focus {
  background-color: var(--colors-contrast);
}
._1qhetbf2tc:active {
  background-color: var(--colors-contrast);
}
._1qhetbf2td {
  background-color: var(--colors-dropdown);
}
._1qhetbf2te:hover {
  background-color: var(--colors-dropdown);
}
._1qhetbf2tf:focus {
  background-color: var(--colors-dropdown);
}
._1qhetbf2tg:active {
  background-color: var(--colors-dropdown);
}
._1qhetbf2th {
  background-color: var(--colors-dropdownDeep);
}
._1qhetbf2ti:hover {
  background-color: var(--colors-dropdownDeep);
}
._1qhetbf2tj:focus {
  background-color: var(--colors-dropdownDeep);
}
._1qhetbf2tk:active {
  background-color: var(--colors-dropdownDeep);
}
._1qhetbf2tl {
  background-color: var(--colors-invertedContrast);
}
._1qhetbf2tm:hover {
  background-color: var(--colors-invertedContrast);
}
._1qhetbf2tn:focus {
  background-color: var(--colors-invertedContrast);
}
._1qhetbf2to:active {
  background-color: var(--colors-invertedContrast);
}
._1qhetbf2tp {
  background-color: var(--colors-input);
}
._1qhetbf2tq:hover {
  background-color: var(--colors-input);
}
._1qhetbf2tr:focus {
  background-color: var(--colors-input);
}
._1qhetbf2ts:active {
  background-color: var(--colors-input);
}
._1qhetbf2tt {
  background-color: var(--colors-inputSecondary);
}
._1qhetbf2tu:hover {
  background-color: var(--colors-inputSecondary);
}
._1qhetbf2tv:focus {
  background-color: var(--colors-inputSecondary);
}
._1qhetbf2tw:active {
  background-color: var(--colors-inputSecondary);
}
._1qhetbf2tx {
  background-color: var(--colors-inputThird);
}
._1qhetbf2ty:hover {
  background-color: var(--colors-inputThird);
}
._1qhetbf2tz:focus {
  background-color: var(--colors-inputThird);
}
._1qhetbf2u0:active {
  background-color: var(--colors-inputThird);
}
._1qhetbf2u1 {
  background-color: var(--colors-tertiary);
}
._1qhetbf2u2:hover {
  background-color: var(--colors-tertiary);
}
._1qhetbf2u3:focus {
  background-color: var(--colors-tertiary);
}
._1qhetbf2u4:active {
  background-color: var(--colors-tertiary);
}
._1qhetbf2u5 {
  background-color: var(--colors-text);
}
._1qhetbf2u6:hover {
  background-color: var(--colors-text);
}
._1qhetbf2u7:focus {
  background-color: var(--colors-text);
}
._1qhetbf2u8:active {
  background-color: var(--colors-text);
}
._1qhetbf2u9 {
  background-color: var(--colors-text99);
}
._1qhetbf2ua:hover {
  background-color: var(--colors-text99);
}
._1qhetbf2ub:focus {
  background-color: var(--colors-text99);
}
._1qhetbf2uc:active {
  background-color: var(--colors-text99);
}
._1qhetbf2ud {
  background-color: var(--colors-textDisabled);
}
._1qhetbf2ue:hover {
  background-color: var(--colors-textDisabled);
}
._1qhetbf2uf:focus {
  background-color: var(--colors-textDisabled);
}
._1qhetbf2ug:active {
  background-color: var(--colors-textDisabled);
}
._1qhetbf2uh {
  background-color: var(--colors-textSubtle);
}
._1qhetbf2ui:hover {
  background-color: var(--colors-textSubtle);
}
._1qhetbf2uj:focus {
  background-color: var(--colors-textSubtle);
}
._1qhetbf2uk:active {
  background-color: var(--colors-textSubtle);
}
._1qhetbf2ul {
  background-color: var(--colors-disabled);
}
._1qhetbf2um:hover {
  background-color: var(--colors-disabled);
}
._1qhetbf2un:focus {
  background-color: var(--colors-disabled);
}
._1qhetbf2uo:active {
  background-color: var(--colors-disabled);
}
._1qhetbf2up {
  background-color: var(--colors-gradientBubblegum);
}
._1qhetbf2uq:hover {
  background-color: var(--colors-gradientBubblegum);
}
._1qhetbf2ur:focus {
  background-color: var(--colors-gradientBubblegum);
}
._1qhetbf2us:active {
  background-color: var(--colors-gradientBubblegum);
}
._1qhetbf2ut {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2uu:hover {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2uv:focus {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2uw:active {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf2ux {
  background-color: var(--colors-gradientCardHeader);
}
._1qhetbf2uy:hover {
  background-color: var(--colors-gradientCardHeader);
}
._1qhetbf2uz:focus {
  background-color: var(--colors-gradientCardHeader);
}
._1qhetbf2v0:active {
  background-color: var(--colors-gradientCardHeader);
}
._1qhetbf2v1 {
  background-color: var(--colors-gradientBlue);
}
._1qhetbf2v2:hover {
  background-color: var(--colors-gradientBlue);
}
._1qhetbf2v3:focus {
  background-color: var(--colors-gradientBlue);
}
._1qhetbf2v4:active {
  background-color: var(--colors-gradientBlue);
}
._1qhetbf2v5 {
  background-color: var(--colors-gradientViolet);
}
._1qhetbf2v6:hover {
  background-color: var(--colors-gradientViolet);
}
._1qhetbf2v7:focus {
  background-color: var(--colors-gradientViolet);
}
._1qhetbf2v8:active {
  background-color: var(--colors-gradientViolet);
}
._1qhetbf2v9 {
  background-color: var(--colors-gradientVioletAlt);
}
._1qhetbf2va:hover {
  background-color: var(--colors-gradientVioletAlt);
}
._1qhetbf2vb:focus {
  background-color: var(--colors-gradientVioletAlt);
}
._1qhetbf2vc:active {
  background-color: var(--colors-gradientVioletAlt);
}
._1qhetbf2vd {
  background-color: var(--colors-gradientGold);
}
._1qhetbf2ve:hover {
  background-color: var(--colors-gradientGold);
}
._1qhetbf2vf:focus {
  background-color: var(--colors-gradientGold);
}
._1qhetbf2vg:active {
  background-color: var(--colors-gradientGold);
}
._1qhetbf2vh {
  background-color: var(--colors-gradientBold);
}
._1qhetbf2vi:hover {
  background-color: var(--colors-gradientBold);
}
._1qhetbf2vj:focus {
  background-color: var(--colors-gradientBold);
}
._1qhetbf2vk:active {
  background-color: var(--colors-gradientBold);
}
._1qhetbf2vl {
  background-color: var(--colors-gradientObsidian);
}
._1qhetbf2vm:hover {
  background-color: var(--colors-gradientObsidian);
}
._1qhetbf2vn:focus {
  background-color: var(--colors-gradientObsidian);
}
._1qhetbf2vo:active {
  background-color: var(--colors-gradientObsidian);
}
._1qhetbf2vp {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2vq:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2vr:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2vs:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf2vt {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2vu:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2vv:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2vw:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf2vx {
  border-color: var(--colors-white);
}
._1qhetbf2vy:hover {
  border-color: var(--colors-white);
}
._1qhetbf2vz:focus {
  border-color: var(--colors-white);
}
._1qhetbf2w0:active {
  border-color: var(--colors-white);
}
._1qhetbf2w1 {
  border-color: var(--colors-failure);
}
._1qhetbf2w2:hover {
  border-color: var(--colors-failure);
}
._1qhetbf2w3:focus {
  border-color: var(--colors-failure);
}
._1qhetbf2w4:active {
  border-color: var(--colors-failure);
}
._1qhetbf2w5 {
  border-color: var(--colors-failure33);
}
._1qhetbf2w6:hover {
  border-color: var(--colors-failure33);
}
._1qhetbf2w7:focus {
  border-color: var(--colors-failure33);
}
._1qhetbf2w8:active {
  border-color: var(--colors-failure33);
}
._1qhetbf2w9 {
  border-color: var(--colors-primary);
}
._1qhetbf2wa:hover {
  border-color: var(--colors-primary);
}
._1qhetbf2wb:focus {
  border-color: var(--colors-primary);
}
._1qhetbf2wc:active {
  border-color: var(--colors-primary);
}
._1qhetbf2wd {
  border-color: var(--colors-primary0f);
}
._1qhetbf2we:hover {
  border-color: var(--colors-primary0f);
}
._1qhetbf2wf:focus {
  border-color: var(--colors-primary0f);
}
._1qhetbf2wg:active {
  border-color: var(--colors-primary0f);
}
._1qhetbf2wh {
  border-color: var(--colors-primary3D);
}
._1qhetbf2wi:hover {
  border-color: var(--colors-primary3D);
}
._1qhetbf2wj:focus {
  border-color: var(--colors-primary3D);
}
._1qhetbf2wk:active {
  border-color: var(--colors-primary3D);
}
._1qhetbf2wl {
  border-color: var(--colors-primaryBright);
}
._1qhetbf2wm:hover {
  border-color: var(--colors-primaryBright);
}
._1qhetbf2wn:focus {
  border-color: var(--colors-primaryBright);
}
._1qhetbf2wo:active {
  border-color: var(--colors-primaryBright);
}
._1qhetbf2wp {
  border-color: var(--colors-primaryDark);
}
._1qhetbf2wq:hover {
  border-color: var(--colors-primaryDark);
}
._1qhetbf2wr:focus {
  border-color: var(--colors-primaryDark);
}
._1qhetbf2ws:active {
  border-color: var(--colors-primaryDark);
}
._1qhetbf2wt {
  border-color: var(--colors-success);
}
._1qhetbf2wu:hover {
  border-color: var(--colors-success);
}
._1qhetbf2wv:focus {
  border-color: var(--colors-success);
}
._1qhetbf2ww:active {
  border-color: var(--colors-success);
}
._1qhetbf2wx {
  border-color: var(--colors-success19);
}
._1qhetbf2wy:hover {
  border-color: var(--colors-success19);
}
._1qhetbf2wz:focus {
  border-color: var(--colors-success19);
}
._1qhetbf2x0:active {
  border-color: var(--colors-success19);
}
._1qhetbf2x1 {
  border-color: var(--colors-warning);
}
._1qhetbf2x2:hover {
  border-color: var(--colors-warning);
}
._1qhetbf2x3:focus {
  border-color: var(--colors-warning);
}
._1qhetbf2x4:active {
  border-color: var(--colors-warning);
}
._1qhetbf2x5 {
  border-color: var(--colors-warning2D);
}
._1qhetbf2x6:hover {
  border-color: var(--colors-warning2D);
}
._1qhetbf2x7:focus {
  border-color: var(--colors-warning2D);
}
._1qhetbf2x8:active {
  border-color: var(--colors-warning2D);
}
._1qhetbf2x9 {
  border-color: var(--colors-warning33);
}
._1qhetbf2xa:hover {
  border-color: var(--colors-warning33);
}
._1qhetbf2xb:focus {
  border-color: var(--colors-warning33);
}
._1qhetbf2xc:active {
  border-color: var(--colors-warning33);
}
._1qhetbf2xd {
  border-color: var(--colors-binance);
}
._1qhetbf2xe:hover {
  border-color: var(--colors-binance);
}
._1qhetbf2xf:focus {
  border-color: var(--colors-binance);
}
._1qhetbf2xg:active {
  border-color: var(--colors-binance);
}
._1qhetbf2xh {
  border-color: var(--colors-overlay);
}
._1qhetbf2xi:hover {
  border-color: var(--colors-overlay);
}
._1qhetbf2xj:focus {
  border-color: var(--colors-overlay);
}
._1qhetbf2xk:active {
  border-color: var(--colors-overlay);
}
._1qhetbf2xl {
  border-color: var(--colors-gold);
}
._1qhetbf2xm:hover {
  border-color: var(--colors-gold);
}
._1qhetbf2xn:focus {
  border-color: var(--colors-gold);
}
._1qhetbf2xo:active {
  border-color: var(--colors-gold);
}
._1qhetbf2xp {
  border-color: var(--colors-silver);
}
._1qhetbf2xq:hover {
  border-color: var(--colors-silver);
}
._1qhetbf2xr:focus {
  border-color: var(--colors-silver);
}
._1qhetbf2xs:active {
  border-color: var(--colors-silver);
}
._1qhetbf2xt {
  border-color: var(--colors-bronze);
}
._1qhetbf2xu:hover {
  border-color: var(--colors-bronze);
}
._1qhetbf2xv:focus {
  border-color: var(--colors-bronze);
}
._1qhetbf2xw:active {
  border-color: var(--colors-bronze);
}
._1qhetbf2xx {
  border-color: var(--colors-secondary);
}
._1qhetbf2xy:hover {
  border-color: var(--colors-secondary);
}
._1qhetbf2xz:focus {
  border-color: var(--colors-secondary);
}
._1qhetbf2y0:active {
  border-color: var(--colors-secondary);
}
._1qhetbf2y1 {
  border-color: var(--colors-secondary80);
}
._1qhetbf2y2:hover {
  border-color: var(--colors-secondary80);
}
._1qhetbf2y3:focus {
  border-color: var(--colors-secondary80);
}
._1qhetbf2y4:active {
  border-color: var(--colors-secondary80);
}
._1qhetbf2y5 {
  border-color: var(--colors-background);
}
._1qhetbf2y6:hover {
  border-color: var(--colors-background);
}
._1qhetbf2y7:focus {
  border-color: var(--colors-background);
}
._1qhetbf2y8:active {
  border-color: var(--colors-background);
}
._1qhetbf2y9 {
  border-color: var(--colors-backgroundDisabled);
}
._1qhetbf2ya:hover {
  border-color: var(--colors-backgroundDisabled);
}
._1qhetbf2yb:focus {
  border-color: var(--colors-backgroundDisabled);
}
._1qhetbf2yc:active {
  border-color: var(--colors-backgroundDisabled);
}
._1qhetbf2yd {
  border-color: var(--colors-backgroundAlt);
}
._1qhetbf2ye:hover {
  border-color: var(--colors-backgroundAlt);
}
._1qhetbf2yf:focus {
  border-color: var(--colors-backgroundAlt);
}
._1qhetbf2yg:active {
  border-color: var(--colors-backgroundAlt);
}
._1qhetbf2yh {
  border-color: var(--colors-backgroundAlt2);
}
._1qhetbf2yi:hover {
  border-color: var(--colors-backgroundAlt2);
}
._1qhetbf2yj:focus {
  border-color: var(--colors-backgroundAlt2);
}
._1qhetbf2yk:active {
  border-color: var(--colors-backgroundAlt2);
}
._1qhetbf2yl {
  border-color: var(--colors-backgroundAlt3);
}
._1qhetbf2ym:hover {
  border-color: var(--colors-backgroundAlt3);
}
._1qhetbf2yn:focus {
  border-color: var(--colors-backgroundAlt3);
}
._1qhetbf2yo:active {
  border-color: var(--colors-backgroundAlt3);
}
._1qhetbf2yp {
  border-color: var(--colors-cardBorder);
}
._1qhetbf2yq:hover {
  border-color: var(--colors-cardBorder);
}
._1qhetbf2yr:focus {
  border-color: var(--colors-cardBorder);
}
._1qhetbf2ys:active {
  border-color: var(--colors-cardBorder);
}
._1qhetbf2yt {
  border-color: var(--colors-contrast);
}
._1qhetbf2yu:hover {
  border-color: var(--colors-contrast);
}
._1qhetbf2yv:focus {
  border-color: var(--colors-contrast);
}
._1qhetbf2yw:active {
  border-color: var(--colors-contrast);
}
._1qhetbf2yx {
  border-color: var(--colors-dropdown);
}
._1qhetbf2yy:hover {
  border-color: var(--colors-dropdown);
}
._1qhetbf2yz:focus {
  border-color: var(--colors-dropdown);
}
._1qhetbf2z0:active {
  border-color: var(--colors-dropdown);
}
._1qhetbf2z1 {
  border-color: var(--colors-dropdownDeep);
}
._1qhetbf2z2:hover {
  border-color: var(--colors-dropdownDeep);
}
._1qhetbf2z3:focus {
  border-color: var(--colors-dropdownDeep);
}
._1qhetbf2z4:active {
  border-color: var(--colors-dropdownDeep);
}
._1qhetbf2z5 {
  border-color: var(--colors-invertedContrast);
}
._1qhetbf2z6:hover {
  border-color: var(--colors-invertedContrast);
}
._1qhetbf2z7:focus {
  border-color: var(--colors-invertedContrast);
}
._1qhetbf2z8:active {
  border-color: var(--colors-invertedContrast);
}
._1qhetbf2z9 {
  border-color: var(--colors-input);
}
._1qhetbf2za:hover {
  border-color: var(--colors-input);
}
._1qhetbf2zb:focus {
  border-color: var(--colors-input);
}
._1qhetbf2zc:active {
  border-color: var(--colors-input);
}
._1qhetbf2zd {
  border-color: var(--colors-inputSecondary);
}
._1qhetbf2ze:hover {
  border-color: var(--colors-inputSecondary);
}
._1qhetbf2zf:focus {
  border-color: var(--colors-inputSecondary);
}
._1qhetbf2zg:active {
  border-color: var(--colors-inputSecondary);
}
._1qhetbf2zh {
  border-color: var(--colors-inputThird);
}
._1qhetbf2zi:hover {
  border-color: var(--colors-inputThird);
}
._1qhetbf2zj:focus {
  border-color: var(--colors-inputThird);
}
._1qhetbf2zk:active {
  border-color: var(--colors-inputThird);
}
._1qhetbf2zl {
  border-color: var(--colors-tertiary);
}
._1qhetbf2zm:hover {
  border-color: var(--colors-tertiary);
}
._1qhetbf2zn:focus {
  border-color: var(--colors-tertiary);
}
._1qhetbf2zo:active {
  border-color: var(--colors-tertiary);
}
._1qhetbf2zp {
  border-color: var(--colors-text);
}
._1qhetbf2zq:hover {
  border-color: var(--colors-text);
}
._1qhetbf2zr:focus {
  border-color: var(--colors-text);
}
._1qhetbf2zs:active {
  border-color: var(--colors-text);
}
._1qhetbf2zt {
  border-color: var(--colors-text99);
}
._1qhetbf2zu:hover {
  border-color: var(--colors-text99);
}
._1qhetbf2zv:focus {
  border-color: var(--colors-text99);
}
._1qhetbf2zw:active {
  border-color: var(--colors-text99);
}
._1qhetbf2zx {
  border-color: var(--colors-textDisabled);
}
._1qhetbf2zy:hover {
  border-color: var(--colors-textDisabled);
}
._1qhetbf2zz:focus {
  border-color: var(--colors-textDisabled);
}
._1qhetbf300:active {
  border-color: var(--colors-textDisabled);
}
._1qhetbf301 {
  border-color: var(--colors-textSubtle);
}
._1qhetbf302:hover {
  border-color: var(--colors-textSubtle);
}
._1qhetbf303:focus {
  border-color: var(--colors-textSubtle);
}
._1qhetbf304:active {
  border-color: var(--colors-textSubtle);
}
._1qhetbf305 {
  border-color: var(--colors-disabled);
}
._1qhetbf306:hover {
  border-color: var(--colors-disabled);
}
._1qhetbf307:focus {
  border-color: var(--colors-disabled);
}
._1qhetbf308:active {
  border-color: var(--colors-disabled);
}
._1qhetbf309 {
  border-color: var(--colors-gradientBubblegum);
}
._1qhetbf30a:hover {
  border-color: var(--colors-gradientBubblegum);
}
._1qhetbf30b:focus {
  border-color: var(--colors-gradientBubblegum);
}
._1qhetbf30c:active {
  border-color: var(--colors-gradientBubblegum);
}
._1qhetbf30d {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf30e:hover {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf30f:focus {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf30g:active {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf30h {
  border-color: var(--colors-gradientCardHeader);
}
._1qhetbf30i:hover {
  border-color: var(--colors-gradientCardHeader);
}
._1qhetbf30j:focus {
  border-color: var(--colors-gradientCardHeader);
}
._1qhetbf30k:active {
  border-color: var(--colors-gradientCardHeader);
}
._1qhetbf30l {
  border-color: var(--colors-gradientBlue);
}
._1qhetbf30m:hover {
  border-color: var(--colors-gradientBlue);
}
._1qhetbf30n:focus {
  border-color: var(--colors-gradientBlue);
}
._1qhetbf30o:active {
  border-color: var(--colors-gradientBlue);
}
._1qhetbf30p {
  border-color: var(--colors-gradientViolet);
}
._1qhetbf30q:hover {
  border-color: var(--colors-gradientViolet);
}
._1qhetbf30r:focus {
  border-color: var(--colors-gradientViolet);
}
._1qhetbf30s:active {
  border-color: var(--colors-gradientViolet);
}
._1qhetbf30t {
  border-color: var(--colors-gradientVioletAlt);
}
._1qhetbf30u:hover {
  border-color: var(--colors-gradientVioletAlt);
}
._1qhetbf30v:focus {
  border-color: var(--colors-gradientVioletAlt);
}
._1qhetbf30w:active {
  border-color: var(--colors-gradientVioletAlt);
}
._1qhetbf30x {
  border-color: var(--colors-gradientGold);
}
._1qhetbf30y:hover {
  border-color: var(--colors-gradientGold);
}
._1qhetbf30z:focus {
  border-color: var(--colors-gradientGold);
}
._1qhetbf310:active {
  border-color: var(--colors-gradientGold);
}
._1qhetbf311 {
  border-color: var(--colors-gradientBold);
}
._1qhetbf312:hover {
  border-color: var(--colors-gradientBold);
}
._1qhetbf313:focus {
  border-color: var(--colors-gradientBold);
}
._1qhetbf314:active {
  border-color: var(--colors-gradientBold);
}
._1qhetbf315 {
  border-color: var(--colors-gradientObsidian);
}
._1qhetbf316:hover {
  border-color: var(--colors-gradientObsidian);
}
._1qhetbf317:focus {
  border-color: var(--colors-gradientObsidian);
}
._1qhetbf318:active {
  border-color: var(--colors-gradientObsidian);
}
._1qhetbf319 {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf31a:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf31b:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf31c:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf31d {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf31e:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf31f:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf31g:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf31h {
  color: var(--colors-white);
}
._1qhetbf31i:hover {
  color: var(--colors-white);
}
._1qhetbf31j:focus {
  color: var(--colors-white);
}
._1qhetbf31k:active {
  color: var(--colors-white);
}
._1qhetbf31l {
  color: var(--colors-failure);
}
._1qhetbf31m:hover {
  color: var(--colors-failure);
}
._1qhetbf31n:focus {
  color: var(--colors-failure);
}
._1qhetbf31o:active {
  color: var(--colors-failure);
}
._1qhetbf31p {
  color: var(--colors-failure33);
}
._1qhetbf31q:hover {
  color: var(--colors-failure33);
}
._1qhetbf31r:focus {
  color: var(--colors-failure33);
}
._1qhetbf31s:active {
  color: var(--colors-failure33);
}
._1qhetbf31t {
  color: var(--colors-primary);
}
._1qhetbf31u:hover {
  color: var(--colors-primary);
}
._1qhetbf31v:focus {
  color: var(--colors-primary);
}
._1qhetbf31w:active {
  color: var(--colors-primary);
}
._1qhetbf31x {
  color: var(--colors-primary0f);
}
._1qhetbf31y:hover {
  color: var(--colors-primary0f);
}
._1qhetbf31z:focus {
  color: var(--colors-primary0f);
}
._1qhetbf320:active {
  color: var(--colors-primary0f);
}
._1qhetbf321 {
  color: var(--colors-primary3D);
}
._1qhetbf322:hover {
  color: var(--colors-primary3D);
}
._1qhetbf323:focus {
  color: var(--colors-primary3D);
}
._1qhetbf324:active {
  color: var(--colors-primary3D);
}
._1qhetbf325 {
  color: var(--colors-primaryBright);
}
._1qhetbf326:hover {
  color: var(--colors-primaryBright);
}
._1qhetbf327:focus {
  color: var(--colors-primaryBright);
}
._1qhetbf328:active {
  color: var(--colors-primaryBright);
}
._1qhetbf329 {
  color: var(--colors-primaryDark);
}
._1qhetbf32a:hover {
  color: var(--colors-primaryDark);
}
._1qhetbf32b:focus {
  color: var(--colors-primaryDark);
}
._1qhetbf32c:active {
  color: var(--colors-primaryDark);
}
._1qhetbf32d {
  color: var(--colors-success);
}
._1qhetbf32e:hover {
  color: var(--colors-success);
}
._1qhetbf32f:focus {
  color: var(--colors-success);
}
._1qhetbf32g:active {
  color: var(--colors-success);
}
._1qhetbf32h {
  color: var(--colors-success19);
}
._1qhetbf32i:hover {
  color: var(--colors-success19);
}
._1qhetbf32j:focus {
  color: var(--colors-success19);
}
._1qhetbf32k:active {
  color: var(--colors-success19);
}
._1qhetbf32l {
  color: var(--colors-warning);
}
._1qhetbf32m:hover {
  color: var(--colors-warning);
}
._1qhetbf32n:focus {
  color: var(--colors-warning);
}
._1qhetbf32o:active {
  color: var(--colors-warning);
}
._1qhetbf32p {
  color: var(--colors-warning2D);
}
._1qhetbf32q:hover {
  color: var(--colors-warning2D);
}
._1qhetbf32r:focus {
  color: var(--colors-warning2D);
}
._1qhetbf32s:active {
  color: var(--colors-warning2D);
}
._1qhetbf32t {
  color: var(--colors-warning33);
}
._1qhetbf32u:hover {
  color: var(--colors-warning33);
}
._1qhetbf32v:focus {
  color: var(--colors-warning33);
}
._1qhetbf32w:active {
  color: var(--colors-warning33);
}
._1qhetbf32x {
  color: var(--colors-binance);
}
._1qhetbf32y:hover {
  color: var(--colors-binance);
}
._1qhetbf32z:focus {
  color: var(--colors-binance);
}
._1qhetbf330:active {
  color: var(--colors-binance);
}
._1qhetbf331 {
  color: var(--colors-overlay);
}
._1qhetbf332:hover {
  color: var(--colors-overlay);
}
._1qhetbf333:focus {
  color: var(--colors-overlay);
}
._1qhetbf334:active {
  color: var(--colors-overlay);
}
._1qhetbf335 {
  color: var(--colors-gold);
}
._1qhetbf336:hover {
  color: var(--colors-gold);
}
._1qhetbf337:focus {
  color: var(--colors-gold);
}
._1qhetbf338:active {
  color: var(--colors-gold);
}
._1qhetbf339 {
  color: var(--colors-silver);
}
._1qhetbf33a:hover {
  color: var(--colors-silver);
}
._1qhetbf33b:focus {
  color: var(--colors-silver);
}
._1qhetbf33c:active {
  color: var(--colors-silver);
}
._1qhetbf33d {
  color: var(--colors-bronze);
}
._1qhetbf33e:hover {
  color: var(--colors-bronze);
}
._1qhetbf33f:focus {
  color: var(--colors-bronze);
}
._1qhetbf33g:active {
  color: var(--colors-bronze);
}
._1qhetbf33h {
  color: var(--colors-secondary);
}
._1qhetbf33i:hover {
  color: var(--colors-secondary);
}
._1qhetbf33j:focus {
  color: var(--colors-secondary);
}
._1qhetbf33k:active {
  color: var(--colors-secondary);
}
._1qhetbf33l {
  color: var(--colors-secondary80);
}
._1qhetbf33m:hover {
  color: var(--colors-secondary80);
}
._1qhetbf33n:focus {
  color: var(--colors-secondary80);
}
._1qhetbf33o:active {
  color: var(--colors-secondary80);
}
._1qhetbf33p {
  color: var(--colors-background);
}
._1qhetbf33q:hover {
  color: var(--colors-background);
}
._1qhetbf33r:focus {
  color: var(--colors-background);
}
._1qhetbf33s:active {
  color: var(--colors-background);
}
._1qhetbf33t {
  color: var(--colors-backgroundDisabled);
}
._1qhetbf33u:hover {
  color: var(--colors-backgroundDisabled);
}
._1qhetbf33v:focus {
  color: var(--colors-backgroundDisabled);
}
._1qhetbf33w:active {
  color: var(--colors-backgroundDisabled);
}
._1qhetbf33x {
  color: var(--colors-backgroundAlt);
}
._1qhetbf33y:hover {
  color: var(--colors-backgroundAlt);
}
._1qhetbf33z:focus {
  color: var(--colors-backgroundAlt);
}
._1qhetbf340:active {
  color: var(--colors-backgroundAlt);
}
._1qhetbf341 {
  color: var(--colors-backgroundAlt2);
}
._1qhetbf342:hover {
  color: var(--colors-backgroundAlt2);
}
._1qhetbf343:focus {
  color: var(--colors-backgroundAlt2);
}
._1qhetbf344:active {
  color: var(--colors-backgroundAlt2);
}
._1qhetbf345 {
  color: var(--colors-backgroundAlt3);
}
._1qhetbf346:hover {
  color: var(--colors-backgroundAlt3);
}
._1qhetbf347:focus {
  color: var(--colors-backgroundAlt3);
}
._1qhetbf348:active {
  color: var(--colors-backgroundAlt3);
}
._1qhetbf349 {
  color: var(--colors-cardBorder);
}
._1qhetbf34a:hover {
  color: var(--colors-cardBorder);
}
._1qhetbf34b:focus {
  color: var(--colors-cardBorder);
}
._1qhetbf34c:active {
  color: var(--colors-cardBorder);
}
._1qhetbf34d {
  color: var(--colors-contrast);
}
._1qhetbf34e:hover {
  color: var(--colors-contrast);
}
._1qhetbf34f:focus {
  color: var(--colors-contrast);
}
._1qhetbf34g:active {
  color: var(--colors-contrast);
}
._1qhetbf34h {
  color: var(--colors-dropdown);
}
._1qhetbf34i:hover {
  color: var(--colors-dropdown);
}
._1qhetbf34j:focus {
  color: var(--colors-dropdown);
}
._1qhetbf34k:active {
  color: var(--colors-dropdown);
}
._1qhetbf34l {
  color: var(--colors-dropdownDeep);
}
._1qhetbf34m:hover {
  color: var(--colors-dropdownDeep);
}
._1qhetbf34n:focus {
  color: var(--colors-dropdownDeep);
}
._1qhetbf34o:active {
  color: var(--colors-dropdownDeep);
}
._1qhetbf34p {
  color: var(--colors-invertedContrast);
}
._1qhetbf34q:hover {
  color: var(--colors-invertedContrast);
}
._1qhetbf34r:focus {
  color: var(--colors-invertedContrast);
}
._1qhetbf34s:active {
  color: var(--colors-invertedContrast);
}
._1qhetbf34t {
  color: var(--colors-input);
}
._1qhetbf34u:hover {
  color: var(--colors-input);
}
._1qhetbf34v:focus {
  color: var(--colors-input);
}
._1qhetbf34w:active {
  color: var(--colors-input);
}
._1qhetbf34x {
  color: var(--colors-inputSecondary);
}
._1qhetbf34y:hover {
  color: var(--colors-inputSecondary);
}
._1qhetbf34z:focus {
  color: var(--colors-inputSecondary);
}
._1qhetbf350:active {
  color: var(--colors-inputSecondary);
}
._1qhetbf351 {
  color: var(--colors-inputThird);
}
._1qhetbf352:hover {
  color: var(--colors-inputThird);
}
._1qhetbf353:focus {
  color: var(--colors-inputThird);
}
._1qhetbf354:active {
  color: var(--colors-inputThird);
}
._1qhetbf355 {
  color: var(--colors-tertiary);
}
._1qhetbf356:hover {
  color: var(--colors-tertiary);
}
._1qhetbf357:focus {
  color: var(--colors-tertiary);
}
._1qhetbf358:active {
  color: var(--colors-tertiary);
}
._1qhetbf359 {
  color: var(--colors-text);
}
._1qhetbf35a:hover {
  color: var(--colors-text);
}
._1qhetbf35b:focus {
  color: var(--colors-text);
}
._1qhetbf35c:active {
  color: var(--colors-text);
}
._1qhetbf35d {
  color: var(--colors-text99);
}
._1qhetbf35e:hover {
  color: var(--colors-text99);
}
._1qhetbf35f:focus {
  color: var(--colors-text99);
}
._1qhetbf35g:active {
  color: var(--colors-text99);
}
._1qhetbf35h {
  color: var(--colors-textDisabled);
}
._1qhetbf35i:hover {
  color: var(--colors-textDisabled);
}
._1qhetbf35j:focus {
  color: var(--colors-textDisabled);
}
._1qhetbf35k:active {
  color: var(--colors-textDisabled);
}
._1qhetbf35l {
  color: var(--colors-textSubtle);
}
._1qhetbf35m:hover {
  color: var(--colors-textSubtle);
}
._1qhetbf35n:focus {
  color: var(--colors-textSubtle);
}
._1qhetbf35o:active {
  color: var(--colors-textSubtle);
}
._1qhetbf35p {
  color: var(--colors-disabled);
}
._1qhetbf35q:hover {
  color: var(--colors-disabled);
}
._1qhetbf35r:focus {
  color: var(--colors-disabled);
}
._1qhetbf35s:active {
  color: var(--colors-disabled);
}
._1qhetbf35t {
  color: var(--colors-gradientBubblegum);
}
._1qhetbf35u:hover {
  color: var(--colors-gradientBubblegum);
}
._1qhetbf35v:focus {
  color: var(--colors-gradientBubblegum);
}
._1qhetbf35w:active {
  color: var(--colors-gradientBubblegum);
}
._1qhetbf35x {
  color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf35y:hover {
  color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf35z:focus {
  color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf360:active {
  color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf361 {
  color: var(--colors-gradientCardHeader);
}
._1qhetbf362:hover {
  color: var(--colors-gradientCardHeader);
}
._1qhetbf363:focus {
  color: var(--colors-gradientCardHeader);
}
._1qhetbf364:active {
  color: var(--colors-gradientCardHeader);
}
._1qhetbf365 {
  color: var(--colors-gradientBlue);
}
._1qhetbf366:hover {
  color: var(--colors-gradientBlue);
}
._1qhetbf367:focus {
  color: var(--colors-gradientBlue);
}
._1qhetbf368:active {
  color: var(--colors-gradientBlue);
}
._1qhetbf369 {
  color: var(--colors-gradientViolet);
}
._1qhetbf36a:hover {
  color: var(--colors-gradientViolet);
}
._1qhetbf36b:focus {
  color: var(--colors-gradientViolet);
}
._1qhetbf36c:active {
  color: var(--colors-gradientViolet);
}
._1qhetbf36d {
  color: var(--colors-gradientVioletAlt);
}
._1qhetbf36e:hover {
  color: var(--colors-gradientVioletAlt);
}
._1qhetbf36f:focus {
  color: var(--colors-gradientVioletAlt);
}
._1qhetbf36g:active {
  color: var(--colors-gradientVioletAlt);
}
._1qhetbf36h {
  color: var(--colors-gradientGold);
}
._1qhetbf36i:hover {
  color: var(--colors-gradientGold);
}
._1qhetbf36j:focus {
  color: var(--colors-gradientGold);
}
._1qhetbf36k:active {
  color: var(--colors-gradientGold);
}
._1qhetbf36l {
  color: var(--colors-gradientBold);
}
._1qhetbf36m:hover {
  color: var(--colors-gradientBold);
}
._1qhetbf36n:focus {
  color: var(--colors-gradientBold);
}
._1qhetbf36o:active {
  color: var(--colors-gradientBold);
}
._1qhetbf36p {
  color: var(--colors-gradientObsidian);
}
._1qhetbf36q:hover {
  color: var(--colors-gradientObsidian);
}
._1qhetbf36r:focus {
  color: var(--colors-gradientObsidian);
}
._1qhetbf36s:active {
  color: var(--colors-gradientObsidian);
}
._1qhetbf36t {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf36u:hover {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf36v:focus {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf36w:active {
  white: var(--colors-light-white);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  background-alt3: var(--colors-light-backgroundAlt3);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  input-third: var(--colors-light-inputThird);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
  gradient-obsidian: var(--colors-light-gradientObsidian);
}
._1qhetbf36x {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf36y:hover {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf36z:focus {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf370:active {
  white: var(--colors-dark-white);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  background-alt3: var(--colors-dark-backgroundAlt3);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  input-third: var(--colors-dark-inputThird);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
  gradient-obsidian: var(--colors-dark-gradientObsidian);
}
._1qhetbf371 {
  outline-color: var(--colors-white);
}
._1qhetbf372:hover {
  outline-color: var(--colors-white);
}
._1qhetbf373:focus {
  outline-color: var(--colors-white);
}
._1qhetbf374:active {
  outline-color: var(--colors-white);
}
._1qhetbf375 {
  outline-color: var(--colors-failure);
}
._1qhetbf376:hover {
  outline-color: var(--colors-failure);
}
._1qhetbf377:focus {
  outline-color: var(--colors-failure);
}
._1qhetbf378:active {
  outline-color: var(--colors-failure);
}
._1qhetbf379 {
  outline-color: var(--colors-failure33);
}
._1qhetbf37a:hover {
  outline-color: var(--colors-failure33);
}
._1qhetbf37b:focus {
  outline-color: var(--colors-failure33);
}
._1qhetbf37c:active {
  outline-color: var(--colors-failure33);
}
._1qhetbf37d {
  outline-color: var(--colors-primary);
}
._1qhetbf37e:hover {
  outline-color: var(--colors-primary);
}
._1qhetbf37f:focus {
  outline-color: var(--colors-primary);
}
._1qhetbf37g:active {
  outline-color: var(--colors-primary);
}
._1qhetbf37h {
  outline-color: var(--colors-primary0f);
}
._1qhetbf37i:hover {
  outline-color: var(--colors-primary0f);
}
._1qhetbf37j:focus {
  outline-color: var(--colors-primary0f);
}
._1qhetbf37k:active {
  outline-color: var(--colors-primary0f);
}
._1qhetbf37l {
  outline-color: var(--colors-primary3D);
}
._1qhetbf37m:hover {
  outline-color: var(--colors-primary3D);
}
._1qhetbf37n:focus {
  outline-color: var(--colors-primary3D);
}
._1qhetbf37o:active {
  outline-color: var(--colors-primary3D);
}
._1qhetbf37p {
  outline-color: var(--colors-primaryBright);
}
._1qhetbf37q:hover {
  outline-color: var(--colors-primaryBright);
}
._1qhetbf37r:focus {
  outline-color: var(--colors-primaryBright);
}
._1qhetbf37s:active {
  outline-color: var(--colors-primaryBright);
}
._1qhetbf37t {
  outline-color: var(--colors-primaryDark);
}
._1qhetbf37u:hover {
  outline-color: var(--colors-primaryDark);
}
._1qhetbf37v:focus {
  outline-color: var(--colors-primaryDark);
}
._1qhetbf37w:active {
  outline-color: var(--colors-primaryDark);
}
._1qhetbf37x {
  outline-color: var(--colors-success);
}
._1qhetbf37y:hover {
  outline-color: var(--colors-success);
}
._1qhetbf37z:focus {
  outline-color: var(--colors-success);
}
._1qhetbf380:active {
  outline-color: var(--colors-success);
}
._1qhetbf381 {
  outline-color: var(--colors-success19);
}
._1qhetbf382:hover {
  outline-color: var(--colors-success19);
}
._1qhetbf383:focus {
  outline-color: var(--colors-success19);
}
._1qhetbf384:active {
  outline-color: var(--colors-success19);
}
._1qhetbf385 {
  outline-color: var(--colors-warning);
}
._1qhetbf386:hover {
  outline-color: var(--colors-warning);
}
._1qhetbf387:focus {
  outline-color: var(--colors-warning);
}
._1qhetbf388:active {
  outline-color: var(--colors-warning);
}
._1qhetbf389 {
  outline-color: var(--colors-warning2D);
}
._1qhetbf38a:hover {
  outline-color: var(--colors-warning2D);
}
._1qhetbf38b:focus {
  outline-color: var(--colors-warning2D);
}
._1qhetbf38c:active {
  outline-color: var(--colors-warning2D);
}
._1qhetbf38d {
  outline-color: var(--colors-warning33);
}
._1qhetbf38e:hover {
  outline-color: var(--colors-warning33);
}
._1qhetbf38f:focus {
  outline-color: var(--colors-warning33);
}
._1qhetbf38g:active {
  outline-color: var(--colors-warning33);
}
._1qhetbf38h {
  outline-color: var(--colors-binance);
}
._1qhetbf38i:hover {
  outline-color: var(--colors-binance);
}
._1qhetbf38j:focus {
  outline-color: var(--colors-binance);
}
._1qhetbf38k:active {
  outline-color: var(--colors-binance);
}
._1qhetbf38l {
  outline-color: var(--colors-overlay);
}
._1qhetbf38m:hover {
  outline-color: var(--colors-overlay);
}
._1qhetbf38n:focus {
  outline-color: var(--colors-overlay);
}
._1qhetbf38o:active {
  outline-color: var(--colors-overlay);
}
._1qhetbf38p {
  outline-color: var(--colors-gold);
}
._1qhetbf38q:hover {
  outline-color: var(--colors-gold);
}
._1qhetbf38r:focus {
  outline-color: var(--colors-gold);
}
._1qhetbf38s:active {
  outline-color: var(--colors-gold);
}
._1qhetbf38t {
  outline-color: var(--colors-silver);
}
._1qhetbf38u:hover {
  outline-color: var(--colors-silver);
}
._1qhetbf38v:focus {
  outline-color: var(--colors-silver);
}
._1qhetbf38w:active {
  outline-color: var(--colors-silver);
}
._1qhetbf38x {
  outline-color: var(--colors-bronze);
}
._1qhetbf38y:hover {
  outline-color: var(--colors-bronze);
}
._1qhetbf38z:focus {
  outline-color: var(--colors-bronze);
}
._1qhetbf390:active {
  outline-color: var(--colors-bronze);
}
._1qhetbf391 {
  outline-color: var(--colors-secondary);
}
._1qhetbf392:hover {
  outline-color: var(--colors-secondary);
}
._1qhetbf393:focus {
  outline-color: var(--colors-secondary);
}
._1qhetbf394:active {
  outline-color: var(--colors-secondary);
}
._1qhetbf395 {
  outline-color: var(--colors-secondary80);
}
._1qhetbf396:hover {
  outline-color: var(--colors-secondary80);
}
._1qhetbf397:focus {
  outline-color: var(--colors-secondary80);
}
._1qhetbf398:active {
  outline-color: var(--colors-secondary80);
}
._1qhetbf399 {
  outline-color: var(--colors-background);
}
._1qhetbf39a:hover {
  outline-color: var(--colors-background);
}
._1qhetbf39b:focus {
  outline-color: var(--colors-background);
}
._1qhetbf39c:active {
  outline-color: var(--colors-background);
}
._1qhetbf39d {
  outline-color: var(--colors-backgroundDisabled);
}
._1qhetbf39e:hover {
  outline-color: var(--colors-backgroundDisabled);
}
._1qhetbf39f:focus {
  outline-color: var(--colors-backgroundDisabled);
}
._1qhetbf39g:active {
  outline-color: var(--colors-backgroundDisabled);
}
._1qhetbf39h {
  outline-color: var(--colors-backgroundAlt);
}
._1qhetbf39i:hover {
  outline-color: var(--colors-backgroundAlt);
}
._1qhetbf39j:focus {
  outline-color: var(--colors-backgroundAlt);
}
._1qhetbf39k:active {
  outline-color: var(--colors-backgroundAlt);
}
._1qhetbf39l {
  outline-color: var(--colors-backgroundAlt2);
}
._1qhetbf39m:hover {
  outline-color: var(--colors-backgroundAlt2);
}
._1qhetbf39n:focus {
  outline-color: var(--colors-backgroundAlt2);
}
._1qhetbf39o:active {
  outline-color: var(--colors-backgroundAlt2);
}
._1qhetbf39p {
  outline-color: var(--colors-backgroundAlt3);
}
._1qhetbf39q:hover {
  outline-color: var(--colors-backgroundAlt3);
}
._1qhetbf39r:focus {
  outline-color: var(--colors-backgroundAlt3);
}
._1qhetbf39s:active {
  outline-color: var(--colors-backgroundAlt3);
}
._1qhetbf39t {
  outline-color: var(--colors-cardBorder);
}
._1qhetbf39u:hover {
  outline-color: var(--colors-cardBorder);
}
._1qhetbf39v:focus {
  outline-color: var(--colors-cardBorder);
}
._1qhetbf39w:active {
  outline-color: var(--colors-cardBorder);
}
._1qhetbf39x {
  outline-color: var(--colors-contrast);
}
._1qhetbf39y:hover {
  outline-color: var(--colors-contrast);
}
._1qhetbf39z:focus {
  outline-color: var(--colors-contrast);
}
._1qhetbf3a0:active {
  outline-color: var(--colors-contrast);
}
._1qhetbf3a1 {
  outline-color: var(--colors-dropdown);
}
._1qhetbf3a2:hover {
  outline-color: var(--colors-dropdown);
}
._1qhetbf3a3:focus {
  outline-color: var(--colors-dropdown);
}
._1qhetbf3a4:active {
  outline-color: var(--colors-dropdown);
}
._1qhetbf3a5 {
  outline-color: var(--colors-dropdownDeep);
}
._1qhetbf3a6:hover {
  outline-color: var(--colors-dropdownDeep);
}
._1qhetbf3a7:focus {
  outline-color: var(--colors-dropdownDeep);
}
._1qhetbf3a8:active {
  outline-color: var(--colors-dropdownDeep);
}
._1qhetbf3a9 {
  outline-color: var(--colors-invertedContrast);
}
._1qhetbf3aa:hover {
  outline-color: var(--colors-invertedContrast);
}
._1qhetbf3ab:focus {
  outline-color: var(--colors-invertedContrast);
}
._1qhetbf3ac:active {
  outline-color: var(--colors-invertedContrast);
}
._1qhetbf3ad {
  outline-color: var(--colors-input);
}
._1qhetbf3ae:hover {
  outline-color: var(--colors-input);
}
._1qhetbf3af:focus {
  outline-color: var(--colors-input);
}
._1qhetbf3ag:active {
  outline-color: var(--colors-input);
}
._1qhetbf3ah {
  outline-color: var(--colors-inputSecondary);
}
._1qhetbf3ai:hover {
  outline-color: var(--colors-inputSecondary);
}
._1qhetbf3aj:focus {
  outline-color: var(--colors-inputSecondary);
}
._1qhetbf3ak:active {
  outline-color: var(--colors-inputSecondary);
}
._1qhetbf3al {
  outline-color: var(--colors-inputThird);
}
._1qhetbf3am:hover {
  outline-color: var(--colors-inputThird);
}
._1qhetbf3an:focus {
  outline-color: var(--colors-inputThird);
}
._1qhetbf3ao:active {
  outline-color: var(--colors-inputThird);
}
._1qhetbf3ap {
  outline-color: var(--colors-tertiary);
}
._1qhetbf3aq:hover {
  outline-color: var(--colors-tertiary);
}
._1qhetbf3ar:focus {
  outline-color: var(--colors-tertiary);
}
._1qhetbf3as:active {
  outline-color: var(--colors-tertiary);
}
._1qhetbf3at {
  outline-color: var(--colors-text);
}
._1qhetbf3au:hover {
  outline-color: var(--colors-text);
}
._1qhetbf3av:focus {
  outline-color: var(--colors-text);
}
._1qhetbf3aw:active {
  outline-color: var(--colors-text);
}
._1qhetbf3ax {
  outline-color: var(--colors-text99);
}
._1qhetbf3ay:hover {
  outline-color: var(--colors-text99);
}
._1qhetbf3az:focus {
  outline-color: var(--colors-text99);
}
._1qhetbf3b0:active {
  outline-color: var(--colors-text99);
}
._1qhetbf3b1 {
  outline-color: var(--colors-textDisabled);
}
._1qhetbf3b2:hover {
  outline-color: var(--colors-textDisabled);
}
._1qhetbf3b3:focus {
  outline-color: var(--colors-textDisabled);
}
._1qhetbf3b4:active {
  outline-color: var(--colors-textDisabled);
}
._1qhetbf3b5 {
  outline-color: var(--colors-textSubtle);
}
._1qhetbf3b6:hover {
  outline-color: var(--colors-textSubtle);
}
._1qhetbf3b7:focus {
  outline-color: var(--colors-textSubtle);
}
._1qhetbf3b8:active {
  outline-color: var(--colors-textSubtle);
}
._1qhetbf3b9 {
  outline-color: var(--colors-disabled);
}
._1qhetbf3ba:hover {
  outline-color: var(--colors-disabled);
}
._1qhetbf3bb:focus {
  outline-color: var(--colors-disabled);
}
._1qhetbf3bc:active {
  outline-color: var(--colors-disabled);
}
._1qhetbf3bd {
  outline-color: var(--colors-gradientBubblegum);
}
._1qhetbf3be:hover {
  outline-color: var(--colors-gradientBubblegum);
}
._1qhetbf3bf:focus {
  outline-color: var(--colors-gradientBubblegum);
}
._1qhetbf3bg:active {
  outline-color: var(--colors-gradientBubblegum);
}
._1qhetbf3bh {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf3bi:hover {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf3bj:focus {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf3bk:active {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1qhetbf3bl {
  outline-color: var(--colors-gradientCardHeader);
}
._1qhetbf3bm:hover {
  outline-color: var(--colors-gradientCardHeader);
}
._1qhetbf3bn:focus {
  outline-color: var(--colors-gradientCardHeader);
}
._1qhetbf3bo:active {
  outline-color: var(--colors-gradientCardHeader);
}
._1qhetbf3bp {
  outline-color: var(--colors-gradientBlue);
}
._1qhetbf3bq:hover {
  outline-color: var(--colors-gradientBlue);
}
._1qhetbf3br:focus {
  outline-color: var(--colors-gradientBlue);
}
._1qhetbf3bs:active {
  outline-color: var(--colors-gradientBlue);
}
._1qhetbf3bt {
  outline-color: var(--colors-gradientViolet);
}
._1qhetbf3bu:hover {
  outline-color: var(--colors-gradientViolet);
}
._1qhetbf3bv:focus {
  outline-color: var(--colors-gradientViolet);
}
._1qhetbf3bw:active {
  outline-color: var(--colors-gradientViolet);
}
._1qhetbf3bx {
  outline-color: var(--colors-gradientVioletAlt);
}
._1qhetbf3by:hover {
  outline-color: var(--colors-gradientVioletAlt);
}
._1qhetbf3bz:focus {
  outline-color: var(--colors-gradientVioletAlt);
}
._1qhetbf3c0:active {
  outline-color: var(--colors-gradientVioletAlt);
}
._1qhetbf3c1 {
  outline-color: var(--colors-gradientGold);
}
._1qhetbf3c2:hover {
  outline-color: var(--colors-gradientGold);
}
._1qhetbf3c3:focus {
  outline-color: var(--colors-gradientGold);
}
._1qhetbf3c4:active {
  outline-color: var(--colors-gradientGold);
}
._1qhetbf3c5 {
  outline-color: var(--colors-gradientBold);
}
._1qhetbf3c6:hover {
  outline-color: var(--colors-gradientBold);
}
._1qhetbf3c7:focus {
  outline-color: var(--colors-gradientBold);
}
._1qhetbf3c8:active {
  outline-color: var(--colors-gradientBold);
}
._1qhetbf3c9 {
  outline-color: var(--colors-gradientObsidian);
}
._1qhetbf3ca:hover {
  outline-color: var(--colors-gradientObsidian);
}
._1qhetbf3cb:focus {
  outline-color: var(--colors-gradientObsidian);
}
._1qhetbf3cc:active {
  outline-color: var(--colors-gradientObsidian);
}
@media (min-width: 576px) {
  ._1qhetbf1 {
    display: block;
  }
  ._1qhetbf7 {
    display: flex;
  }
  ._1qhetbfd {
    display: grid;
  }
  ._1qhetbfj {
    display: inline;
  }
  ._1qhetbfp {
    display: inline-flex;
  }
  ._1qhetbfv {
    display: inline-block;
  }
  ._1qhetbf11 {
    display: none;
  }
  ._1qhetbf17 {
    flex-direction: column;
  }
  ._1qhetbf1d {
    flex-direction: row;
  }
  ._1qhetbf1j {
    flex-direction: column-reverse;
  }
  ._1qhetbf1p {
    align-items: center;
  }
  ._1qhetbf1v {
    align-items: end;
  }
  ._1qhetbf21 {
    align-items: baseLine;
  }
  ._1qhetbf27 {
    align-items: inherit;
  }
  ._1qhetbf2d {
    align-items: flex-start;
  }
  ._1qhetbf2j {
    align-items: center;
  }
  ._1qhetbf2p {
    align-items: start;
  }
  ._1qhetbf2v {
    align-items: flex-end;
  }
  ._1qhetbf31 {
    align-items: stretch;
  }
  ._1qhetbf37 {
    align-self: flex-start;
  }
  ._1qhetbf3d {
    align-self: center;
  }
  ._1qhetbf3j {
    align-self: start;
  }
  ._1qhetbf3p {
    align-self: flex-end;
  }
  ._1qhetbf3v {
    align-self: stretch;
  }
  ._1qhetbf41 {
    flex-wrap: wrap;
  }
  ._1qhetbf47 {
    flex-wrap: nowrap;
  }
  ._1qhetbf4d {
    flex-grow: 1;
  }
  ._1qhetbf4j {
    overflow: auto;
  }
  ._1qhetbf4p {
    overflow: hidden;
  }
  ._1qhetbf4v {
    overflow: scroll;
  }
  ._1qhetbf51 {
    overflow: unset;
  }
  ._1qhetbf57 {
    overflow-y: auto;
  }
  ._1qhetbf5d {
    overflow-y: hidden;
  }
  ._1qhetbf5j {
    overflow-y: scroll;
  }
  ._1qhetbf5p {
    overflow-x: auto;
  }
  ._1qhetbf5v {
    overflow-x: hidden;
  }
  ._1qhetbf61 {
    overflow-x: scroll;
  }
  ._1qhetbf67 {
    position: absolute;
  }
  ._1qhetbf6d {
    position: fixed;
  }
  ._1qhetbf6j {
    position: relative;
  }
  ._1qhetbf6p {
    position: sticky;
  }
  ._1qhetbf6v {
    text-align: center;
  }
  ._1qhetbf71 {
    text-align: left;
  }
  ._1qhetbf77 {
    text-align: right;
  }
  ._1qhetbf7d {
    justify-content: flex-start;
  }
  ._1qhetbf7j {
    justify-content: center;
  }
  ._1qhetbf7p {
    justify-content: start;
  }
  ._1qhetbf7v {
    justify-content: flex-end;
  }
  ._1qhetbf81 {
    justify-content: stretch;
  }
  ._1qhetbf87 {
    justify-content: space-around;
  }
  ._1qhetbf8d {
    justify-content: space-between;
  }
  ._1qhetbf8j {
    justify-items: flex-start;
  }
  ._1qhetbf8p {
    justify-items: center;
  }
  ._1qhetbf8v {
    justify-items: start;
  }
  ._1qhetbf91 {
    justify-items: flex-end;
  }
  ._1qhetbf97 {
    justify-items: stretch;
  }
  ._1qhetbf9d {
    justify-items: space-around;
  }
  ._1qhetbf9j {
    justify-items: space-between;
  }
  ._1qhetbf9p {
    justify-self: flex-start;
  }
  ._1qhetbf9v {
    justify-self: center;
  }
  ._1qhetbfa1 {
    justify-self: start;
  }
  ._1qhetbfa7 {
    justify-self: flex-end;
  }
  ._1qhetbfad {
    justify-self: stretch;
  }
  ._1qhetbfaj {
    inset: 0px;
  }
  ._1qhetbfap {
    height: var(--space-0px);
  }
  ._1qhetbfav {
    height: var(--space-1rem);
  }
  ._1qhetbfb1 {
    height: var(--space-1px);
  }
  ._1qhetbfb7 {
    height: var(--space-2px);
  }
  ._1qhetbfbd {
    height: var(--space-6px);
  }
  ._1qhetbfbj {
    height: var(--space-4px);
  }
  ._1qhetbfbp {
    height: var(--space-8px);
  }
  ._1qhetbfbv {
    height: var(--space-12px);
  }
  ._1qhetbfc1 {
    height: var(--space-14px);
  }
  ._1qhetbfc7 {
    height: var(--space-16px);
  }
  ._1qhetbfcd {
    height: var(--space-20px);
  }
  ._1qhetbfcj {
    height: var(--space-24px);
  }
  ._1qhetbfcp {
    height: var(--space-32px);
  }
  ._1qhetbfcv {
    height: var(--space-48px);
  }
  ._1qhetbfd1 {
    height: var(--space-56px);
  }
  ._1qhetbfd7 {
    height: var(--space-64px);
  }
  ._1qhetbfdd {
    height: 100%;
  }
  ._1qhetbfdj {
    height: auto;
  }
  ._1qhetbfdp {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1qhetbfdv {
    height: 420px;
  }
  ._1qhetbfe1 {
    height: 576px;
  }
  ._1qhetbfe7 {
    height: 852px;
  }
  ._1qhetbfed {
    height: 968px;
  }
  ._1qhetbfej {
    height: 1080px;
  }
  ._1qhetbfep {
    height: 100vh;
  }
  ._1qhetbfev {
    margin-bottom: var(--space-0px);
  }
  ._1qhetbff1 {
    margin-bottom: var(--space-1rem);
  }
  ._1qhetbff7 {
    margin-bottom: var(--space-1px);
  }
  ._1qhetbffd {
    margin-bottom: var(--space-2px);
  }
  ._1qhetbffj {
    margin-bottom: var(--space-6px);
  }
  ._1qhetbffp {
    margin-bottom: var(--space-4px);
  }
  ._1qhetbffv {
    margin-bottom: var(--space-8px);
  }
  ._1qhetbfg1 {
    margin-bottom: var(--space-12px);
  }
  ._1qhetbfg7 {
    margin-bottom: var(--space-14px);
  }
  ._1qhetbfgd {
    margin-bottom: var(--space-16px);
  }
  ._1qhetbfgj {
    margin-bottom: var(--space-20px);
  }
  ._1qhetbfgp {
    margin-bottom: var(--space-24px);
  }
  ._1qhetbfgv {
    margin-bottom: var(--space-32px);
  }
  ._1qhetbfh1 {
    margin-bottom: var(--space-48px);
  }
  ._1qhetbfh7 {
    margin-bottom: var(--space-56px);
  }
  ._1qhetbfhd {
    margin-bottom: var(--space-64px);
  }
  ._1qhetbfhj {
    margin-bottom: auto;
  }
  ._1qhetbfhp {
    margin-left: var(--space-0px);
  }
  ._1qhetbfhv {
    margin-left: var(--space-1rem);
  }
  ._1qhetbfi1 {
    margin-left: var(--space-1px);
  }
  ._1qhetbfi7 {
    margin-left: var(--space-2px);
  }
  ._1qhetbfid {
    margin-left: var(--space-6px);
  }
  ._1qhetbfij {
    margin-left: var(--space-4px);
  }
  ._1qhetbfip {
    margin-left: var(--space-8px);
  }
  ._1qhetbfiv {
    margin-left: var(--space-12px);
  }
  ._1qhetbfj1 {
    margin-left: var(--space-14px);
  }
  ._1qhetbfj7 {
    margin-left: var(--space-16px);
  }
  ._1qhetbfjd {
    margin-left: var(--space-20px);
  }
  ._1qhetbfjj {
    margin-left: var(--space-24px);
  }
  ._1qhetbfjp {
    margin-left: var(--space-32px);
  }
  ._1qhetbfjv {
    margin-left: var(--space-48px);
  }
  ._1qhetbfk1 {
    margin-left: var(--space-56px);
  }
  ._1qhetbfk7 {
    margin-left: var(--space-64px);
  }
  ._1qhetbfkd {
    margin-left: auto;
  }
  ._1qhetbfkj {
    margin-right: var(--space-0px);
  }
  ._1qhetbfkp {
    margin-right: var(--space-1rem);
  }
  ._1qhetbfkv {
    margin-right: var(--space-1px);
  }
  ._1qhetbfl1 {
    margin-right: var(--space-2px);
  }
  ._1qhetbfl7 {
    margin-right: var(--space-6px);
  }
  ._1qhetbfld {
    margin-right: var(--space-4px);
  }
  ._1qhetbflj {
    margin-right: var(--space-8px);
  }
  ._1qhetbflp {
    margin-right: var(--space-12px);
  }
  ._1qhetbflv {
    margin-right: var(--space-14px);
  }
  ._1qhetbfm1 {
    margin-right: var(--space-16px);
  }
  ._1qhetbfm7 {
    margin-right: var(--space-20px);
  }
  ._1qhetbfmd {
    margin-right: var(--space-24px);
  }
  ._1qhetbfmj {
    margin-right: var(--space-32px);
  }
  ._1qhetbfmp {
    margin-right: var(--space-48px);
  }
  ._1qhetbfmv {
    margin-right: var(--space-56px);
  }
  ._1qhetbfn1 {
    margin-right: var(--space-64px);
  }
  ._1qhetbfn7 {
    margin-right: auto;
  }
  ._1qhetbfnd {
    margin-top: var(--space-0px);
  }
  ._1qhetbfnj {
    margin-top: var(--space-1rem);
  }
  ._1qhetbfnp {
    margin-top: var(--space-1px);
  }
  ._1qhetbfnv {
    margin-top: var(--space-2px);
  }
  ._1qhetbfo1 {
    margin-top: var(--space-6px);
  }
  ._1qhetbfo7 {
    margin-top: var(--space-4px);
  }
  ._1qhetbfod {
    margin-top: var(--space-8px);
  }
  ._1qhetbfoj {
    margin-top: var(--space-12px);
  }
  ._1qhetbfop {
    margin-top: var(--space-14px);
  }
  ._1qhetbfov {
    margin-top: var(--space-16px);
  }
  ._1qhetbfp1 {
    margin-top: var(--space-20px);
  }
  ._1qhetbfp7 {
    margin-top: var(--space-24px);
  }
  ._1qhetbfpd {
    margin-top: var(--space-32px);
  }
  ._1qhetbfpj {
    margin-top: var(--space-48px);
  }
  ._1qhetbfpp {
    margin-top: var(--space-56px);
  }
  ._1qhetbfpv {
    margin-top: var(--space-64px);
  }
  ._1qhetbfq1 {
    margin-top: auto;
  }
  ._1qhetbfq7 {
    margin: var(--space-0px);
  }
  ._1qhetbfqd {
    margin: var(--space-1rem);
  }
  ._1qhetbfqj {
    margin: var(--space-1px);
  }
  ._1qhetbfqp {
    margin: var(--space-2px);
  }
  ._1qhetbfqv {
    margin: var(--space-6px);
  }
  ._1qhetbfr1 {
    margin: var(--space-4px);
  }
  ._1qhetbfr7 {
    margin: var(--space-8px);
  }
  ._1qhetbfrd {
    margin: var(--space-12px);
  }
  ._1qhetbfrj {
    margin: var(--space-14px);
  }
  ._1qhetbfrp {
    margin: var(--space-16px);
  }
  ._1qhetbfrv {
    margin: var(--space-20px);
  }
  ._1qhetbfs1 {
    margin: var(--space-24px);
  }
  ._1qhetbfs7 {
    margin: var(--space-32px);
  }
  ._1qhetbfsd {
    margin: var(--space-48px);
  }
  ._1qhetbfsj {
    margin: var(--space-56px);
  }
  ._1qhetbfsp {
    margin: var(--space-64px);
  }
  ._1qhetbfsv {
    margin: auto;
  }
  ._1qhetbft1 {
    padding: var(--space-0px);
  }
  ._1qhetbft7 {
    padding: var(--space-1rem);
  }
  ._1qhetbftd {
    padding: var(--space-1px);
  }
  ._1qhetbftj {
    padding: var(--space-2px);
  }
  ._1qhetbftp {
    padding: var(--space-6px);
  }
  ._1qhetbftv {
    padding: var(--space-4px);
  }
  ._1qhetbfu1 {
    padding: var(--space-8px);
  }
  ._1qhetbfu7 {
    padding: var(--space-12px);
  }
  ._1qhetbfud {
    padding: var(--space-14px);
  }
  ._1qhetbfuj {
    padding: var(--space-16px);
  }
  ._1qhetbfup {
    padding: var(--space-20px);
  }
  ._1qhetbfuv {
    padding: var(--space-24px);
  }
  ._1qhetbfv1 {
    padding: var(--space-32px);
  }
  ._1qhetbfv7 {
    padding: var(--space-48px);
  }
  ._1qhetbfvd {
    padding: var(--space-56px);
  }
  ._1qhetbfvj {
    padding: var(--space-64px);
  }
  ._1qhetbfvp {
    max-height: var(--space-0px);
  }
  ._1qhetbfvv {
    max-height: var(--space-1rem);
  }
  ._1qhetbfw1 {
    max-height: var(--space-1px);
  }
  ._1qhetbfw7 {
    max-height: var(--space-2px);
  }
  ._1qhetbfwd {
    max-height: var(--space-6px);
  }
  ._1qhetbfwj {
    max-height: var(--space-4px);
  }
  ._1qhetbfwp {
    max-height: var(--space-8px);
  }
  ._1qhetbfwv {
    max-height: var(--space-12px);
  }
  ._1qhetbfx1 {
    max-height: var(--space-14px);
  }
  ._1qhetbfx7 {
    max-height: var(--space-16px);
  }
  ._1qhetbfxd {
    max-height: var(--space-20px);
  }
  ._1qhetbfxj {
    max-height: var(--space-24px);
  }
  ._1qhetbfxp {
    max-height: var(--space-32px);
  }
  ._1qhetbfxv {
    max-height: var(--space-48px);
  }
  ._1qhetbfy1 {
    max-height: var(--space-56px);
  }
  ._1qhetbfy7 {
    max-height: var(--space-64px);
  }
  ._1qhetbfyd {
    max-width: var(--space-0px);
  }
  ._1qhetbfyj {
    max-width: var(--space-1rem);
  }
  ._1qhetbfyp {
    max-width: var(--space-1px);
  }
  ._1qhetbfyv {
    max-width: var(--space-2px);
  }
  ._1qhetbfz1 {
    max-width: var(--space-6px);
  }
  ._1qhetbfz7 {
    max-width: var(--space-4px);
  }
  ._1qhetbfzd {
    max-width: var(--space-8px);
  }
  ._1qhetbfzj {
    max-width: var(--space-12px);
  }
  ._1qhetbfzp {
    max-width: var(--space-14px);
  }
  ._1qhetbfzv {
    max-width: var(--space-16px);
  }
  ._1qhetbf101 {
    max-width: var(--space-20px);
  }
  ._1qhetbf107 {
    max-width: var(--space-24px);
  }
  ._1qhetbf10d {
    max-width: var(--space-32px);
  }
  ._1qhetbf10j {
    max-width: var(--space-48px);
  }
  ._1qhetbf10p {
    max-width: var(--space-56px);
  }
  ._1qhetbf10v {
    max-width: var(--space-64px);
  }
  ._1qhetbf111 {
    max-width: 100%;
  }
  ._1qhetbf117 {
    max-width: auto;
  }
  ._1qhetbf11d {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1qhetbf11j {
    max-width: 420px;
  }
  ._1qhetbf11p {
    max-width: 576px;
  }
  ._1qhetbf11v {
    max-width: 852px;
  }
  ._1qhetbf121 {
    max-width: 968px;
  }
  ._1qhetbf127 {
    max-width: 1080px;
  }
  ._1qhetbf12d {
    max-width: none;
  }
  ._1qhetbf12j {
    min-height: var(--space-0px);
  }
  ._1qhetbf12p {
    min-height: var(--space-1rem);
  }
  ._1qhetbf12v {
    min-height: var(--space-1px);
  }
  ._1qhetbf131 {
    min-height: var(--space-2px);
  }
  ._1qhetbf137 {
    min-height: var(--space-6px);
  }
  ._1qhetbf13d {
    min-height: var(--space-4px);
  }
  ._1qhetbf13j {
    min-height: var(--space-8px);
  }
  ._1qhetbf13p {
    min-height: var(--space-12px);
  }
  ._1qhetbf13v {
    min-height: var(--space-14px);
  }
  ._1qhetbf141 {
    min-height: var(--space-16px);
  }
  ._1qhetbf147 {
    min-height: var(--space-20px);
  }
  ._1qhetbf14d {
    min-height: var(--space-24px);
  }
  ._1qhetbf14j {
    min-height: var(--space-32px);
  }
  ._1qhetbf14p {
    min-height: var(--space-48px);
  }
  ._1qhetbf14v {
    min-height: var(--space-56px);
  }
  ._1qhetbf151 {
    min-height: var(--space-64px);
  }
  ._1qhetbf157 {
    min-height: 100%;
  }
  ._1qhetbf15d {
    min-height: auto;
  }
  ._1qhetbf15j {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1qhetbf15p {
    min-height: 420px;
  }
  ._1qhetbf15v {
    min-height: 576px;
  }
  ._1qhetbf161 {
    min-height: 852px;
  }
  ._1qhetbf167 {
    min-height: 968px;
  }
  ._1qhetbf16d {
    min-height: 1080px;
  }
  ._1qhetbf16j {
    min-height: 100vh;
  }
  ._1qhetbf16p {
    min-width: var(--space-0px);
  }
  ._1qhetbf16v {
    min-width: var(--space-1rem);
  }
  ._1qhetbf171 {
    min-width: var(--space-1px);
  }
  ._1qhetbf177 {
    min-width: var(--space-2px);
  }
  ._1qhetbf17d {
    min-width: var(--space-6px);
  }
  ._1qhetbf17j {
    min-width: var(--space-4px);
  }
  ._1qhetbf17p {
    min-width: var(--space-8px);
  }
  ._1qhetbf17v {
    min-width: var(--space-12px);
  }
  ._1qhetbf181 {
    min-width: var(--space-14px);
  }
  ._1qhetbf187 {
    min-width: var(--space-16px);
  }
  ._1qhetbf18d {
    min-width: var(--space-20px);
  }
  ._1qhetbf18j {
    min-width: var(--space-24px);
  }
  ._1qhetbf18p {
    min-width: var(--space-32px);
  }
  ._1qhetbf18v {
    min-width: var(--space-48px);
  }
  ._1qhetbf191 {
    min-width: var(--space-56px);
  }
  ._1qhetbf197 {
    min-width: var(--space-64px);
  }
  ._1qhetbf19d {
    padding-bottom: var(--space-0px);
  }
  ._1qhetbf19j {
    padding-bottom: var(--space-1rem);
  }
  ._1qhetbf19p {
    padding-bottom: var(--space-1px);
  }
  ._1qhetbf19v {
    padding-bottom: var(--space-2px);
  }
  ._1qhetbf1a1 {
    padding-bottom: var(--space-6px);
  }
  ._1qhetbf1a7 {
    padding-bottom: var(--space-4px);
  }
  ._1qhetbf1ad {
    padding-bottom: var(--space-8px);
  }
  ._1qhetbf1aj {
    padding-bottom: var(--space-12px);
  }
  ._1qhetbf1ap {
    padding-bottom: var(--space-14px);
  }
  ._1qhetbf1av {
    padding-bottom: var(--space-16px);
  }
  ._1qhetbf1b1 {
    padding-bottom: var(--space-20px);
  }
  ._1qhetbf1b7 {
    padding-bottom: var(--space-24px);
  }
  ._1qhetbf1bd {
    padding-bottom: var(--space-32px);
  }
  ._1qhetbf1bj {
    padding-bottom: var(--space-48px);
  }
  ._1qhetbf1bp {
    padding-bottom: var(--space-56px);
  }
  ._1qhetbf1bv {
    padding-bottom: var(--space-64px);
  }
  ._1qhetbf1c1 {
    padding-left: var(--space-0px);
  }
  ._1qhetbf1c7 {
    padding-left: var(--space-1rem);
  }
  ._1qhetbf1cd {
    padding-left: var(--space-1px);
  }
  ._1qhetbf1cj {
    padding-left: var(--space-2px);
  }
  ._1qhetbf1cp {
    padding-left: var(--space-6px);
  }
  ._1qhetbf1cv {
    padding-left: var(--space-4px);
  }
  ._1qhetbf1d1 {
    padding-left: var(--space-8px);
  }
  ._1qhetbf1d7 {
    padding-left: var(--space-12px);
  }
  ._1qhetbf1dd {
    padding-left: var(--space-14px);
  }
  ._1qhetbf1dj {
    padding-left: var(--space-16px);
  }
  ._1qhetbf1dp {
    padding-left: var(--space-20px);
  }
  ._1qhetbf1dv {
    padding-left: var(--space-24px);
  }
  ._1qhetbf1e1 {
    padding-left: var(--space-32px);
  }
  ._1qhetbf1e7 {
    padding-left: var(--space-48px);
  }
  ._1qhetbf1ed {
    padding-left: var(--space-56px);
  }
  ._1qhetbf1ej {
    padding-left: var(--space-64px);
  }
  ._1qhetbf1ep {
    padding-right: var(--space-0px);
  }
  ._1qhetbf1ev {
    padding-right: var(--space-1rem);
  }
  ._1qhetbf1f1 {
    padding-right: var(--space-1px);
  }
  ._1qhetbf1f7 {
    padding-right: var(--space-2px);
  }
  ._1qhetbf1fd {
    padding-right: var(--space-6px);
  }
  ._1qhetbf1fj {
    padding-right: var(--space-4px);
  }
  ._1qhetbf1fp {
    padding-right: var(--space-8px);
  }
  ._1qhetbf1fv {
    padding-right: var(--space-12px);
  }
  ._1qhetbf1g1 {
    padding-right: var(--space-14px);
  }
  ._1qhetbf1g7 {
    padding-right: var(--space-16px);
  }
  ._1qhetbf1gd {
    padding-right: var(--space-20px);
  }
  ._1qhetbf1gj {
    padding-right: var(--space-24px);
  }
  ._1qhetbf1gp {
    padding-right: var(--space-32px);
  }
  ._1qhetbf1gv {
    padding-right: var(--space-48px);
  }
  ._1qhetbf1h1 {
    padding-right: var(--space-56px);
  }
  ._1qhetbf1h7 {
    padding-right: var(--space-64px);
  }
  ._1qhetbf1hd {
    padding-top: var(--space-0px);
  }
  ._1qhetbf1hj {
    padding-top: var(--space-1rem);
  }
  ._1qhetbf1hp {
    padding-top: var(--space-1px);
  }
  ._1qhetbf1hv {
    padding-top: var(--space-2px);
  }
  ._1qhetbf1i1 {
    padding-top: var(--space-6px);
  }
  ._1qhetbf1i7 {
    padding-top: var(--space-4px);
  }
  ._1qhetbf1id {
    padding-top: var(--space-8px);
  }
  ._1qhetbf1ij {
    padding-top: var(--space-12px);
  }
  ._1qhetbf1ip {
    padding-top: var(--space-14px);
  }
  ._1qhetbf1iv {
    padding-top: var(--space-16px);
  }
  ._1qhetbf1j1 {
    padding-top: var(--space-20px);
  }
  ._1qhetbf1j7 {
    padding-top: var(--space-24px);
  }
  ._1qhetbf1jd {
    padding-top: var(--space-32px);
  }
  ._1qhetbf1jj {
    padding-top: var(--space-48px);
  }
  ._1qhetbf1jp {
    padding-top: var(--space-56px);
  }
  ._1qhetbf1jv {
    padding-top: var(--space-64px);
  }
  ._1qhetbf1k1 {
    font-size: var(--fontSizes-10px);
  }
  ._1qhetbf1k7 {
    font-size: var(--fontSizes-12px);
  }
  ._1qhetbf1kd {
    font-size: var(--fontSizes-16px);
  }
  ._1qhetbf1kj {
    font-size: var(--fontSizes-14px);
  }
  ._1qhetbf1kp {
    font-size: var(--fontSizes-20px);
  }
  ._1qhetbf1kv {
    font-size: var(--fontSizes-40px);
  }
  ._1qhetbf1l1 {
    font-size: inherit;
  }
  ._1qhetbf1l7 {
    flex: 1 1;
  }
  ._1qhetbf1ld {
    flex: 1 1 auto;
  }
  ._1qhetbf1lj {
    flex: 0 1 auto;
  }
  ._1qhetbf1lp {
    flex: none;
  }
  ._1qhetbf1lv {
    box-shadow: var(--shadows-level1);
  }
  ._1qhetbf1m1 {
    box-shadow: var(--shadows-active);
  }
  ._1qhetbf1m7 {
    box-shadow: var(--shadows-success);
  }
  ._1qhetbf1md {
    box-shadow: var(--shadows-warning);
  }
  ._1qhetbf1mj {
    box-shadow: var(--shadows-danger);
  }
  ._1qhetbf1mp {
    box-shadow: var(--shadows-focus);
  }
  ._1qhetbf1mv {
    box-shadow: var(--shadows-inset);
  }
  ._1qhetbf1n1 {
    box-shadow: var(--shadows-tooltip);
  }
  ._1qhetbf1n7 {
    width: var(--space-0px);
  }
  ._1qhetbf1nd {
    width: var(--space-1rem);
  }
  ._1qhetbf1nj {
    width: var(--space-1px);
  }
  ._1qhetbf1np {
    width: var(--space-2px);
  }
  ._1qhetbf1nv {
    width: var(--space-6px);
  }
  ._1qhetbf1o1 {
    width: var(--space-4px);
  }
  ._1qhetbf1o7 {
    width: var(--space-8px);
  }
  ._1qhetbf1od {
    width: var(--space-12px);
  }
  ._1qhetbf1oj {
    width: var(--space-14px);
  }
  ._1qhetbf1op {
    width: var(--space-16px);
  }
  ._1qhetbf1ov {
    width: var(--space-20px);
  }
  ._1qhetbf1p1 {
    width: var(--space-24px);
  }
  ._1qhetbf1p7 {
    width: var(--space-32px);
  }
  ._1qhetbf1pd {
    width: var(--space-48px);
  }
  ._1qhetbf1pj {
    width: var(--space-56px);
  }
  ._1qhetbf1pp {
    width: var(--space-64px);
  }
  ._1qhetbf1pv {
    width: 100%;
  }
  ._1qhetbf1q1 {
    width: auto;
  }
  ._1qhetbf1q7 {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1qhetbf1qd {
    width: 420px;
  }
  ._1qhetbf1qj {
    width: 576px;
  }
  ._1qhetbf1qp {
    width: 852px;
  }
  ._1qhetbf1qv {
    width: 968px;
  }
  ._1qhetbf1r1 {
    width: 1080px;
  }
  ._1qhetbf1r7 {
    z-index: 0;
  }
  ._1qhetbf1rd {
    z-index: 1;
  }
  ._1qhetbf1rj {
    z-index: 10;
  }
  ._1qhetbf1rp {
    z-index: 20;
  }
  ._1qhetbf1rv {
    z-index: 30;
  }
  ._1qhetbf1s1 {
    z-index: 40;
  }
  ._1qhetbf1s7 {
    z-index: 50;
  }
  ._1qhetbf1sd {
    z-index: 75;
  }
  ._1qhetbf1sj {
    z-index: 99;
  }
  ._1qhetbf1sp {
    z-index: 100;
  }
  ._1qhetbf1sv {
    z-index: 9;
  }
  ._1qhetbf1t1 {
    z-index: 10;
  }
  ._1qhetbf1t7 {
    z-index: 100;
  }
  ._1qhetbf1td {
    z-index: auto;
  }
  ._1qhetbf1tj {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf1tp {
    border-radius: var(--radii-0);
  }
  ._1qhetbf1tv {
    border-radius: var(--radii-8px);
  }
  ._1qhetbf1u1 {
    border-radius: var(--radii-12px);
  }
  ._1qhetbf1u7 {
    border-radius: var(--radii-20px);
  }
  ._1qhetbf1ud {
    border-radius: var(--radii-32px);
  }
  ._1qhetbf1uj {
    border-radius: var(--radii-small);
  }
  ._1qhetbf1up {
    border-radius: var(--radii-default);
  }
  ._1qhetbf1uv {
    border-radius: var(--radii-card);
  }
  ._1qhetbf1v1 {
    border-radius: var(--radii-circle);
  }
  ._1qhetbf1v7 {
    border-top-left-radius: var(--radii-0);
  }
  ._1qhetbf1vd {
    border-top-left-radius: var(--radii-8px);
  }
  ._1qhetbf1vj {
    border-top-left-radius: var(--radii-12px);
  }
  ._1qhetbf1vp {
    border-top-left-radius: var(--radii-20px);
  }
  ._1qhetbf1vv {
    border-top-left-radius: var(--radii-32px);
  }
  ._1qhetbf1w1 {
    border-top-left-radius: var(--radii-small);
  }
  ._1qhetbf1w7 {
    border-top-left-radius: var(--radii-default);
  }
  ._1qhetbf1wd {
    border-top-left-radius: var(--radii-card);
  }
  ._1qhetbf1wj {
    border-top-left-radius: var(--radii-circle);
  }
  ._1qhetbf1wp {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1qhetbf1wv {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1qhetbf1x1 {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1qhetbf1x7 {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1qhetbf1xd {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1qhetbf1xj {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1qhetbf1xp {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1qhetbf1xv {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1qhetbf1y1 {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1qhetbf1y7 {
    border-top-right-radius: var(--radii-0);
  }
  ._1qhetbf1yd {
    border-top-right-radius: var(--radii-8px);
  }
  ._1qhetbf1yj {
    border-top-right-radius: var(--radii-12px);
  }
  ._1qhetbf1yp {
    border-top-right-radius: var(--radii-20px);
  }
  ._1qhetbf1yv {
    border-top-right-radius: var(--radii-32px);
  }
  ._1qhetbf1z1 {
    border-top-right-radius: var(--radii-small);
  }
  ._1qhetbf1z7 {
    border-top-right-radius: var(--radii-default);
  }
  ._1qhetbf1zd {
    border-top-right-radius: var(--radii-card);
  }
  ._1qhetbf1zj {
    border-top-right-radius: var(--radii-circle);
  }
  ._1qhetbf1zp {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1qhetbf1zv {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1qhetbf201 {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1qhetbf207 {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1qhetbf20d {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1qhetbf20j {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1qhetbf20p {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1qhetbf20v {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1qhetbf211 {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1qhetbf217 {
    gap: var(--space-0px);
  }
  ._1qhetbf21d {
    gap: var(--space-1rem);
  }
  ._1qhetbf21j {
    gap: var(--space-1px);
  }
  ._1qhetbf21p {
    gap: var(--space-2px);
  }
  ._1qhetbf21v {
    gap: var(--space-6px);
  }
  ._1qhetbf221 {
    gap: var(--space-4px);
  }
  ._1qhetbf227 {
    gap: var(--space-8px);
  }
  ._1qhetbf22d {
    gap: var(--space-12px);
  }
  ._1qhetbf22j {
    gap: var(--space-14px);
  }
  ._1qhetbf22p {
    gap: var(--space-16px);
  }
  ._1qhetbf22v {
    gap: var(--space-20px);
  }
  ._1qhetbf231 {
    gap: var(--space-24px);
  }
  ._1qhetbf237 {
    gap: var(--space-32px);
  }
  ._1qhetbf23d {
    gap: var(--space-48px);
  }
  ._1qhetbf23j {
    gap: var(--space-56px);
  }
  ._1qhetbf23p {
    gap: var(--space-64px);
  }
  ._1qhetbf23v {
    gap: var(--space-8px);
  }
  ._1qhetbf241 {
    gap: var(--space-12px);
  }
  ._1qhetbf247 {
    gap: var(--space-24px);
  }
  ._1qhetbf24d {
    row-gap: var(--space-0px);
  }
  ._1qhetbf24j {
    row-gap: var(--space-1rem);
  }
  ._1qhetbf24p {
    row-gap: var(--space-1px);
  }
  ._1qhetbf24v {
    row-gap: var(--space-2px);
  }
  ._1qhetbf251 {
    row-gap: var(--space-6px);
  }
  ._1qhetbf257 {
    row-gap: var(--space-4px);
  }
  ._1qhetbf25d {
    row-gap: var(--space-8px);
  }
  ._1qhetbf25j {
    row-gap: var(--space-12px);
  }
  ._1qhetbf25p {
    row-gap: var(--space-14px);
  }
  ._1qhetbf25v {
    row-gap: var(--space-16px);
  }
  ._1qhetbf261 {
    row-gap: var(--space-20px);
  }
  ._1qhetbf267 {
    row-gap: var(--space-24px);
  }
  ._1qhetbf26d {
    row-gap: var(--space-32px);
  }
  ._1qhetbf26j {
    row-gap: var(--space-48px);
  }
  ._1qhetbf26p {
    row-gap: var(--space-56px);
  }
  ._1qhetbf26v {
    row-gap: var(--space-64px);
  }
  ._1qhetbf271 {
    row-gap: var(--space-8px);
  }
  ._1qhetbf277 {
    row-gap: var(--space-12px);
  }
  ._1qhetbf27d {
    row-gap: var(--space-24px);
  }
  ._1qhetbf27j {
    column-gap: var(--space-0px);
  }
  ._1qhetbf27p {
    column-gap: var(--space-1rem);
  }
  ._1qhetbf27v {
    column-gap: var(--space-1px);
  }
  ._1qhetbf281 {
    column-gap: var(--space-2px);
  }
  ._1qhetbf287 {
    column-gap: var(--space-6px);
  }
  ._1qhetbf28d {
    column-gap: var(--space-4px);
  }
  ._1qhetbf28j {
    column-gap: var(--space-8px);
  }
  ._1qhetbf28p {
    column-gap: var(--space-12px);
  }
  ._1qhetbf28v {
    column-gap: var(--space-14px);
  }
  ._1qhetbf291 {
    column-gap: var(--space-16px);
  }
  ._1qhetbf297 {
    column-gap: var(--space-20px);
  }
  ._1qhetbf29d {
    column-gap: var(--space-24px);
  }
  ._1qhetbf29j {
    column-gap: var(--space-32px);
  }
  ._1qhetbf29p {
    column-gap: var(--space-48px);
  }
  ._1qhetbf29v {
    column-gap: var(--space-56px);
  }
  ._1qhetbf2a1 {
    column-gap: var(--space-64px);
  }
  ._1qhetbf2a7 {
    column-gap: var(--space-8px);
  }
  ._1qhetbf2ad {
    column-gap: var(--space-12px);
  }
  ._1qhetbf2aj {
    column-gap: var(--space-24px);
  }
  ._1qhetbf2ap {
    grid-auto-rows: auto;
  }
  ._1qhetbf2av {
    opacity: 0.5;
  }
  ._1qhetbf2b1 {
    opacity: 0.6;
  }
  ._1qhetbf2b7 {
    line-height: var(--space-16px);
  }
  ._1qhetbf2bd {
    white: var(--colors-light-white);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    background-alt3: var(--colors-light-backgroundAlt3);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    input-third: var(--colors-light-inputThird);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
    gradient-obsidian: var(--colors-light-gradientObsidian);
  }
  ._1qhetbf2bj {
    white: var(--colors-dark-white);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    background-alt3: var(--colors-dark-backgroundAlt3);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    input-third: var(--colors-dark-inputThird);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
    gradient-obsidian: var(--colors-dark-gradientObsidian);
  }
  ._1qhetbf2bp {
    border-bottom-color: var(--colors-white);
  }
  ._1qhetbf2bv {
    border-bottom-color: var(--colors-failure);
  }
  ._1qhetbf2c1 {
    border-bottom-color: var(--colors-failure33);
  }
  ._1qhetbf2c7 {
    border-bottom-color: var(--colors-primary);
  }
  ._1qhetbf2cd {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1qhetbf2cj {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1qhetbf2cp {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1qhetbf2cv {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1qhetbf2d1 {
    border-bottom-color: var(--colors-success);
  }
  ._1qhetbf2d7 {
    border-bottom-color: var(--colors-success19);
  }
  ._1qhetbf2dd {
    border-bottom-color: var(--colors-warning);
  }
  ._1qhetbf2dj {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1qhetbf2dp {
    border-bottom-color: var(--colors-warning33);
  }
  ._1qhetbf2dv {
    border-bottom-color: var(--colors-binance);
  }
  ._1qhetbf2e1 {
    border-bottom-color: var(--colors-overlay);
  }
  ._1qhetbf2e7 {
    border-bottom-color: var(--colors-gold);
  }
  ._1qhetbf2ed {
    border-bottom-color: var(--colors-silver);
  }
  ._1qhetbf2ej {
    border-bottom-color: var(--colors-bronze);
  }
  ._1qhetbf2ep {
    border-bottom-color: var(--colors-secondary);
  }
  ._1qhetbf2ev {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1qhetbf2f1 {
    border-bottom-color: var(--colors-background);
  }
  ._1qhetbf2f7 {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1qhetbf2fd {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1qhetbf2fj {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1qhetbf2fp {
    border-bottom-color: var(--colors-backgroundAlt3);
  }
  ._1qhetbf2fv {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1qhetbf2g1 {
    border-bottom-color: var(--colors-contrast);
  }
  ._1qhetbf2g7 {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1qhetbf2gd {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1qhetbf2gj {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1qhetbf2gp {
    border-bottom-color: var(--colors-input);
  }
  ._1qhetbf2gv {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1qhetbf2h1 {
    border-bottom-color: var(--colors-inputThird);
  }
  ._1qhetbf2h7 {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1qhetbf2hd {
    border-bottom-color: var(--colors-text);
  }
  ._1qhetbf2hj {
    border-bottom-color: var(--colors-text99);
  }
  ._1qhetbf2hp {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1qhetbf2hv {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1qhetbf2i1 {
    border-bottom-color: var(--colors-disabled);
  }
  ._1qhetbf2i7 {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1qhetbf2id {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1qhetbf2ij {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1qhetbf2ip {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1qhetbf2iv {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1qhetbf2j1 {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1qhetbf2j7 {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1qhetbf2jd {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1qhetbf2jj {
    border-bottom-color: var(--colors-gradientObsidian);
  }
  ._1qhetbf2jp {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf2jv {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 852px) {
  ._1qhetbf2 {
    display: block;
  }
  ._1qhetbf8 {
    display: flex;
  }
  ._1qhetbfe {
    display: grid;
  }
  ._1qhetbfk {
    display: inline;
  }
  ._1qhetbfq {
    display: inline-flex;
  }
  ._1qhetbfw {
    display: inline-block;
  }
  ._1qhetbf12 {
    display: none;
  }
  ._1qhetbf18 {
    flex-direction: column;
  }
  ._1qhetbf1e {
    flex-direction: row;
  }
  ._1qhetbf1k {
    flex-direction: column-reverse;
  }
  ._1qhetbf1q {
    align-items: center;
  }
  ._1qhetbf1w {
    align-items: end;
  }
  ._1qhetbf22 {
    align-items: baseLine;
  }
  ._1qhetbf28 {
    align-items: inherit;
  }
  ._1qhetbf2e {
    align-items: flex-start;
  }
  ._1qhetbf2k {
    align-items: center;
  }
  ._1qhetbf2q {
    align-items: start;
  }
  ._1qhetbf2w {
    align-items: flex-end;
  }
  ._1qhetbf32 {
    align-items: stretch;
  }
  ._1qhetbf38 {
    align-self: flex-start;
  }
  ._1qhetbf3e {
    align-self: center;
  }
  ._1qhetbf3k {
    align-self: start;
  }
  ._1qhetbf3q {
    align-self: flex-end;
  }
  ._1qhetbf3w {
    align-self: stretch;
  }
  ._1qhetbf42 {
    flex-wrap: wrap;
  }
  ._1qhetbf48 {
    flex-wrap: nowrap;
  }
  ._1qhetbf4e {
    flex-grow: 1;
  }
  ._1qhetbf4k {
    overflow: auto;
  }
  ._1qhetbf4q {
    overflow: hidden;
  }
  ._1qhetbf4w {
    overflow: scroll;
  }
  ._1qhetbf52 {
    overflow: unset;
  }
  ._1qhetbf58 {
    overflow-y: auto;
  }
  ._1qhetbf5e {
    overflow-y: hidden;
  }
  ._1qhetbf5k {
    overflow-y: scroll;
  }
  ._1qhetbf5q {
    overflow-x: auto;
  }
  ._1qhetbf5w {
    overflow-x: hidden;
  }
  ._1qhetbf62 {
    overflow-x: scroll;
  }
  ._1qhetbf68 {
    position: absolute;
  }
  ._1qhetbf6e {
    position: fixed;
  }
  ._1qhetbf6k {
    position: relative;
  }
  ._1qhetbf6q {
    position: sticky;
  }
  ._1qhetbf6w {
    text-align: center;
  }
  ._1qhetbf72 {
    text-align: left;
  }
  ._1qhetbf78 {
    text-align: right;
  }
  ._1qhetbf7e {
    justify-content: flex-start;
  }
  ._1qhetbf7k {
    justify-content: center;
  }
  ._1qhetbf7q {
    justify-content: start;
  }
  ._1qhetbf7w {
    justify-content: flex-end;
  }
  ._1qhetbf82 {
    justify-content: stretch;
  }
  ._1qhetbf88 {
    justify-content: space-around;
  }
  ._1qhetbf8e {
    justify-content: space-between;
  }
  ._1qhetbf8k {
    justify-items: flex-start;
  }
  ._1qhetbf8q {
    justify-items: center;
  }
  ._1qhetbf8w {
    justify-items: start;
  }
  ._1qhetbf92 {
    justify-items: flex-end;
  }
  ._1qhetbf98 {
    justify-items: stretch;
  }
  ._1qhetbf9e {
    justify-items: space-around;
  }
  ._1qhetbf9k {
    justify-items: space-between;
  }
  ._1qhetbf9q {
    justify-self: flex-start;
  }
  ._1qhetbf9w {
    justify-self: center;
  }
  ._1qhetbfa2 {
    justify-self: start;
  }
  ._1qhetbfa8 {
    justify-self: flex-end;
  }
  ._1qhetbfae {
    justify-self: stretch;
  }
  ._1qhetbfak {
    inset: 0px;
  }
  ._1qhetbfaq {
    height: var(--space-0px);
  }
  ._1qhetbfaw {
    height: var(--space-1rem);
  }
  ._1qhetbfb2 {
    height: var(--space-1px);
  }
  ._1qhetbfb8 {
    height: var(--space-2px);
  }
  ._1qhetbfbe {
    height: var(--space-6px);
  }
  ._1qhetbfbk {
    height: var(--space-4px);
  }
  ._1qhetbfbq {
    height: var(--space-8px);
  }
  ._1qhetbfbw {
    height: var(--space-12px);
  }
  ._1qhetbfc2 {
    height: var(--space-14px);
  }
  ._1qhetbfc8 {
    height: var(--space-16px);
  }
  ._1qhetbfce {
    height: var(--space-20px);
  }
  ._1qhetbfck {
    height: var(--space-24px);
  }
  ._1qhetbfcq {
    height: var(--space-32px);
  }
  ._1qhetbfcw {
    height: var(--space-48px);
  }
  ._1qhetbfd2 {
    height: var(--space-56px);
  }
  ._1qhetbfd8 {
    height: var(--space-64px);
  }
  ._1qhetbfde {
    height: 100%;
  }
  ._1qhetbfdk {
    height: auto;
  }
  ._1qhetbfdq {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1qhetbfdw {
    height: 420px;
  }
  ._1qhetbfe2 {
    height: 576px;
  }
  ._1qhetbfe8 {
    height: 852px;
  }
  ._1qhetbfee {
    height: 968px;
  }
  ._1qhetbfek {
    height: 1080px;
  }
  ._1qhetbfeq {
    height: 100vh;
  }
  ._1qhetbfew {
    margin-bottom: var(--space-0px);
  }
  ._1qhetbff2 {
    margin-bottom: var(--space-1rem);
  }
  ._1qhetbff8 {
    margin-bottom: var(--space-1px);
  }
  ._1qhetbffe {
    margin-bottom: var(--space-2px);
  }
  ._1qhetbffk {
    margin-bottom: var(--space-6px);
  }
  ._1qhetbffq {
    margin-bottom: var(--space-4px);
  }
  ._1qhetbffw {
    margin-bottom: var(--space-8px);
  }
  ._1qhetbfg2 {
    margin-bottom: var(--space-12px);
  }
  ._1qhetbfg8 {
    margin-bottom: var(--space-14px);
  }
  ._1qhetbfge {
    margin-bottom: var(--space-16px);
  }
  ._1qhetbfgk {
    margin-bottom: var(--space-20px);
  }
  ._1qhetbfgq {
    margin-bottom: var(--space-24px);
  }
  ._1qhetbfgw {
    margin-bottom: var(--space-32px);
  }
  ._1qhetbfh2 {
    margin-bottom: var(--space-48px);
  }
  ._1qhetbfh8 {
    margin-bottom: var(--space-56px);
  }
  ._1qhetbfhe {
    margin-bottom: var(--space-64px);
  }
  ._1qhetbfhk {
    margin-bottom: auto;
  }
  ._1qhetbfhq {
    margin-left: var(--space-0px);
  }
  ._1qhetbfhw {
    margin-left: var(--space-1rem);
  }
  ._1qhetbfi2 {
    margin-left: var(--space-1px);
  }
  ._1qhetbfi8 {
    margin-left: var(--space-2px);
  }
  ._1qhetbfie {
    margin-left: var(--space-6px);
  }
  ._1qhetbfik {
    margin-left: var(--space-4px);
  }
  ._1qhetbfiq {
    margin-left: var(--space-8px);
  }
  ._1qhetbfiw {
    margin-left: var(--space-12px);
  }
  ._1qhetbfj2 {
    margin-left: var(--space-14px);
  }
  ._1qhetbfj8 {
    margin-left: var(--space-16px);
  }
  ._1qhetbfje {
    margin-left: var(--space-20px);
  }
  ._1qhetbfjk {
    margin-left: var(--space-24px);
  }
  ._1qhetbfjq {
    margin-left: var(--space-32px);
  }
  ._1qhetbfjw {
    margin-left: var(--space-48px);
  }
  ._1qhetbfk2 {
    margin-left: var(--space-56px);
  }
  ._1qhetbfk8 {
    margin-left: var(--space-64px);
  }
  ._1qhetbfke {
    margin-left: auto;
  }
  ._1qhetbfkk {
    margin-right: var(--space-0px);
  }
  ._1qhetbfkq {
    margin-right: var(--space-1rem);
  }
  ._1qhetbfkw {
    margin-right: var(--space-1px);
  }
  ._1qhetbfl2 {
    margin-right: var(--space-2px);
  }
  ._1qhetbfl8 {
    margin-right: var(--space-6px);
  }
  ._1qhetbfle {
    margin-right: var(--space-4px);
  }
  ._1qhetbflk {
    margin-right: var(--space-8px);
  }
  ._1qhetbflq {
    margin-right: var(--space-12px);
  }
  ._1qhetbflw {
    margin-right: var(--space-14px);
  }
  ._1qhetbfm2 {
    margin-right: var(--space-16px);
  }
  ._1qhetbfm8 {
    margin-right: var(--space-20px);
  }
  ._1qhetbfme {
    margin-right: var(--space-24px);
  }
  ._1qhetbfmk {
    margin-right: var(--space-32px);
  }
  ._1qhetbfmq {
    margin-right: var(--space-48px);
  }
  ._1qhetbfmw {
    margin-right: var(--space-56px);
  }
  ._1qhetbfn2 {
    margin-right: var(--space-64px);
  }
  ._1qhetbfn8 {
    margin-right: auto;
  }
  ._1qhetbfne {
    margin-top: var(--space-0px);
  }
  ._1qhetbfnk {
    margin-top: var(--space-1rem);
  }
  ._1qhetbfnq {
    margin-top: var(--space-1px);
  }
  ._1qhetbfnw {
    margin-top: var(--space-2px);
  }
  ._1qhetbfo2 {
    margin-top: var(--space-6px);
  }
  ._1qhetbfo8 {
    margin-top: var(--space-4px);
  }
  ._1qhetbfoe {
    margin-top: var(--space-8px);
  }
  ._1qhetbfok {
    margin-top: var(--space-12px);
  }
  ._1qhetbfoq {
    margin-top: var(--space-14px);
  }
  ._1qhetbfow {
    margin-top: var(--space-16px);
  }
  ._1qhetbfp2 {
    margin-top: var(--space-20px);
  }
  ._1qhetbfp8 {
    margin-top: var(--space-24px);
  }
  ._1qhetbfpe {
    margin-top: var(--space-32px);
  }
  ._1qhetbfpk {
    margin-top: var(--space-48px);
  }
  ._1qhetbfpq {
    margin-top: var(--space-56px);
  }
  ._1qhetbfpw {
    margin-top: var(--space-64px);
  }
  ._1qhetbfq2 {
    margin-top: auto;
  }
  ._1qhetbfq8 {
    margin: var(--space-0px);
  }
  ._1qhetbfqe {
    margin: var(--space-1rem);
  }
  ._1qhetbfqk {
    margin: var(--space-1px);
  }
  ._1qhetbfqq {
    margin: var(--space-2px);
  }
  ._1qhetbfqw {
    margin: var(--space-6px);
  }
  ._1qhetbfr2 {
    margin: var(--space-4px);
  }
  ._1qhetbfr8 {
    margin: var(--space-8px);
  }
  ._1qhetbfre {
    margin: var(--space-12px);
  }
  ._1qhetbfrk {
    margin: var(--space-14px);
  }
  ._1qhetbfrq {
    margin: var(--space-16px);
  }
  ._1qhetbfrw {
    margin: var(--space-20px);
  }
  ._1qhetbfs2 {
    margin: var(--space-24px);
  }
  ._1qhetbfs8 {
    margin: var(--space-32px);
  }
  ._1qhetbfse {
    margin: var(--space-48px);
  }
  ._1qhetbfsk {
    margin: var(--space-56px);
  }
  ._1qhetbfsq {
    margin: var(--space-64px);
  }
  ._1qhetbfsw {
    margin: auto;
  }
  ._1qhetbft2 {
    padding: var(--space-0px);
  }
  ._1qhetbft8 {
    padding: var(--space-1rem);
  }
  ._1qhetbfte {
    padding: var(--space-1px);
  }
  ._1qhetbftk {
    padding: var(--space-2px);
  }
  ._1qhetbftq {
    padding: var(--space-6px);
  }
  ._1qhetbftw {
    padding: var(--space-4px);
  }
  ._1qhetbfu2 {
    padding: var(--space-8px);
  }
  ._1qhetbfu8 {
    padding: var(--space-12px);
  }
  ._1qhetbfue {
    padding: var(--space-14px);
  }
  ._1qhetbfuk {
    padding: var(--space-16px);
  }
  ._1qhetbfuq {
    padding: var(--space-20px);
  }
  ._1qhetbfuw {
    padding: var(--space-24px);
  }
  ._1qhetbfv2 {
    padding: var(--space-32px);
  }
  ._1qhetbfv8 {
    padding: var(--space-48px);
  }
  ._1qhetbfve {
    padding: var(--space-56px);
  }
  ._1qhetbfvk {
    padding: var(--space-64px);
  }
  ._1qhetbfvq {
    max-height: var(--space-0px);
  }
  ._1qhetbfvw {
    max-height: var(--space-1rem);
  }
  ._1qhetbfw2 {
    max-height: var(--space-1px);
  }
  ._1qhetbfw8 {
    max-height: var(--space-2px);
  }
  ._1qhetbfwe {
    max-height: var(--space-6px);
  }
  ._1qhetbfwk {
    max-height: var(--space-4px);
  }
  ._1qhetbfwq {
    max-height: var(--space-8px);
  }
  ._1qhetbfww {
    max-height: var(--space-12px);
  }
  ._1qhetbfx2 {
    max-height: var(--space-14px);
  }
  ._1qhetbfx8 {
    max-height: var(--space-16px);
  }
  ._1qhetbfxe {
    max-height: var(--space-20px);
  }
  ._1qhetbfxk {
    max-height: var(--space-24px);
  }
  ._1qhetbfxq {
    max-height: var(--space-32px);
  }
  ._1qhetbfxw {
    max-height: var(--space-48px);
  }
  ._1qhetbfy2 {
    max-height: var(--space-56px);
  }
  ._1qhetbfy8 {
    max-height: var(--space-64px);
  }
  ._1qhetbfye {
    max-width: var(--space-0px);
  }
  ._1qhetbfyk {
    max-width: var(--space-1rem);
  }
  ._1qhetbfyq {
    max-width: var(--space-1px);
  }
  ._1qhetbfyw {
    max-width: var(--space-2px);
  }
  ._1qhetbfz2 {
    max-width: var(--space-6px);
  }
  ._1qhetbfz8 {
    max-width: var(--space-4px);
  }
  ._1qhetbfze {
    max-width: var(--space-8px);
  }
  ._1qhetbfzk {
    max-width: var(--space-12px);
  }
  ._1qhetbfzq {
    max-width: var(--space-14px);
  }
  ._1qhetbfzw {
    max-width: var(--space-16px);
  }
  ._1qhetbf102 {
    max-width: var(--space-20px);
  }
  ._1qhetbf108 {
    max-width: var(--space-24px);
  }
  ._1qhetbf10e {
    max-width: var(--space-32px);
  }
  ._1qhetbf10k {
    max-width: var(--space-48px);
  }
  ._1qhetbf10q {
    max-width: var(--space-56px);
  }
  ._1qhetbf10w {
    max-width: var(--space-64px);
  }
  ._1qhetbf112 {
    max-width: 100%;
  }
  ._1qhetbf118 {
    max-width: auto;
  }
  ._1qhetbf11e {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1qhetbf11k {
    max-width: 420px;
  }
  ._1qhetbf11q {
    max-width: 576px;
  }
  ._1qhetbf11w {
    max-width: 852px;
  }
  ._1qhetbf122 {
    max-width: 968px;
  }
  ._1qhetbf128 {
    max-width: 1080px;
  }
  ._1qhetbf12e {
    max-width: none;
  }
  ._1qhetbf12k {
    min-height: var(--space-0px);
  }
  ._1qhetbf12q {
    min-height: var(--space-1rem);
  }
  ._1qhetbf12w {
    min-height: var(--space-1px);
  }
  ._1qhetbf132 {
    min-height: var(--space-2px);
  }
  ._1qhetbf138 {
    min-height: var(--space-6px);
  }
  ._1qhetbf13e {
    min-height: var(--space-4px);
  }
  ._1qhetbf13k {
    min-height: var(--space-8px);
  }
  ._1qhetbf13q {
    min-height: var(--space-12px);
  }
  ._1qhetbf13w {
    min-height: var(--space-14px);
  }
  ._1qhetbf142 {
    min-height: var(--space-16px);
  }
  ._1qhetbf148 {
    min-height: var(--space-20px);
  }
  ._1qhetbf14e {
    min-height: var(--space-24px);
  }
  ._1qhetbf14k {
    min-height: var(--space-32px);
  }
  ._1qhetbf14q {
    min-height: var(--space-48px);
  }
  ._1qhetbf14w {
    min-height: var(--space-56px);
  }
  ._1qhetbf152 {
    min-height: var(--space-64px);
  }
  ._1qhetbf158 {
    min-height: 100%;
  }
  ._1qhetbf15e {
    min-height: auto;
  }
  ._1qhetbf15k {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1qhetbf15q {
    min-height: 420px;
  }
  ._1qhetbf15w {
    min-height: 576px;
  }
  ._1qhetbf162 {
    min-height: 852px;
  }
  ._1qhetbf168 {
    min-height: 968px;
  }
  ._1qhetbf16e {
    min-height: 1080px;
  }
  ._1qhetbf16k {
    min-height: 100vh;
  }
  ._1qhetbf16q {
    min-width: var(--space-0px);
  }
  ._1qhetbf16w {
    min-width: var(--space-1rem);
  }
  ._1qhetbf172 {
    min-width: var(--space-1px);
  }
  ._1qhetbf178 {
    min-width: var(--space-2px);
  }
  ._1qhetbf17e {
    min-width: var(--space-6px);
  }
  ._1qhetbf17k {
    min-width: var(--space-4px);
  }
  ._1qhetbf17q {
    min-width: var(--space-8px);
  }
  ._1qhetbf17w {
    min-width: var(--space-12px);
  }
  ._1qhetbf182 {
    min-width: var(--space-14px);
  }
  ._1qhetbf188 {
    min-width: var(--space-16px);
  }
  ._1qhetbf18e {
    min-width: var(--space-20px);
  }
  ._1qhetbf18k {
    min-width: var(--space-24px);
  }
  ._1qhetbf18q {
    min-width: var(--space-32px);
  }
  ._1qhetbf18w {
    min-width: var(--space-48px);
  }
  ._1qhetbf192 {
    min-width: var(--space-56px);
  }
  ._1qhetbf198 {
    min-width: var(--space-64px);
  }
  ._1qhetbf19e {
    padding-bottom: var(--space-0px);
  }
  ._1qhetbf19k {
    padding-bottom: var(--space-1rem);
  }
  ._1qhetbf19q {
    padding-bottom: var(--space-1px);
  }
  ._1qhetbf19w {
    padding-bottom: var(--space-2px);
  }
  ._1qhetbf1a2 {
    padding-bottom: var(--space-6px);
  }
  ._1qhetbf1a8 {
    padding-bottom: var(--space-4px);
  }
  ._1qhetbf1ae {
    padding-bottom: var(--space-8px);
  }
  ._1qhetbf1ak {
    padding-bottom: var(--space-12px);
  }
  ._1qhetbf1aq {
    padding-bottom: var(--space-14px);
  }
  ._1qhetbf1aw {
    padding-bottom: var(--space-16px);
  }
  ._1qhetbf1b2 {
    padding-bottom: var(--space-20px);
  }
  ._1qhetbf1b8 {
    padding-bottom: var(--space-24px);
  }
  ._1qhetbf1be {
    padding-bottom: var(--space-32px);
  }
  ._1qhetbf1bk {
    padding-bottom: var(--space-48px);
  }
  ._1qhetbf1bq {
    padding-bottom: var(--space-56px);
  }
  ._1qhetbf1bw {
    padding-bottom: var(--space-64px);
  }
  ._1qhetbf1c2 {
    padding-left: var(--space-0px);
  }
  ._1qhetbf1c8 {
    padding-left: var(--space-1rem);
  }
  ._1qhetbf1ce {
    padding-left: var(--space-1px);
  }
  ._1qhetbf1ck {
    padding-left: var(--space-2px);
  }
  ._1qhetbf1cq {
    padding-left: var(--space-6px);
  }
  ._1qhetbf1cw {
    padding-left: var(--space-4px);
  }
  ._1qhetbf1d2 {
    padding-left: var(--space-8px);
  }
  ._1qhetbf1d8 {
    padding-left: var(--space-12px);
  }
  ._1qhetbf1de {
    padding-left: var(--space-14px);
  }
  ._1qhetbf1dk {
    padding-left: var(--space-16px);
  }
  ._1qhetbf1dq {
    padding-left: var(--space-20px);
  }
  ._1qhetbf1dw {
    padding-left: var(--space-24px);
  }
  ._1qhetbf1e2 {
    padding-left: var(--space-32px);
  }
  ._1qhetbf1e8 {
    padding-left: var(--space-48px);
  }
  ._1qhetbf1ee {
    padding-left: var(--space-56px);
  }
  ._1qhetbf1ek {
    padding-left: var(--space-64px);
  }
  ._1qhetbf1eq {
    padding-right: var(--space-0px);
  }
  ._1qhetbf1ew {
    padding-right: var(--space-1rem);
  }
  ._1qhetbf1f2 {
    padding-right: var(--space-1px);
  }
  ._1qhetbf1f8 {
    padding-right: var(--space-2px);
  }
  ._1qhetbf1fe {
    padding-right: var(--space-6px);
  }
  ._1qhetbf1fk {
    padding-right: var(--space-4px);
  }
  ._1qhetbf1fq {
    padding-right: var(--space-8px);
  }
  ._1qhetbf1fw {
    padding-right: var(--space-12px);
  }
  ._1qhetbf1g2 {
    padding-right: var(--space-14px);
  }
  ._1qhetbf1g8 {
    padding-right: var(--space-16px);
  }
  ._1qhetbf1ge {
    padding-right: var(--space-20px);
  }
  ._1qhetbf1gk {
    padding-right: var(--space-24px);
  }
  ._1qhetbf1gq {
    padding-right: var(--space-32px);
  }
  ._1qhetbf1gw {
    padding-right: var(--space-48px);
  }
  ._1qhetbf1h2 {
    padding-right: var(--space-56px);
  }
  ._1qhetbf1h8 {
    padding-right: var(--space-64px);
  }
  ._1qhetbf1he {
    padding-top: var(--space-0px);
  }
  ._1qhetbf1hk {
    padding-top: var(--space-1rem);
  }
  ._1qhetbf1hq {
    padding-top: var(--space-1px);
  }
  ._1qhetbf1hw {
    padding-top: var(--space-2px);
  }
  ._1qhetbf1i2 {
    padding-top: var(--space-6px);
  }
  ._1qhetbf1i8 {
    padding-top: var(--space-4px);
  }
  ._1qhetbf1ie {
    padding-top: var(--space-8px);
  }
  ._1qhetbf1ik {
    padding-top: var(--space-12px);
  }
  ._1qhetbf1iq {
    padding-top: var(--space-14px);
  }
  ._1qhetbf1iw {
    padding-top: var(--space-16px);
  }
  ._1qhetbf1j2 {
    padding-top: var(--space-20px);
  }
  ._1qhetbf1j8 {
    padding-top: var(--space-24px);
  }
  ._1qhetbf1je {
    padding-top: var(--space-32px);
  }
  ._1qhetbf1jk {
    padding-top: var(--space-48px);
  }
  ._1qhetbf1jq {
    padding-top: var(--space-56px);
  }
  ._1qhetbf1jw {
    padding-top: var(--space-64px);
  }
  ._1qhetbf1k2 {
    font-size: var(--fontSizes-10px);
  }
  ._1qhetbf1k8 {
    font-size: var(--fontSizes-12px);
  }
  ._1qhetbf1ke {
    font-size: var(--fontSizes-16px);
  }
  ._1qhetbf1kk {
    font-size: var(--fontSizes-14px);
  }
  ._1qhetbf1kq {
    font-size: var(--fontSizes-20px);
  }
  ._1qhetbf1kw {
    font-size: var(--fontSizes-40px);
  }
  ._1qhetbf1l2 {
    font-size: inherit;
  }
  ._1qhetbf1l8 {
    flex: 1 1;
  }
  ._1qhetbf1le {
    flex: 1 1 auto;
  }
  ._1qhetbf1lk {
    flex: 0 1 auto;
  }
  ._1qhetbf1lq {
    flex: none;
  }
  ._1qhetbf1lw {
    box-shadow: var(--shadows-level1);
  }
  ._1qhetbf1m2 {
    box-shadow: var(--shadows-active);
  }
  ._1qhetbf1m8 {
    box-shadow: var(--shadows-success);
  }
  ._1qhetbf1me {
    box-shadow: var(--shadows-warning);
  }
  ._1qhetbf1mk {
    box-shadow: var(--shadows-danger);
  }
  ._1qhetbf1mq {
    box-shadow: var(--shadows-focus);
  }
  ._1qhetbf1mw {
    box-shadow: var(--shadows-inset);
  }
  ._1qhetbf1n2 {
    box-shadow: var(--shadows-tooltip);
  }
  ._1qhetbf1n8 {
    width: var(--space-0px);
  }
  ._1qhetbf1ne {
    width: var(--space-1rem);
  }
  ._1qhetbf1nk {
    width: var(--space-1px);
  }
  ._1qhetbf1nq {
    width: var(--space-2px);
  }
  ._1qhetbf1nw {
    width: var(--space-6px);
  }
  ._1qhetbf1o2 {
    width: var(--space-4px);
  }
  ._1qhetbf1o8 {
    width: var(--space-8px);
  }
  ._1qhetbf1oe {
    width: var(--space-12px);
  }
  ._1qhetbf1ok {
    width: var(--space-14px);
  }
  ._1qhetbf1oq {
    width: var(--space-16px);
  }
  ._1qhetbf1ow {
    width: var(--space-20px);
  }
  ._1qhetbf1p2 {
    width: var(--space-24px);
  }
  ._1qhetbf1p8 {
    width: var(--space-32px);
  }
  ._1qhetbf1pe {
    width: var(--space-48px);
  }
  ._1qhetbf1pk {
    width: var(--space-56px);
  }
  ._1qhetbf1pq {
    width: var(--space-64px);
  }
  ._1qhetbf1pw {
    width: 100%;
  }
  ._1qhetbf1q2 {
    width: auto;
  }
  ._1qhetbf1q8 {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1qhetbf1qe {
    width: 420px;
  }
  ._1qhetbf1qk {
    width: 576px;
  }
  ._1qhetbf1qq {
    width: 852px;
  }
  ._1qhetbf1qw {
    width: 968px;
  }
  ._1qhetbf1r2 {
    width: 1080px;
  }
  ._1qhetbf1r8 {
    z-index: 0;
  }
  ._1qhetbf1re {
    z-index: 1;
  }
  ._1qhetbf1rk {
    z-index: 10;
  }
  ._1qhetbf1rq {
    z-index: 20;
  }
  ._1qhetbf1rw {
    z-index: 30;
  }
  ._1qhetbf1s2 {
    z-index: 40;
  }
  ._1qhetbf1s8 {
    z-index: 50;
  }
  ._1qhetbf1se {
    z-index: 75;
  }
  ._1qhetbf1sk {
    z-index: 99;
  }
  ._1qhetbf1sq {
    z-index: 100;
  }
  ._1qhetbf1sw {
    z-index: 9;
  }
  ._1qhetbf1t2 {
    z-index: 10;
  }
  ._1qhetbf1t8 {
    z-index: 100;
  }
  ._1qhetbf1te {
    z-index: auto;
  }
  ._1qhetbf1tk {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf1tq {
    border-radius: var(--radii-0);
  }
  ._1qhetbf1tw {
    border-radius: var(--radii-8px);
  }
  ._1qhetbf1u2 {
    border-radius: var(--radii-12px);
  }
  ._1qhetbf1u8 {
    border-radius: var(--radii-20px);
  }
  ._1qhetbf1ue {
    border-radius: var(--radii-32px);
  }
  ._1qhetbf1uk {
    border-radius: var(--radii-small);
  }
  ._1qhetbf1uq {
    border-radius: var(--radii-default);
  }
  ._1qhetbf1uw {
    border-radius: var(--radii-card);
  }
  ._1qhetbf1v2 {
    border-radius: var(--radii-circle);
  }
  ._1qhetbf1v8 {
    border-top-left-radius: var(--radii-0);
  }
  ._1qhetbf1ve {
    border-top-left-radius: var(--radii-8px);
  }
  ._1qhetbf1vk {
    border-top-left-radius: var(--radii-12px);
  }
  ._1qhetbf1vq {
    border-top-left-radius: var(--radii-20px);
  }
  ._1qhetbf1vw {
    border-top-left-radius: var(--radii-32px);
  }
  ._1qhetbf1w2 {
    border-top-left-radius: var(--radii-small);
  }
  ._1qhetbf1w8 {
    border-top-left-radius: var(--radii-default);
  }
  ._1qhetbf1we {
    border-top-left-radius: var(--radii-card);
  }
  ._1qhetbf1wk {
    border-top-left-radius: var(--radii-circle);
  }
  ._1qhetbf1wq {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1qhetbf1ww {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1qhetbf1x2 {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1qhetbf1x8 {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1qhetbf1xe {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1qhetbf1xk {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1qhetbf1xq {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1qhetbf1xw {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1qhetbf1y2 {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1qhetbf1y8 {
    border-top-right-radius: var(--radii-0);
  }
  ._1qhetbf1ye {
    border-top-right-radius: var(--radii-8px);
  }
  ._1qhetbf1yk {
    border-top-right-radius: var(--radii-12px);
  }
  ._1qhetbf1yq {
    border-top-right-radius: var(--radii-20px);
  }
  ._1qhetbf1yw {
    border-top-right-radius: var(--radii-32px);
  }
  ._1qhetbf1z2 {
    border-top-right-radius: var(--radii-small);
  }
  ._1qhetbf1z8 {
    border-top-right-radius: var(--radii-default);
  }
  ._1qhetbf1ze {
    border-top-right-radius: var(--radii-card);
  }
  ._1qhetbf1zk {
    border-top-right-radius: var(--radii-circle);
  }
  ._1qhetbf1zq {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1qhetbf1zw {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1qhetbf202 {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1qhetbf208 {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1qhetbf20e {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1qhetbf20k {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1qhetbf20q {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1qhetbf20w {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1qhetbf212 {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1qhetbf218 {
    gap: var(--space-0px);
  }
  ._1qhetbf21e {
    gap: var(--space-1rem);
  }
  ._1qhetbf21k {
    gap: var(--space-1px);
  }
  ._1qhetbf21q {
    gap: var(--space-2px);
  }
  ._1qhetbf21w {
    gap: var(--space-6px);
  }
  ._1qhetbf222 {
    gap: var(--space-4px);
  }
  ._1qhetbf228 {
    gap: var(--space-8px);
  }
  ._1qhetbf22e {
    gap: var(--space-12px);
  }
  ._1qhetbf22k {
    gap: var(--space-14px);
  }
  ._1qhetbf22q {
    gap: var(--space-16px);
  }
  ._1qhetbf22w {
    gap: var(--space-20px);
  }
  ._1qhetbf232 {
    gap: var(--space-24px);
  }
  ._1qhetbf238 {
    gap: var(--space-32px);
  }
  ._1qhetbf23e {
    gap: var(--space-48px);
  }
  ._1qhetbf23k {
    gap: var(--space-56px);
  }
  ._1qhetbf23q {
    gap: var(--space-64px);
  }
  ._1qhetbf23w {
    gap: var(--space-8px);
  }
  ._1qhetbf242 {
    gap: var(--space-12px);
  }
  ._1qhetbf248 {
    gap: var(--space-24px);
  }
  ._1qhetbf24e {
    row-gap: var(--space-0px);
  }
  ._1qhetbf24k {
    row-gap: var(--space-1rem);
  }
  ._1qhetbf24q {
    row-gap: var(--space-1px);
  }
  ._1qhetbf24w {
    row-gap: var(--space-2px);
  }
  ._1qhetbf252 {
    row-gap: var(--space-6px);
  }
  ._1qhetbf258 {
    row-gap: var(--space-4px);
  }
  ._1qhetbf25e {
    row-gap: var(--space-8px);
  }
  ._1qhetbf25k {
    row-gap: var(--space-12px);
  }
  ._1qhetbf25q {
    row-gap: var(--space-14px);
  }
  ._1qhetbf25w {
    row-gap: var(--space-16px);
  }
  ._1qhetbf262 {
    row-gap: var(--space-20px);
  }
  ._1qhetbf268 {
    row-gap: var(--space-24px);
  }
  ._1qhetbf26e {
    row-gap: var(--space-32px);
  }
  ._1qhetbf26k {
    row-gap: var(--space-48px);
  }
  ._1qhetbf26q {
    row-gap: var(--space-56px);
  }
  ._1qhetbf26w {
    row-gap: var(--space-64px);
  }
  ._1qhetbf272 {
    row-gap: var(--space-8px);
  }
  ._1qhetbf278 {
    row-gap: var(--space-12px);
  }
  ._1qhetbf27e {
    row-gap: var(--space-24px);
  }
  ._1qhetbf27k {
    column-gap: var(--space-0px);
  }
  ._1qhetbf27q {
    column-gap: var(--space-1rem);
  }
  ._1qhetbf27w {
    column-gap: var(--space-1px);
  }
  ._1qhetbf282 {
    column-gap: var(--space-2px);
  }
  ._1qhetbf288 {
    column-gap: var(--space-6px);
  }
  ._1qhetbf28e {
    column-gap: var(--space-4px);
  }
  ._1qhetbf28k {
    column-gap: var(--space-8px);
  }
  ._1qhetbf28q {
    column-gap: var(--space-12px);
  }
  ._1qhetbf28w {
    column-gap: var(--space-14px);
  }
  ._1qhetbf292 {
    column-gap: var(--space-16px);
  }
  ._1qhetbf298 {
    column-gap: var(--space-20px);
  }
  ._1qhetbf29e {
    column-gap: var(--space-24px);
  }
  ._1qhetbf29k {
    column-gap: var(--space-32px);
  }
  ._1qhetbf29q {
    column-gap: var(--space-48px);
  }
  ._1qhetbf29w {
    column-gap: var(--space-56px);
  }
  ._1qhetbf2a2 {
    column-gap: var(--space-64px);
  }
  ._1qhetbf2a8 {
    column-gap: var(--space-8px);
  }
  ._1qhetbf2ae {
    column-gap: var(--space-12px);
  }
  ._1qhetbf2ak {
    column-gap: var(--space-24px);
  }
  ._1qhetbf2aq {
    grid-auto-rows: auto;
  }
  ._1qhetbf2aw {
    opacity: 0.5;
  }
  ._1qhetbf2b2 {
    opacity: 0.6;
  }
  ._1qhetbf2b8 {
    line-height: var(--space-16px);
  }
  ._1qhetbf2be {
    white: var(--colors-light-white);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    background-alt3: var(--colors-light-backgroundAlt3);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    input-third: var(--colors-light-inputThird);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
    gradient-obsidian: var(--colors-light-gradientObsidian);
  }
  ._1qhetbf2bk {
    white: var(--colors-dark-white);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    background-alt3: var(--colors-dark-backgroundAlt3);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    input-third: var(--colors-dark-inputThird);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
    gradient-obsidian: var(--colors-dark-gradientObsidian);
  }
  ._1qhetbf2bq {
    border-bottom-color: var(--colors-white);
  }
  ._1qhetbf2bw {
    border-bottom-color: var(--colors-failure);
  }
  ._1qhetbf2c2 {
    border-bottom-color: var(--colors-failure33);
  }
  ._1qhetbf2c8 {
    border-bottom-color: var(--colors-primary);
  }
  ._1qhetbf2ce {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1qhetbf2ck {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1qhetbf2cq {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1qhetbf2cw {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1qhetbf2d2 {
    border-bottom-color: var(--colors-success);
  }
  ._1qhetbf2d8 {
    border-bottom-color: var(--colors-success19);
  }
  ._1qhetbf2de {
    border-bottom-color: var(--colors-warning);
  }
  ._1qhetbf2dk {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1qhetbf2dq {
    border-bottom-color: var(--colors-warning33);
  }
  ._1qhetbf2dw {
    border-bottom-color: var(--colors-binance);
  }
  ._1qhetbf2e2 {
    border-bottom-color: var(--colors-overlay);
  }
  ._1qhetbf2e8 {
    border-bottom-color: var(--colors-gold);
  }
  ._1qhetbf2ee {
    border-bottom-color: var(--colors-silver);
  }
  ._1qhetbf2ek {
    border-bottom-color: var(--colors-bronze);
  }
  ._1qhetbf2eq {
    border-bottom-color: var(--colors-secondary);
  }
  ._1qhetbf2ew {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1qhetbf2f2 {
    border-bottom-color: var(--colors-background);
  }
  ._1qhetbf2f8 {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1qhetbf2fe {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1qhetbf2fk {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1qhetbf2fq {
    border-bottom-color: var(--colors-backgroundAlt3);
  }
  ._1qhetbf2fw {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1qhetbf2g2 {
    border-bottom-color: var(--colors-contrast);
  }
  ._1qhetbf2g8 {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1qhetbf2ge {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1qhetbf2gk {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1qhetbf2gq {
    border-bottom-color: var(--colors-input);
  }
  ._1qhetbf2gw {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1qhetbf2h2 {
    border-bottom-color: var(--colors-inputThird);
  }
  ._1qhetbf2h8 {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1qhetbf2he {
    border-bottom-color: var(--colors-text);
  }
  ._1qhetbf2hk {
    border-bottom-color: var(--colors-text99);
  }
  ._1qhetbf2hq {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1qhetbf2hw {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1qhetbf2i2 {
    border-bottom-color: var(--colors-disabled);
  }
  ._1qhetbf2i8 {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1qhetbf2ie {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1qhetbf2ik {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1qhetbf2iq {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1qhetbf2iw {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1qhetbf2j2 {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1qhetbf2j8 {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1qhetbf2je {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1qhetbf2jk {
    border-bottom-color: var(--colors-gradientObsidian);
  }
  ._1qhetbf2jq {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf2jw {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 968px) {
  ._1qhetbf3 {
    display: block;
  }
  ._1qhetbf9 {
    display: flex;
  }
  ._1qhetbff {
    display: grid;
  }
  ._1qhetbfl {
    display: inline;
  }
  ._1qhetbfr {
    display: inline-flex;
  }
  ._1qhetbfx {
    display: inline-block;
  }
  ._1qhetbf13 {
    display: none;
  }
  ._1qhetbf19 {
    flex-direction: column;
  }
  ._1qhetbf1f {
    flex-direction: row;
  }
  ._1qhetbf1l {
    flex-direction: column-reverse;
  }
  ._1qhetbf1r {
    align-items: center;
  }
  ._1qhetbf1x {
    align-items: end;
  }
  ._1qhetbf23 {
    align-items: baseLine;
  }
  ._1qhetbf29 {
    align-items: inherit;
  }
  ._1qhetbf2f {
    align-items: flex-start;
  }
  ._1qhetbf2l {
    align-items: center;
  }
  ._1qhetbf2r {
    align-items: start;
  }
  ._1qhetbf2x {
    align-items: flex-end;
  }
  ._1qhetbf33 {
    align-items: stretch;
  }
  ._1qhetbf39 {
    align-self: flex-start;
  }
  ._1qhetbf3f {
    align-self: center;
  }
  ._1qhetbf3l {
    align-self: start;
  }
  ._1qhetbf3r {
    align-self: flex-end;
  }
  ._1qhetbf3x {
    align-self: stretch;
  }
  ._1qhetbf43 {
    flex-wrap: wrap;
  }
  ._1qhetbf49 {
    flex-wrap: nowrap;
  }
  ._1qhetbf4f {
    flex-grow: 1;
  }
  ._1qhetbf4l {
    overflow: auto;
  }
  ._1qhetbf4r {
    overflow: hidden;
  }
  ._1qhetbf4x {
    overflow: scroll;
  }
  ._1qhetbf53 {
    overflow: unset;
  }
  ._1qhetbf59 {
    overflow-y: auto;
  }
  ._1qhetbf5f {
    overflow-y: hidden;
  }
  ._1qhetbf5l {
    overflow-y: scroll;
  }
  ._1qhetbf5r {
    overflow-x: auto;
  }
  ._1qhetbf5x {
    overflow-x: hidden;
  }
  ._1qhetbf63 {
    overflow-x: scroll;
  }
  ._1qhetbf69 {
    position: absolute;
  }
  ._1qhetbf6f {
    position: fixed;
  }
  ._1qhetbf6l {
    position: relative;
  }
  ._1qhetbf6r {
    position: sticky;
  }
  ._1qhetbf6x {
    text-align: center;
  }
  ._1qhetbf73 {
    text-align: left;
  }
  ._1qhetbf79 {
    text-align: right;
  }
  ._1qhetbf7f {
    justify-content: flex-start;
  }
  ._1qhetbf7l {
    justify-content: center;
  }
  ._1qhetbf7r {
    justify-content: start;
  }
  ._1qhetbf7x {
    justify-content: flex-end;
  }
  ._1qhetbf83 {
    justify-content: stretch;
  }
  ._1qhetbf89 {
    justify-content: space-around;
  }
  ._1qhetbf8f {
    justify-content: space-between;
  }
  ._1qhetbf8l {
    justify-items: flex-start;
  }
  ._1qhetbf8r {
    justify-items: center;
  }
  ._1qhetbf8x {
    justify-items: start;
  }
  ._1qhetbf93 {
    justify-items: flex-end;
  }
  ._1qhetbf99 {
    justify-items: stretch;
  }
  ._1qhetbf9f {
    justify-items: space-around;
  }
  ._1qhetbf9l {
    justify-items: space-between;
  }
  ._1qhetbf9r {
    justify-self: flex-start;
  }
  ._1qhetbf9x {
    justify-self: center;
  }
  ._1qhetbfa3 {
    justify-self: start;
  }
  ._1qhetbfa9 {
    justify-self: flex-end;
  }
  ._1qhetbfaf {
    justify-self: stretch;
  }
  ._1qhetbfal {
    inset: 0px;
  }
  ._1qhetbfar {
    height: var(--space-0px);
  }
  ._1qhetbfax {
    height: var(--space-1rem);
  }
  ._1qhetbfb3 {
    height: var(--space-1px);
  }
  ._1qhetbfb9 {
    height: var(--space-2px);
  }
  ._1qhetbfbf {
    height: var(--space-6px);
  }
  ._1qhetbfbl {
    height: var(--space-4px);
  }
  ._1qhetbfbr {
    height: var(--space-8px);
  }
  ._1qhetbfbx {
    height: var(--space-12px);
  }
  ._1qhetbfc3 {
    height: var(--space-14px);
  }
  ._1qhetbfc9 {
    height: var(--space-16px);
  }
  ._1qhetbfcf {
    height: var(--space-20px);
  }
  ._1qhetbfcl {
    height: var(--space-24px);
  }
  ._1qhetbfcr {
    height: var(--space-32px);
  }
  ._1qhetbfcx {
    height: var(--space-48px);
  }
  ._1qhetbfd3 {
    height: var(--space-56px);
  }
  ._1qhetbfd9 {
    height: var(--space-64px);
  }
  ._1qhetbfdf {
    height: 100%;
  }
  ._1qhetbfdl {
    height: auto;
  }
  ._1qhetbfdr {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1qhetbfdx {
    height: 420px;
  }
  ._1qhetbfe3 {
    height: 576px;
  }
  ._1qhetbfe9 {
    height: 852px;
  }
  ._1qhetbfef {
    height: 968px;
  }
  ._1qhetbfel {
    height: 1080px;
  }
  ._1qhetbfer {
    height: 100vh;
  }
  ._1qhetbfex {
    margin-bottom: var(--space-0px);
  }
  ._1qhetbff3 {
    margin-bottom: var(--space-1rem);
  }
  ._1qhetbff9 {
    margin-bottom: var(--space-1px);
  }
  ._1qhetbfff {
    margin-bottom: var(--space-2px);
  }
  ._1qhetbffl {
    margin-bottom: var(--space-6px);
  }
  ._1qhetbffr {
    margin-bottom: var(--space-4px);
  }
  ._1qhetbffx {
    margin-bottom: var(--space-8px);
  }
  ._1qhetbfg3 {
    margin-bottom: var(--space-12px);
  }
  ._1qhetbfg9 {
    margin-bottom: var(--space-14px);
  }
  ._1qhetbfgf {
    margin-bottom: var(--space-16px);
  }
  ._1qhetbfgl {
    margin-bottom: var(--space-20px);
  }
  ._1qhetbfgr {
    margin-bottom: var(--space-24px);
  }
  ._1qhetbfgx {
    margin-bottom: var(--space-32px);
  }
  ._1qhetbfh3 {
    margin-bottom: var(--space-48px);
  }
  ._1qhetbfh9 {
    margin-bottom: var(--space-56px);
  }
  ._1qhetbfhf {
    margin-bottom: var(--space-64px);
  }
  ._1qhetbfhl {
    margin-bottom: auto;
  }
  ._1qhetbfhr {
    margin-left: var(--space-0px);
  }
  ._1qhetbfhx {
    margin-left: var(--space-1rem);
  }
  ._1qhetbfi3 {
    margin-left: var(--space-1px);
  }
  ._1qhetbfi9 {
    margin-left: var(--space-2px);
  }
  ._1qhetbfif {
    margin-left: var(--space-6px);
  }
  ._1qhetbfil {
    margin-left: var(--space-4px);
  }
  ._1qhetbfir {
    margin-left: var(--space-8px);
  }
  ._1qhetbfix {
    margin-left: var(--space-12px);
  }
  ._1qhetbfj3 {
    margin-left: var(--space-14px);
  }
  ._1qhetbfj9 {
    margin-left: var(--space-16px);
  }
  ._1qhetbfjf {
    margin-left: var(--space-20px);
  }
  ._1qhetbfjl {
    margin-left: var(--space-24px);
  }
  ._1qhetbfjr {
    margin-left: var(--space-32px);
  }
  ._1qhetbfjx {
    margin-left: var(--space-48px);
  }
  ._1qhetbfk3 {
    margin-left: var(--space-56px);
  }
  ._1qhetbfk9 {
    margin-left: var(--space-64px);
  }
  ._1qhetbfkf {
    margin-left: auto;
  }
  ._1qhetbfkl {
    margin-right: var(--space-0px);
  }
  ._1qhetbfkr {
    margin-right: var(--space-1rem);
  }
  ._1qhetbfkx {
    margin-right: var(--space-1px);
  }
  ._1qhetbfl3 {
    margin-right: var(--space-2px);
  }
  ._1qhetbfl9 {
    margin-right: var(--space-6px);
  }
  ._1qhetbflf {
    margin-right: var(--space-4px);
  }
  ._1qhetbfll {
    margin-right: var(--space-8px);
  }
  ._1qhetbflr {
    margin-right: var(--space-12px);
  }
  ._1qhetbflx {
    margin-right: var(--space-14px);
  }
  ._1qhetbfm3 {
    margin-right: var(--space-16px);
  }
  ._1qhetbfm9 {
    margin-right: var(--space-20px);
  }
  ._1qhetbfmf {
    margin-right: var(--space-24px);
  }
  ._1qhetbfml {
    margin-right: var(--space-32px);
  }
  ._1qhetbfmr {
    margin-right: var(--space-48px);
  }
  ._1qhetbfmx {
    margin-right: var(--space-56px);
  }
  ._1qhetbfn3 {
    margin-right: var(--space-64px);
  }
  ._1qhetbfn9 {
    margin-right: auto;
  }
  ._1qhetbfnf {
    margin-top: var(--space-0px);
  }
  ._1qhetbfnl {
    margin-top: var(--space-1rem);
  }
  ._1qhetbfnr {
    margin-top: var(--space-1px);
  }
  ._1qhetbfnx {
    margin-top: var(--space-2px);
  }
  ._1qhetbfo3 {
    margin-top: var(--space-6px);
  }
  ._1qhetbfo9 {
    margin-top: var(--space-4px);
  }
  ._1qhetbfof {
    margin-top: var(--space-8px);
  }
  ._1qhetbfol {
    margin-top: var(--space-12px);
  }
  ._1qhetbfor {
    margin-top: var(--space-14px);
  }
  ._1qhetbfox {
    margin-top: var(--space-16px);
  }
  ._1qhetbfp3 {
    margin-top: var(--space-20px);
  }
  ._1qhetbfp9 {
    margin-top: var(--space-24px);
  }
  ._1qhetbfpf {
    margin-top: var(--space-32px);
  }
  ._1qhetbfpl {
    margin-top: var(--space-48px);
  }
  ._1qhetbfpr {
    margin-top: var(--space-56px);
  }
  ._1qhetbfpx {
    margin-top: var(--space-64px);
  }
  ._1qhetbfq3 {
    margin-top: auto;
  }
  ._1qhetbfq9 {
    margin: var(--space-0px);
  }
  ._1qhetbfqf {
    margin: var(--space-1rem);
  }
  ._1qhetbfql {
    margin: var(--space-1px);
  }
  ._1qhetbfqr {
    margin: var(--space-2px);
  }
  ._1qhetbfqx {
    margin: var(--space-6px);
  }
  ._1qhetbfr3 {
    margin: var(--space-4px);
  }
  ._1qhetbfr9 {
    margin: var(--space-8px);
  }
  ._1qhetbfrf {
    margin: var(--space-12px);
  }
  ._1qhetbfrl {
    margin: var(--space-14px);
  }
  ._1qhetbfrr {
    margin: var(--space-16px);
  }
  ._1qhetbfrx {
    margin: var(--space-20px);
  }
  ._1qhetbfs3 {
    margin: var(--space-24px);
  }
  ._1qhetbfs9 {
    margin: var(--space-32px);
  }
  ._1qhetbfsf {
    margin: var(--space-48px);
  }
  ._1qhetbfsl {
    margin: var(--space-56px);
  }
  ._1qhetbfsr {
    margin: var(--space-64px);
  }
  ._1qhetbfsx {
    margin: auto;
  }
  ._1qhetbft3 {
    padding: var(--space-0px);
  }
  ._1qhetbft9 {
    padding: var(--space-1rem);
  }
  ._1qhetbftf {
    padding: var(--space-1px);
  }
  ._1qhetbftl {
    padding: var(--space-2px);
  }
  ._1qhetbftr {
    padding: var(--space-6px);
  }
  ._1qhetbftx {
    padding: var(--space-4px);
  }
  ._1qhetbfu3 {
    padding: var(--space-8px);
  }
  ._1qhetbfu9 {
    padding: var(--space-12px);
  }
  ._1qhetbfuf {
    padding: var(--space-14px);
  }
  ._1qhetbful {
    padding: var(--space-16px);
  }
  ._1qhetbfur {
    padding: var(--space-20px);
  }
  ._1qhetbfux {
    padding: var(--space-24px);
  }
  ._1qhetbfv3 {
    padding: var(--space-32px);
  }
  ._1qhetbfv9 {
    padding: var(--space-48px);
  }
  ._1qhetbfvf {
    padding: var(--space-56px);
  }
  ._1qhetbfvl {
    padding: var(--space-64px);
  }
  ._1qhetbfvr {
    max-height: var(--space-0px);
  }
  ._1qhetbfvx {
    max-height: var(--space-1rem);
  }
  ._1qhetbfw3 {
    max-height: var(--space-1px);
  }
  ._1qhetbfw9 {
    max-height: var(--space-2px);
  }
  ._1qhetbfwf {
    max-height: var(--space-6px);
  }
  ._1qhetbfwl {
    max-height: var(--space-4px);
  }
  ._1qhetbfwr {
    max-height: var(--space-8px);
  }
  ._1qhetbfwx {
    max-height: var(--space-12px);
  }
  ._1qhetbfx3 {
    max-height: var(--space-14px);
  }
  ._1qhetbfx9 {
    max-height: var(--space-16px);
  }
  ._1qhetbfxf {
    max-height: var(--space-20px);
  }
  ._1qhetbfxl {
    max-height: var(--space-24px);
  }
  ._1qhetbfxr {
    max-height: var(--space-32px);
  }
  ._1qhetbfxx {
    max-height: var(--space-48px);
  }
  ._1qhetbfy3 {
    max-height: var(--space-56px);
  }
  ._1qhetbfy9 {
    max-height: var(--space-64px);
  }
  ._1qhetbfyf {
    max-width: var(--space-0px);
  }
  ._1qhetbfyl {
    max-width: var(--space-1rem);
  }
  ._1qhetbfyr {
    max-width: var(--space-1px);
  }
  ._1qhetbfyx {
    max-width: var(--space-2px);
  }
  ._1qhetbfz3 {
    max-width: var(--space-6px);
  }
  ._1qhetbfz9 {
    max-width: var(--space-4px);
  }
  ._1qhetbfzf {
    max-width: var(--space-8px);
  }
  ._1qhetbfzl {
    max-width: var(--space-12px);
  }
  ._1qhetbfzr {
    max-width: var(--space-14px);
  }
  ._1qhetbfzx {
    max-width: var(--space-16px);
  }
  ._1qhetbf103 {
    max-width: var(--space-20px);
  }
  ._1qhetbf109 {
    max-width: var(--space-24px);
  }
  ._1qhetbf10f {
    max-width: var(--space-32px);
  }
  ._1qhetbf10l {
    max-width: var(--space-48px);
  }
  ._1qhetbf10r {
    max-width: var(--space-56px);
  }
  ._1qhetbf10x {
    max-width: var(--space-64px);
  }
  ._1qhetbf113 {
    max-width: 100%;
  }
  ._1qhetbf119 {
    max-width: auto;
  }
  ._1qhetbf11f {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1qhetbf11l {
    max-width: 420px;
  }
  ._1qhetbf11r {
    max-width: 576px;
  }
  ._1qhetbf11x {
    max-width: 852px;
  }
  ._1qhetbf123 {
    max-width: 968px;
  }
  ._1qhetbf129 {
    max-width: 1080px;
  }
  ._1qhetbf12f {
    max-width: none;
  }
  ._1qhetbf12l {
    min-height: var(--space-0px);
  }
  ._1qhetbf12r {
    min-height: var(--space-1rem);
  }
  ._1qhetbf12x {
    min-height: var(--space-1px);
  }
  ._1qhetbf133 {
    min-height: var(--space-2px);
  }
  ._1qhetbf139 {
    min-height: var(--space-6px);
  }
  ._1qhetbf13f {
    min-height: var(--space-4px);
  }
  ._1qhetbf13l {
    min-height: var(--space-8px);
  }
  ._1qhetbf13r {
    min-height: var(--space-12px);
  }
  ._1qhetbf13x {
    min-height: var(--space-14px);
  }
  ._1qhetbf143 {
    min-height: var(--space-16px);
  }
  ._1qhetbf149 {
    min-height: var(--space-20px);
  }
  ._1qhetbf14f {
    min-height: var(--space-24px);
  }
  ._1qhetbf14l {
    min-height: var(--space-32px);
  }
  ._1qhetbf14r {
    min-height: var(--space-48px);
  }
  ._1qhetbf14x {
    min-height: var(--space-56px);
  }
  ._1qhetbf153 {
    min-height: var(--space-64px);
  }
  ._1qhetbf159 {
    min-height: 100%;
  }
  ._1qhetbf15f {
    min-height: auto;
  }
  ._1qhetbf15l {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1qhetbf15r {
    min-height: 420px;
  }
  ._1qhetbf15x {
    min-height: 576px;
  }
  ._1qhetbf163 {
    min-height: 852px;
  }
  ._1qhetbf169 {
    min-height: 968px;
  }
  ._1qhetbf16f {
    min-height: 1080px;
  }
  ._1qhetbf16l {
    min-height: 100vh;
  }
  ._1qhetbf16r {
    min-width: var(--space-0px);
  }
  ._1qhetbf16x {
    min-width: var(--space-1rem);
  }
  ._1qhetbf173 {
    min-width: var(--space-1px);
  }
  ._1qhetbf179 {
    min-width: var(--space-2px);
  }
  ._1qhetbf17f {
    min-width: var(--space-6px);
  }
  ._1qhetbf17l {
    min-width: var(--space-4px);
  }
  ._1qhetbf17r {
    min-width: var(--space-8px);
  }
  ._1qhetbf17x {
    min-width: var(--space-12px);
  }
  ._1qhetbf183 {
    min-width: var(--space-14px);
  }
  ._1qhetbf189 {
    min-width: var(--space-16px);
  }
  ._1qhetbf18f {
    min-width: var(--space-20px);
  }
  ._1qhetbf18l {
    min-width: var(--space-24px);
  }
  ._1qhetbf18r {
    min-width: var(--space-32px);
  }
  ._1qhetbf18x {
    min-width: var(--space-48px);
  }
  ._1qhetbf193 {
    min-width: var(--space-56px);
  }
  ._1qhetbf199 {
    min-width: var(--space-64px);
  }
  ._1qhetbf19f {
    padding-bottom: var(--space-0px);
  }
  ._1qhetbf19l {
    padding-bottom: var(--space-1rem);
  }
  ._1qhetbf19r {
    padding-bottom: var(--space-1px);
  }
  ._1qhetbf19x {
    padding-bottom: var(--space-2px);
  }
  ._1qhetbf1a3 {
    padding-bottom: var(--space-6px);
  }
  ._1qhetbf1a9 {
    padding-bottom: var(--space-4px);
  }
  ._1qhetbf1af {
    padding-bottom: var(--space-8px);
  }
  ._1qhetbf1al {
    padding-bottom: var(--space-12px);
  }
  ._1qhetbf1ar {
    padding-bottom: var(--space-14px);
  }
  ._1qhetbf1ax {
    padding-bottom: var(--space-16px);
  }
  ._1qhetbf1b3 {
    padding-bottom: var(--space-20px);
  }
  ._1qhetbf1b9 {
    padding-bottom: var(--space-24px);
  }
  ._1qhetbf1bf {
    padding-bottom: var(--space-32px);
  }
  ._1qhetbf1bl {
    padding-bottom: var(--space-48px);
  }
  ._1qhetbf1br {
    padding-bottom: var(--space-56px);
  }
  ._1qhetbf1bx {
    padding-bottom: var(--space-64px);
  }
  ._1qhetbf1c3 {
    padding-left: var(--space-0px);
  }
  ._1qhetbf1c9 {
    padding-left: var(--space-1rem);
  }
  ._1qhetbf1cf {
    padding-left: var(--space-1px);
  }
  ._1qhetbf1cl {
    padding-left: var(--space-2px);
  }
  ._1qhetbf1cr {
    padding-left: var(--space-6px);
  }
  ._1qhetbf1cx {
    padding-left: var(--space-4px);
  }
  ._1qhetbf1d3 {
    padding-left: var(--space-8px);
  }
  ._1qhetbf1d9 {
    padding-left: var(--space-12px);
  }
  ._1qhetbf1df {
    padding-left: var(--space-14px);
  }
  ._1qhetbf1dl {
    padding-left: var(--space-16px);
  }
  ._1qhetbf1dr {
    padding-left: var(--space-20px);
  }
  ._1qhetbf1dx {
    padding-left: var(--space-24px);
  }
  ._1qhetbf1e3 {
    padding-left: var(--space-32px);
  }
  ._1qhetbf1e9 {
    padding-left: var(--space-48px);
  }
  ._1qhetbf1ef {
    padding-left: var(--space-56px);
  }
  ._1qhetbf1el {
    padding-left: var(--space-64px);
  }
  ._1qhetbf1er {
    padding-right: var(--space-0px);
  }
  ._1qhetbf1ex {
    padding-right: var(--space-1rem);
  }
  ._1qhetbf1f3 {
    padding-right: var(--space-1px);
  }
  ._1qhetbf1f9 {
    padding-right: var(--space-2px);
  }
  ._1qhetbf1ff {
    padding-right: var(--space-6px);
  }
  ._1qhetbf1fl {
    padding-right: var(--space-4px);
  }
  ._1qhetbf1fr {
    padding-right: var(--space-8px);
  }
  ._1qhetbf1fx {
    padding-right: var(--space-12px);
  }
  ._1qhetbf1g3 {
    padding-right: var(--space-14px);
  }
  ._1qhetbf1g9 {
    padding-right: var(--space-16px);
  }
  ._1qhetbf1gf {
    padding-right: var(--space-20px);
  }
  ._1qhetbf1gl {
    padding-right: var(--space-24px);
  }
  ._1qhetbf1gr {
    padding-right: var(--space-32px);
  }
  ._1qhetbf1gx {
    padding-right: var(--space-48px);
  }
  ._1qhetbf1h3 {
    padding-right: var(--space-56px);
  }
  ._1qhetbf1h9 {
    padding-right: var(--space-64px);
  }
  ._1qhetbf1hf {
    padding-top: var(--space-0px);
  }
  ._1qhetbf1hl {
    padding-top: var(--space-1rem);
  }
  ._1qhetbf1hr {
    padding-top: var(--space-1px);
  }
  ._1qhetbf1hx {
    padding-top: var(--space-2px);
  }
  ._1qhetbf1i3 {
    padding-top: var(--space-6px);
  }
  ._1qhetbf1i9 {
    padding-top: var(--space-4px);
  }
  ._1qhetbf1if {
    padding-top: var(--space-8px);
  }
  ._1qhetbf1il {
    padding-top: var(--space-12px);
  }
  ._1qhetbf1ir {
    padding-top: var(--space-14px);
  }
  ._1qhetbf1ix {
    padding-top: var(--space-16px);
  }
  ._1qhetbf1j3 {
    padding-top: var(--space-20px);
  }
  ._1qhetbf1j9 {
    padding-top: var(--space-24px);
  }
  ._1qhetbf1jf {
    padding-top: var(--space-32px);
  }
  ._1qhetbf1jl {
    padding-top: var(--space-48px);
  }
  ._1qhetbf1jr {
    padding-top: var(--space-56px);
  }
  ._1qhetbf1jx {
    padding-top: var(--space-64px);
  }
  ._1qhetbf1k3 {
    font-size: var(--fontSizes-10px);
  }
  ._1qhetbf1k9 {
    font-size: var(--fontSizes-12px);
  }
  ._1qhetbf1kf {
    font-size: var(--fontSizes-16px);
  }
  ._1qhetbf1kl {
    font-size: var(--fontSizes-14px);
  }
  ._1qhetbf1kr {
    font-size: var(--fontSizes-20px);
  }
  ._1qhetbf1kx {
    font-size: var(--fontSizes-40px);
  }
  ._1qhetbf1l3 {
    font-size: inherit;
  }
  ._1qhetbf1l9 {
    flex: 1 1;
  }
  ._1qhetbf1lf {
    flex: 1 1 auto;
  }
  ._1qhetbf1ll {
    flex: 0 1 auto;
  }
  ._1qhetbf1lr {
    flex: none;
  }
  ._1qhetbf1lx {
    box-shadow: var(--shadows-level1);
  }
  ._1qhetbf1m3 {
    box-shadow: var(--shadows-active);
  }
  ._1qhetbf1m9 {
    box-shadow: var(--shadows-success);
  }
  ._1qhetbf1mf {
    box-shadow: var(--shadows-warning);
  }
  ._1qhetbf1ml {
    box-shadow: var(--shadows-danger);
  }
  ._1qhetbf1mr {
    box-shadow: var(--shadows-focus);
  }
  ._1qhetbf1mx {
    box-shadow: var(--shadows-inset);
  }
  ._1qhetbf1n3 {
    box-shadow: var(--shadows-tooltip);
  }
  ._1qhetbf1n9 {
    width: var(--space-0px);
  }
  ._1qhetbf1nf {
    width: var(--space-1rem);
  }
  ._1qhetbf1nl {
    width: var(--space-1px);
  }
  ._1qhetbf1nr {
    width: var(--space-2px);
  }
  ._1qhetbf1nx {
    width: var(--space-6px);
  }
  ._1qhetbf1o3 {
    width: var(--space-4px);
  }
  ._1qhetbf1o9 {
    width: var(--space-8px);
  }
  ._1qhetbf1of {
    width: var(--space-12px);
  }
  ._1qhetbf1ol {
    width: var(--space-14px);
  }
  ._1qhetbf1or {
    width: var(--space-16px);
  }
  ._1qhetbf1ox {
    width: var(--space-20px);
  }
  ._1qhetbf1p3 {
    width: var(--space-24px);
  }
  ._1qhetbf1p9 {
    width: var(--space-32px);
  }
  ._1qhetbf1pf {
    width: var(--space-48px);
  }
  ._1qhetbf1pl {
    width: var(--space-56px);
  }
  ._1qhetbf1pr {
    width: var(--space-64px);
  }
  ._1qhetbf1px {
    width: 100%;
  }
  ._1qhetbf1q3 {
    width: auto;
  }
  ._1qhetbf1q9 {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1qhetbf1qf {
    width: 420px;
  }
  ._1qhetbf1ql {
    width: 576px;
  }
  ._1qhetbf1qr {
    width: 852px;
  }
  ._1qhetbf1qx {
    width: 968px;
  }
  ._1qhetbf1r3 {
    width: 1080px;
  }
  ._1qhetbf1r9 {
    z-index: 0;
  }
  ._1qhetbf1rf {
    z-index: 1;
  }
  ._1qhetbf1rl {
    z-index: 10;
  }
  ._1qhetbf1rr {
    z-index: 20;
  }
  ._1qhetbf1rx {
    z-index: 30;
  }
  ._1qhetbf1s3 {
    z-index: 40;
  }
  ._1qhetbf1s9 {
    z-index: 50;
  }
  ._1qhetbf1sf {
    z-index: 75;
  }
  ._1qhetbf1sl {
    z-index: 99;
  }
  ._1qhetbf1sr {
    z-index: 100;
  }
  ._1qhetbf1sx {
    z-index: 9;
  }
  ._1qhetbf1t3 {
    z-index: 10;
  }
  ._1qhetbf1t9 {
    z-index: 100;
  }
  ._1qhetbf1tf {
    z-index: auto;
  }
  ._1qhetbf1tl {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf1tr {
    border-radius: var(--radii-0);
  }
  ._1qhetbf1tx {
    border-radius: var(--radii-8px);
  }
  ._1qhetbf1u3 {
    border-radius: var(--radii-12px);
  }
  ._1qhetbf1u9 {
    border-radius: var(--radii-20px);
  }
  ._1qhetbf1uf {
    border-radius: var(--radii-32px);
  }
  ._1qhetbf1ul {
    border-radius: var(--radii-small);
  }
  ._1qhetbf1ur {
    border-radius: var(--radii-default);
  }
  ._1qhetbf1ux {
    border-radius: var(--radii-card);
  }
  ._1qhetbf1v3 {
    border-radius: var(--radii-circle);
  }
  ._1qhetbf1v9 {
    border-top-left-radius: var(--radii-0);
  }
  ._1qhetbf1vf {
    border-top-left-radius: var(--radii-8px);
  }
  ._1qhetbf1vl {
    border-top-left-radius: var(--radii-12px);
  }
  ._1qhetbf1vr {
    border-top-left-radius: var(--radii-20px);
  }
  ._1qhetbf1vx {
    border-top-left-radius: var(--radii-32px);
  }
  ._1qhetbf1w3 {
    border-top-left-radius: var(--radii-small);
  }
  ._1qhetbf1w9 {
    border-top-left-radius: var(--radii-default);
  }
  ._1qhetbf1wf {
    border-top-left-radius: var(--radii-card);
  }
  ._1qhetbf1wl {
    border-top-left-radius: var(--radii-circle);
  }
  ._1qhetbf1wr {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1qhetbf1wx {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1qhetbf1x3 {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1qhetbf1x9 {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1qhetbf1xf {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1qhetbf1xl {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1qhetbf1xr {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1qhetbf1xx {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1qhetbf1y3 {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1qhetbf1y9 {
    border-top-right-radius: var(--radii-0);
  }
  ._1qhetbf1yf {
    border-top-right-radius: var(--radii-8px);
  }
  ._1qhetbf1yl {
    border-top-right-radius: var(--radii-12px);
  }
  ._1qhetbf1yr {
    border-top-right-radius: var(--radii-20px);
  }
  ._1qhetbf1yx {
    border-top-right-radius: var(--radii-32px);
  }
  ._1qhetbf1z3 {
    border-top-right-radius: var(--radii-small);
  }
  ._1qhetbf1z9 {
    border-top-right-radius: var(--radii-default);
  }
  ._1qhetbf1zf {
    border-top-right-radius: var(--radii-card);
  }
  ._1qhetbf1zl {
    border-top-right-radius: var(--radii-circle);
  }
  ._1qhetbf1zr {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1qhetbf1zx {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1qhetbf203 {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1qhetbf209 {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1qhetbf20f {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1qhetbf20l {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1qhetbf20r {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1qhetbf20x {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1qhetbf213 {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1qhetbf219 {
    gap: var(--space-0px);
  }
  ._1qhetbf21f {
    gap: var(--space-1rem);
  }
  ._1qhetbf21l {
    gap: var(--space-1px);
  }
  ._1qhetbf21r {
    gap: var(--space-2px);
  }
  ._1qhetbf21x {
    gap: var(--space-6px);
  }
  ._1qhetbf223 {
    gap: var(--space-4px);
  }
  ._1qhetbf229 {
    gap: var(--space-8px);
  }
  ._1qhetbf22f {
    gap: var(--space-12px);
  }
  ._1qhetbf22l {
    gap: var(--space-14px);
  }
  ._1qhetbf22r {
    gap: var(--space-16px);
  }
  ._1qhetbf22x {
    gap: var(--space-20px);
  }
  ._1qhetbf233 {
    gap: var(--space-24px);
  }
  ._1qhetbf239 {
    gap: var(--space-32px);
  }
  ._1qhetbf23f {
    gap: var(--space-48px);
  }
  ._1qhetbf23l {
    gap: var(--space-56px);
  }
  ._1qhetbf23r {
    gap: var(--space-64px);
  }
  ._1qhetbf23x {
    gap: var(--space-8px);
  }
  ._1qhetbf243 {
    gap: var(--space-12px);
  }
  ._1qhetbf249 {
    gap: var(--space-24px);
  }
  ._1qhetbf24f {
    row-gap: var(--space-0px);
  }
  ._1qhetbf24l {
    row-gap: var(--space-1rem);
  }
  ._1qhetbf24r {
    row-gap: var(--space-1px);
  }
  ._1qhetbf24x {
    row-gap: var(--space-2px);
  }
  ._1qhetbf253 {
    row-gap: var(--space-6px);
  }
  ._1qhetbf259 {
    row-gap: var(--space-4px);
  }
  ._1qhetbf25f {
    row-gap: var(--space-8px);
  }
  ._1qhetbf25l {
    row-gap: var(--space-12px);
  }
  ._1qhetbf25r {
    row-gap: var(--space-14px);
  }
  ._1qhetbf25x {
    row-gap: var(--space-16px);
  }
  ._1qhetbf263 {
    row-gap: var(--space-20px);
  }
  ._1qhetbf269 {
    row-gap: var(--space-24px);
  }
  ._1qhetbf26f {
    row-gap: var(--space-32px);
  }
  ._1qhetbf26l {
    row-gap: var(--space-48px);
  }
  ._1qhetbf26r {
    row-gap: var(--space-56px);
  }
  ._1qhetbf26x {
    row-gap: var(--space-64px);
  }
  ._1qhetbf273 {
    row-gap: var(--space-8px);
  }
  ._1qhetbf279 {
    row-gap: var(--space-12px);
  }
  ._1qhetbf27f {
    row-gap: var(--space-24px);
  }
  ._1qhetbf27l {
    column-gap: var(--space-0px);
  }
  ._1qhetbf27r {
    column-gap: var(--space-1rem);
  }
  ._1qhetbf27x {
    column-gap: var(--space-1px);
  }
  ._1qhetbf283 {
    column-gap: var(--space-2px);
  }
  ._1qhetbf289 {
    column-gap: var(--space-6px);
  }
  ._1qhetbf28f {
    column-gap: var(--space-4px);
  }
  ._1qhetbf28l {
    column-gap: var(--space-8px);
  }
  ._1qhetbf28r {
    column-gap: var(--space-12px);
  }
  ._1qhetbf28x {
    column-gap: var(--space-14px);
  }
  ._1qhetbf293 {
    column-gap: var(--space-16px);
  }
  ._1qhetbf299 {
    column-gap: var(--space-20px);
  }
  ._1qhetbf29f {
    column-gap: var(--space-24px);
  }
  ._1qhetbf29l {
    column-gap: var(--space-32px);
  }
  ._1qhetbf29r {
    column-gap: var(--space-48px);
  }
  ._1qhetbf29x {
    column-gap: var(--space-56px);
  }
  ._1qhetbf2a3 {
    column-gap: var(--space-64px);
  }
  ._1qhetbf2a9 {
    column-gap: var(--space-8px);
  }
  ._1qhetbf2af {
    column-gap: var(--space-12px);
  }
  ._1qhetbf2al {
    column-gap: var(--space-24px);
  }
  ._1qhetbf2ar {
    grid-auto-rows: auto;
  }
  ._1qhetbf2ax {
    opacity: 0.5;
  }
  ._1qhetbf2b3 {
    opacity: 0.6;
  }
  ._1qhetbf2b9 {
    line-height: var(--space-16px);
  }
  ._1qhetbf2bf {
    white: var(--colors-light-white);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    background-alt3: var(--colors-light-backgroundAlt3);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    input-third: var(--colors-light-inputThird);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
    gradient-obsidian: var(--colors-light-gradientObsidian);
  }
  ._1qhetbf2bl {
    white: var(--colors-dark-white);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    background-alt3: var(--colors-dark-backgroundAlt3);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    input-third: var(--colors-dark-inputThird);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
    gradient-obsidian: var(--colors-dark-gradientObsidian);
  }
  ._1qhetbf2br {
    border-bottom-color: var(--colors-white);
  }
  ._1qhetbf2bx {
    border-bottom-color: var(--colors-failure);
  }
  ._1qhetbf2c3 {
    border-bottom-color: var(--colors-failure33);
  }
  ._1qhetbf2c9 {
    border-bottom-color: var(--colors-primary);
  }
  ._1qhetbf2cf {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1qhetbf2cl {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1qhetbf2cr {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1qhetbf2cx {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1qhetbf2d3 {
    border-bottom-color: var(--colors-success);
  }
  ._1qhetbf2d9 {
    border-bottom-color: var(--colors-success19);
  }
  ._1qhetbf2df {
    border-bottom-color: var(--colors-warning);
  }
  ._1qhetbf2dl {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1qhetbf2dr {
    border-bottom-color: var(--colors-warning33);
  }
  ._1qhetbf2dx {
    border-bottom-color: var(--colors-binance);
  }
  ._1qhetbf2e3 {
    border-bottom-color: var(--colors-overlay);
  }
  ._1qhetbf2e9 {
    border-bottom-color: var(--colors-gold);
  }
  ._1qhetbf2ef {
    border-bottom-color: var(--colors-silver);
  }
  ._1qhetbf2el {
    border-bottom-color: var(--colors-bronze);
  }
  ._1qhetbf2er {
    border-bottom-color: var(--colors-secondary);
  }
  ._1qhetbf2ex {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1qhetbf2f3 {
    border-bottom-color: var(--colors-background);
  }
  ._1qhetbf2f9 {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1qhetbf2ff {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1qhetbf2fl {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1qhetbf2fr {
    border-bottom-color: var(--colors-backgroundAlt3);
  }
  ._1qhetbf2fx {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1qhetbf2g3 {
    border-bottom-color: var(--colors-contrast);
  }
  ._1qhetbf2g9 {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1qhetbf2gf {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1qhetbf2gl {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1qhetbf2gr {
    border-bottom-color: var(--colors-input);
  }
  ._1qhetbf2gx {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1qhetbf2h3 {
    border-bottom-color: var(--colors-inputThird);
  }
  ._1qhetbf2h9 {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1qhetbf2hf {
    border-bottom-color: var(--colors-text);
  }
  ._1qhetbf2hl {
    border-bottom-color: var(--colors-text99);
  }
  ._1qhetbf2hr {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1qhetbf2hx {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1qhetbf2i3 {
    border-bottom-color: var(--colors-disabled);
  }
  ._1qhetbf2i9 {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1qhetbf2if {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1qhetbf2il {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1qhetbf2ir {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1qhetbf2ix {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1qhetbf2j3 {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1qhetbf2j9 {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1qhetbf2jf {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1qhetbf2jl {
    border-bottom-color: var(--colors-gradientObsidian);
  }
  ._1qhetbf2jr {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf2jx {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 1080px) {
  ._1qhetbf4 {
    display: block;
  }
  ._1qhetbfa {
    display: flex;
  }
  ._1qhetbfg {
    display: grid;
  }
  ._1qhetbfm {
    display: inline;
  }
  ._1qhetbfs {
    display: inline-flex;
  }
  ._1qhetbfy {
    display: inline-block;
  }
  ._1qhetbf14 {
    display: none;
  }
  ._1qhetbf1a {
    flex-direction: column;
  }
  ._1qhetbf1g {
    flex-direction: row;
  }
  ._1qhetbf1m {
    flex-direction: column-reverse;
  }
  ._1qhetbf1s {
    align-items: center;
  }
  ._1qhetbf1y {
    align-items: end;
  }
  ._1qhetbf24 {
    align-items: baseLine;
  }
  ._1qhetbf2a {
    align-items: inherit;
  }
  ._1qhetbf2g {
    align-items: flex-start;
  }
  ._1qhetbf2m {
    align-items: center;
  }
  ._1qhetbf2s {
    align-items: start;
  }
  ._1qhetbf2y {
    align-items: flex-end;
  }
  ._1qhetbf34 {
    align-items: stretch;
  }
  ._1qhetbf3a {
    align-self: flex-start;
  }
  ._1qhetbf3g {
    align-self: center;
  }
  ._1qhetbf3m {
    align-self: start;
  }
  ._1qhetbf3s {
    align-self: flex-end;
  }
  ._1qhetbf3y {
    align-self: stretch;
  }
  ._1qhetbf44 {
    flex-wrap: wrap;
  }
  ._1qhetbf4a {
    flex-wrap: nowrap;
  }
  ._1qhetbf4g {
    flex-grow: 1;
  }
  ._1qhetbf4m {
    overflow: auto;
  }
  ._1qhetbf4s {
    overflow: hidden;
  }
  ._1qhetbf4y {
    overflow: scroll;
  }
  ._1qhetbf54 {
    overflow: unset;
  }
  ._1qhetbf5a {
    overflow-y: auto;
  }
  ._1qhetbf5g {
    overflow-y: hidden;
  }
  ._1qhetbf5m {
    overflow-y: scroll;
  }
  ._1qhetbf5s {
    overflow-x: auto;
  }
  ._1qhetbf5y {
    overflow-x: hidden;
  }
  ._1qhetbf64 {
    overflow-x: scroll;
  }
  ._1qhetbf6a {
    position: absolute;
  }
  ._1qhetbf6g {
    position: fixed;
  }
  ._1qhetbf6m {
    position: relative;
  }
  ._1qhetbf6s {
    position: sticky;
  }
  ._1qhetbf6y {
    text-align: center;
  }
  ._1qhetbf74 {
    text-align: left;
  }
  ._1qhetbf7a {
    text-align: right;
  }
  ._1qhetbf7g {
    justify-content: flex-start;
  }
  ._1qhetbf7m {
    justify-content: center;
  }
  ._1qhetbf7s {
    justify-content: start;
  }
  ._1qhetbf7y {
    justify-content: flex-end;
  }
  ._1qhetbf84 {
    justify-content: stretch;
  }
  ._1qhetbf8a {
    justify-content: space-around;
  }
  ._1qhetbf8g {
    justify-content: space-between;
  }
  ._1qhetbf8m {
    justify-items: flex-start;
  }
  ._1qhetbf8s {
    justify-items: center;
  }
  ._1qhetbf8y {
    justify-items: start;
  }
  ._1qhetbf94 {
    justify-items: flex-end;
  }
  ._1qhetbf9a {
    justify-items: stretch;
  }
  ._1qhetbf9g {
    justify-items: space-around;
  }
  ._1qhetbf9m {
    justify-items: space-between;
  }
  ._1qhetbf9s {
    justify-self: flex-start;
  }
  ._1qhetbf9y {
    justify-self: center;
  }
  ._1qhetbfa4 {
    justify-self: start;
  }
  ._1qhetbfaa {
    justify-self: flex-end;
  }
  ._1qhetbfag {
    justify-self: stretch;
  }
  ._1qhetbfam {
    inset: 0px;
  }
  ._1qhetbfas {
    height: var(--space-0px);
  }
  ._1qhetbfay {
    height: var(--space-1rem);
  }
  ._1qhetbfb4 {
    height: var(--space-1px);
  }
  ._1qhetbfba {
    height: var(--space-2px);
  }
  ._1qhetbfbg {
    height: var(--space-6px);
  }
  ._1qhetbfbm {
    height: var(--space-4px);
  }
  ._1qhetbfbs {
    height: var(--space-8px);
  }
  ._1qhetbfby {
    height: var(--space-12px);
  }
  ._1qhetbfc4 {
    height: var(--space-14px);
  }
  ._1qhetbfca {
    height: var(--space-16px);
  }
  ._1qhetbfcg {
    height: var(--space-20px);
  }
  ._1qhetbfcm {
    height: var(--space-24px);
  }
  ._1qhetbfcs {
    height: var(--space-32px);
  }
  ._1qhetbfcy {
    height: var(--space-48px);
  }
  ._1qhetbfd4 {
    height: var(--space-56px);
  }
  ._1qhetbfda {
    height: var(--space-64px);
  }
  ._1qhetbfdg {
    height: 100%;
  }
  ._1qhetbfdm {
    height: auto;
  }
  ._1qhetbfds {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1qhetbfdy {
    height: 420px;
  }
  ._1qhetbfe4 {
    height: 576px;
  }
  ._1qhetbfea {
    height: 852px;
  }
  ._1qhetbfeg {
    height: 968px;
  }
  ._1qhetbfem {
    height: 1080px;
  }
  ._1qhetbfes {
    height: 100vh;
  }
  ._1qhetbfey {
    margin-bottom: var(--space-0px);
  }
  ._1qhetbff4 {
    margin-bottom: var(--space-1rem);
  }
  ._1qhetbffa {
    margin-bottom: var(--space-1px);
  }
  ._1qhetbffg {
    margin-bottom: var(--space-2px);
  }
  ._1qhetbffm {
    margin-bottom: var(--space-6px);
  }
  ._1qhetbffs {
    margin-bottom: var(--space-4px);
  }
  ._1qhetbffy {
    margin-bottom: var(--space-8px);
  }
  ._1qhetbfg4 {
    margin-bottom: var(--space-12px);
  }
  ._1qhetbfga {
    margin-bottom: var(--space-14px);
  }
  ._1qhetbfgg {
    margin-bottom: var(--space-16px);
  }
  ._1qhetbfgm {
    margin-bottom: var(--space-20px);
  }
  ._1qhetbfgs {
    margin-bottom: var(--space-24px);
  }
  ._1qhetbfgy {
    margin-bottom: var(--space-32px);
  }
  ._1qhetbfh4 {
    margin-bottom: var(--space-48px);
  }
  ._1qhetbfha {
    margin-bottom: var(--space-56px);
  }
  ._1qhetbfhg {
    margin-bottom: var(--space-64px);
  }
  ._1qhetbfhm {
    margin-bottom: auto;
  }
  ._1qhetbfhs {
    margin-left: var(--space-0px);
  }
  ._1qhetbfhy {
    margin-left: var(--space-1rem);
  }
  ._1qhetbfi4 {
    margin-left: var(--space-1px);
  }
  ._1qhetbfia {
    margin-left: var(--space-2px);
  }
  ._1qhetbfig {
    margin-left: var(--space-6px);
  }
  ._1qhetbfim {
    margin-left: var(--space-4px);
  }
  ._1qhetbfis {
    margin-left: var(--space-8px);
  }
  ._1qhetbfiy {
    margin-left: var(--space-12px);
  }
  ._1qhetbfj4 {
    margin-left: var(--space-14px);
  }
  ._1qhetbfja {
    margin-left: var(--space-16px);
  }
  ._1qhetbfjg {
    margin-left: var(--space-20px);
  }
  ._1qhetbfjm {
    margin-left: var(--space-24px);
  }
  ._1qhetbfjs {
    margin-left: var(--space-32px);
  }
  ._1qhetbfjy {
    margin-left: var(--space-48px);
  }
  ._1qhetbfk4 {
    margin-left: var(--space-56px);
  }
  ._1qhetbfka {
    margin-left: var(--space-64px);
  }
  ._1qhetbfkg {
    margin-left: auto;
  }
  ._1qhetbfkm {
    margin-right: var(--space-0px);
  }
  ._1qhetbfks {
    margin-right: var(--space-1rem);
  }
  ._1qhetbfky {
    margin-right: var(--space-1px);
  }
  ._1qhetbfl4 {
    margin-right: var(--space-2px);
  }
  ._1qhetbfla {
    margin-right: var(--space-6px);
  }
  ._1qhetbflg {
    margin-right: var(--space-4px);
  }
  ._1qhetbflm {
    margin-right: var(--space-8px);
  }
  ._1qhetbfls {
    margin-right: var(--space-12px);
  }
  ._1qhetbfly {
    margin-right: var(--space-14px);
  }
  ._1qhetbfm4 {
    margin-right: var(--space-16px);
  }
  ._1qhetbfma {
    margin-right: var(--space-20px);
  }
  ._1qhetbfmg {
    margin-right: var(--space-24px);
  }
  ._1qhetbfmm {
    margin-right: var(--space-32px);
  }
  ._1qhetbfms {
    margin-right: var(--space-48px);
  }
  ._1qhetbfmy {
    margin-right: var(--space-56px);
  }
  ._1qhetbfn4 {
    margin-right: var(--space-64px);
  }
  ._1qhetbfna {
    margin-right: auto;
  }
  ._1qhetbfng {
    margin-top: var(--space-0px);
  }
  ._1qhetbfnm {
    margin-top: var(--space-1rem);
  }
  ._1qhetbfns {
    margin-top: var(--space-1px);
  }
  ._1qhetbfny {
    margin-top: var(--space-2px);
  }
  ._1qhetbfo4 {
    margin-top: var(--space-6px);
  }
  ._1qhetbfoa {
    margin-top: var(--space-4px);
  }
  ._1qhetbfog {
    margin-top: var(--space-8px);
  }
  ._1qhetbfom {
    margin-top: var(--space-12px);
  }
  ._1qhetbfos {
    margin-top: var(--space-14px);
  }
  ._1qhetbfoy {
    margin-top: var(--space-16px);
  }
  ._1qhetbfp4 {
    margin-top: var(--space-20px);
  }
  ._1qhetbfpa {
    margin-top: var(--space-24px);
  }
  ._1qhetbfpg {
    margin-top: var(--space-32px);
  }
  ._1qhetbfpm {
    margin-top: var(--space-48px);
  }
  ._1qhetbfps {
    margin-top: var(--space-56px);
  }
  ._1qhetbfpy {
    margin-top: var(--space-64px);
  }
  ._1qhetbfq4 {
    margin-top: auto;
  }
  ._1qhetbfqa {
    margin: var(--space-0px);
  }
  ._1qhetbfqg {
    margin: var(--space-1rem);
  }
  ._1qhetbfqm {
    margin: var(--space-1px);
  }
  ._1qhetbfqs {
    margin: var(--space-2px);
  }
  ._1qhetbfqy {
    margin: var(--space-6px);
  }
  ._1qhetbfr4 {
    margin: var(--space-4px);
  }
  ._1qhetbfra {
    margin: var(--space-8px);
  }
  ._1qhetbfrg {
    margin: var(--space-12px);
  }
  ._1qhetbfrm {
    margin: var(--space-14px);
  }
  ._1qhetbfrs {
    margin: var(--space-16px);
  }
  ._1qhetbfry {
    margin: var(--space-20px);
  }
  ._1qhetbfs4 {
    margin: var(--space-24px);
  }
  ._1qhetbfsa {
    margin: var(--space-32px);
  }
  ._1qhetbfsg {
    margin: var(--space-48px);
  }
  ._1qhetbfsm {
    margin: var(--space-56px);
  }
  ._1qhetbfss {
    margin: var(--space-64px);
  }
  ._1qhetbfsy {
    margin: auto;
  }
  ._1qhetbft4 {
    padding: var(--space-0px);
  }
  ._1qhetbfta {
    padding: var(--space-1rem);
  }
  ._1qhetbftg {
    padding: var(--space-1px);
  }
  ._1qhetbftm {
    padding: var(--space-2px);
  }
  ._1qhetbfts {
    padding: var(--space-6px);
  }
  ._1qhetbfty {
    padding: var(--space-4px);
  }
  ._1qhetbfu4 {
    padding: var(--space-8px);
  }
  ._1qhetbfua {
    padding: var(--space-12px);
  }
  ._1qhetbfug {
    padding: var(--space-14px);
  }
  ._1qhetbfum {
    padding: var(--space-16px);
  }
  ._1qhetbfus {
    padding: var(--space-20px);
  }
  ._1qhetbfuy {
    padding: var(--space-24px);
  }
  ._1qhetbfv4 {
    padding: var(--space-32px);
  }
  ._1qhetbfva {
    padding: var(--space-48px);
  }
  ._1qhetbfvg {
    padding: var(--space-56px);
  }
  ._1qhetbfvm {
    padding: var(--space-64px);
  }
  ._1qhetbfvs {
    max-height: var(--space-0px);
  }
  ._1qhetbfvy {
    max-height: var(--space-1rem);
  }
  ._1qhetbfw4 {
    max-height: var(--space-1px);
  }
  ._1qhetbfwa {
    max-height: var(--space-2px);
  }
  ._1qhetbfwg {
    max-height: var(--space-6px);
  }
  ._1qhetbfwm {
    max-height: var(--space-4px);
  }
  ._1qhetbfws {
    max-height: var(--space-8px);
  }
  ._1qhetbfwy {
    max-height: var(--space-12px);
  }
  ._1qhetbfx4 {
    max-height: var(--space-14px);
  }
  ._1qhetbfxa {
    max-height: var(--space-16px);
  }
  ._1qhetbfxg {
    max-height: var(--space-20px);
  }
  ._1qhetbfxm {
    max-height: var(--space-24px);
  }
  ._1qhetbfxs {
    max-height: var(--space-32px);
  }
  ._1qhetbfxy {
    max-height: var(--space-48px);
  }
  ._1qhetbfy4 {
    max-height: var(--space-56px);
  }
  ._1qhetbfya {
    max-height: var(--space-64px);
  }
  ._1qhetbfyg {
    max-width: var(--space-0px);
  }
  ._1qhetbfym {
    max-width: var(--space-1rem);
  }
  ._1qhetbfys {
    max-width: var(--space-1px);
  }
  ._1qhetbfyy {
    max-width: var(--space-2px);
  }
  ._1qhetbfz4 {
    max-width: var(--space-6px);
  }
  ._1qhetbfza {
    max-width: var(--space-4px);
  }
  ._1qhetbfzg {
    max-width: var(--space-8px);
  }
  ._1qhetbfzm {
    max-width: var(--space-12px);
  }
  ._1qhetbfzs {
    max-width: var(--space-14px);
  }
  ._1qhetbfzy {
    max-width: var(--space-16px);
  }
  ._1qhetbf104 {
    max-width: var(--space-20px);
  }
  ._1qhetbf10a {
    max-width: var(--space-24px);
  }
  ._1qhetbf10g {
    max-width: var(--space-32px);
  }
  ._1qhetbf10m {
    max-width: var(--space-48px);
  }
  ._1qhetbf10s {
    max-width: var(--space-56px);
  }
  ._1qhetbf10y {
    max-width: var(--space-64px);
  }
  ._1qhetbf114 {
    max-width: 100%;
  }
  ._1qhetbf11a {
    max-width: auto;
  }
  ._1qhetbf11g {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1qhetbf11m {
    max-width: 420px;
  }
  ._1qhetbf11s {
    max-width: 576px;
  }
  ._1qhetbf11y {
    max-width: 852px;
  }
  ._1qhetbf124 {
    max-width: 968px;
  }
  ._1qhetbf12a {
    max-width: 1080px;
  }
  ._1qhetbf12g {
    max-width: none;
  }
  ._1qhetbf12m {
    min-height: var(--space-0px);
  }
  ._1qhetbf12s {
    min-height: var(--space-1rem);
  }
  ._1qhetbf12y {
    min-height: var(--space-1px);
  }
  ._1qhetbf134 {
    min-height: var(--space-2px);
  }
  ._1qhetbf13a {
    min-height: var(--space-6px);
  }
  ._1qhetbf13g {
    min-height: var(--space-4px);
  }
  ._1qhetbf13m {
    min-height: var(--space-8px);
  }
  ._1qhetbf13s {
    min-height: var(--space-12px);
  }
  ._1qhetbf13y {
    min-height: var(--space-14px);
  }
  ._1qhetbf144 {
    min-height: var(--space-16px);
  }
  ._1qhetbf14a {
    min-height: var(--space-20px);
  }
  ._1qhetbf14g {
    min-height: var(--space-24px);
  }
  ._1qhetbf14m {
    min-height: var(--space-32px);
  }
  ._1qhetbf14s {
    min-height: var(--space-48px);
  }
  ._1qhetbf14y {
    min-height: var(--space-56px);
  }
  ._1qhetbf154 {
    min-height: var(--space-64px);
  }
  ._1qhetbf15a {
    min-height: 100%;
  }
  ._1qhetbf15g {
    min-height: auto;
  }
  ._1qhetbf15m {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1qhetbf15s {
    min-height: 420px;
  }
  ._1qhetbf15y {
    min-height: 576px;
  }
  ._1qhetbf164 {
    min-height: 852px;
  }
  ._1qhetbf16a {
    min-height: 968px;
  }
  ._1qhetbf16g {
    min-height: 1080px;
  }
  ._1qhetbf16m {
    min-height: 100vh;
  }
  ._1qhetbf16s {
    min-width: var(--space-0px);
  }
  ._1qhetbf16y {
    min-width: var(--space-1rem);
  }
  ._1qhetbf174 {
    min-width: var(--space-1px);
  }
  ._1qhetbf17a {
    min-width: var(--space-2px);
  }
  ._1qhetbf17g {
    min-width: var(--space-6px);
  }
  ._1qhetbf17m {
    min-width: var(--space-4px);
  }
  ._1qhetbf17s {
    min-width: var(--space-8px);
  }
  ._1qhetbf17y {
    min-width: var(--space-12px);
  }
  ._1qhetbf184 {
    min-width: var(--space-14px);
  }
  ._1qhetbf18a {
    min-width: var(--space-16px);
  }
  ._1qhetbf18g {
    min-width: var(--space-20px);
  }
  ._1qhetbf18m {
    min-width: var(--space-24px);
  }
  ._1qhetbf18s {
    min-width: var(--space-32px);
  }
  ._1qhetbf18y {
    min-width: var(--space-48px);
  }
  ._1qhetbf194 {
    min-width: var(--space-56px);
  }
  ._1qhetbf19a {
    min-width: var(--space-64px);
  }
  ._1qhetbf19g {
    padding-bottom: var(--space-0px);
  }
  ._1qhetbf19m {
    padding-bottom: var(--space-1rem);
  }
  ._1qhetbf19s {
    padding-bottom: var(--space-1px);
  }
  ._1qhetbf19y {
    padding-bottom: var(--space-2px);
  }
  ._1qhetbf1a4 {
    padding-bottom: var(--space-6px);
  }
  ._1qhetbf1aa {
    padding-bottom: var(--space-4px);
  }
  ._1qhetbf1ag {
    padding-bottom: var(--space-8px);
  }
  ._1qhetbf1am {
    padding-bottom: var(--space-12px);
  }
  ._1qhetbf1as {
    padding-bottom: var(--space-14px);
  }
  ._1qhetbf1ay {
    padding-bottom: var(--space-16px);
  }
  ._1qhetbf1b4 {
    padding-bottom: var(--space-20px);
  }
  ._1qhetbf1ba {
    padding-bottom: var(--space-24px);
  }
  ._1qhetbf1bg {
    padding-bottom: var(--space-32px);
  }
  ._1qhetbf1bm {
    padding-bottom: var(--space-48px);
  }
  ._1qhetbf1bs {
    padding-bottom: var(--space-56px);
  }
  ._1qhetbf1by {
    padding-bottom: var(--space-64px);
  }
  ._1qhetbf1c4 {
    padding-left: var(--space-0px);
  }
  ._1qhetbf1ca {
    padding-left: var(--space-1rem);
  }
  ._1qhetbf1cg {
    padding-left: var(--space-1px);
  }
  ._1qhetbf1cm {
    padding-left: var(--space-2px);
  }
  ._1qhetbf1cs {
    padding-left: var(--space-6px);
  }
  ._1qhetbf1cy {
    padding-left: var(--space-4px);
  }
  ._1qhetbf1d4 {
    padding-left: var(--space-8px);
  }
  ._1qhetbf1da {
    padding-left: var(--space-12px);
  }
  ._1qhetbf1dg {
    padding-left: var(--space-14px);
  }
  ._1qhetbf1dm {
    padding-left: var(--space-16px);
  }
  ._1qhetbf1ds {
    padding-left: var(--space-20px);
  }
  ._1qhetbf1dy {
    padding-left: var(--space-24px);
  }
  ._1qhetbf1e4 {
    padding-left: var(--space-32px);
  }
  ._1qhetbf1ea {
    padding-left: var(--space-48px);
  }
  ._1qhetbf1eg {
    padding-left: var(--space-56px);
  }
  ._1qhetbf1em {
    padding-left: var(--space-64px);
  }
  ._1qhetbf1es {
    padding-right: var(--space-0px);
  }
  ._1qhetbf1ey {
    padding-right: var(--space-1rem);
  }
  ._1qhetbf1f4 {
    padding-right: var(--space-1px);
  }
  ._1qhetbf1fa {
    padding-right: var(--space-2px);
  }
  ._1qhetbf1fg {
    padding-right: var(--space-6px);
  }
  ._1qhetbf1fm {
    padding-right: var(--space-4px);
  }
  ._1qhetbf1fs {
    padding-right: var(--space-8px);
  }
  ._1qhetbf1fy {
    padding-right: var(--space-12px);
  }
  ._1qhetbf1g4 {
    padding-right: var(--space-14px);
  }
  ._1qhetbf1ga {
    padding-right: var(--space-16px);
  }
  ._1qhetbf1gg {
    padding-right: var(--space-20px);
  }
  ._1qhetbf1gm {
    padding-right: var(--space-24px);
  }
  ._1qhetbf1gs {
    padding-right: var(--space-32px);
  }
  ._1qhetbf1gy {
    padding-right: var(--space-48px);
  }
  ._1qhetbf1h4 {
    padding-right: var(--space-56px);
  }
  ._1qhetbf1ha {
    padding-right: var(--space-64px);
  }
  ._1qhetbf1hg {
    padding-top: var(--space-0px);
  }
  ._1qhetbf1hm {
    padding-top: var(--space-1rem);
  }
  ._1qhetbf1hs {
    padding-top: var(--space-1px);
  }
  ._1qhetbf1hy {
    padding-top: var(--space-2px);
  }
  ._1qhetbf1i4 {
    padding-top: var(--space-6px);
  }
  ._1qhetbf1ia {
    padding-top: var(--space-4px);
  }
  ._1qhetbf1ig {
    padding-top: var(--space-8px);
  }
  ._1qhetbf1im {
    padding-top: var(--space-12px);
  }
  ._1qhetbf1is {
    padding-top: var(--space-14px);
  }
  ._1qhetbf1iy {
    padding-top: var(--space-16px);
  }
  ._1qhetbf1j4 {
    padding-top: var(--space-20px);
  }
  ._1qhetbf1ja {
    padding-top: var(--space-24px);
  }
  ._1qhetbf1jg {
    padding-top: var(--space-32px);
  }
  ._1qhetbf1jm {
    padding-top: var(--space-48px);
  }
  ._1qhetbf1js {
    padding-top: var(--space-56px);
  }
  ._1qhetbf1jy {
    padding-top: var(--space-64px);
  }
  ._1qhetbf1k4 {
    font-size: var(--fontSizes-10px);
  }
  ._1qhetbf1ka {
    font-size: var(--fontSizes-12px);
  }
  ._1qhetbf1kg {
    font-size: var(--fontSizes-16px);
  }
  ._1qhetbf1km {
    font-size: var(--fontSizes-14px);
  }
  ._1qhetbf1ks {
    font-size: var(--fontSizes-20px);
  }
  ._1qhetbf1ky {
    font-size: var(--fontSizes-40px);
  }
  ._1qhetbf1l4 {
    font-size: inherit;
  }
  ._1qhetbf1la {
    flex: 1 1;
  }
  ._1qhetbf1lg {
    flex: 1 1 auto;
  }
  ._1qhetbf1lm {
    flex: 0 1 auto;
  }
  ._1qhetbf1ls {
    flex: none;
  }
  ._1qhetbf1ly {
    box-shadow: var(--shadows-level1);
  }
  ._1qhetbf1m4 {
    box-shadow: var(--shadows-active);
  }
  ._1qhetbf1ma {
    box-shadow: var(--shadows-success);
  }
  ._1qhetbf1mg {
    box-shadow: var(--shadows-warning);
  }
  ._1qhetbf1mm {
    box-shadow: var(--shadows-danger);
  }
  ._1qhetbf1ms {
    box-shadow: var(--shadows-focus);
  }
  ._1qhetbf1my {
    box-shadow: var(--shadows-inset);
  }
  ._1qhetbf1n4 {
    box-shadow: var(--shadows-tooltip);
  }
  ._1qhetbf1na {
    width: var(--space-0px);
  }
  ._1qhetbf1ng {
    width: var(--space-1rem);
  }
  ._1qhetbf1nm {
    width: var(--space-1px);
  }
  ._1qhetbf1ns {
    width: var(--space-2px);
  }
  ._1qhetbf1ny {
    width: var(--space-6px);
  }
  ._1qhetbf1o4 {
    width: var(--space-4px);
  }
  ._1qhetbf1oa {
    width: var(--space-8px);
  }
  ._1qhetbf1og {
    width: var(--space-12px);
  }
  ._1qhetbf1om {
    width: var(--space-14px);
  }
  ._1qhetbf1os {
    width: var(--space-16px);
  }
  ._1qhetbf1oy {
    width: var(--space-20px);
  }
  ._1qhetbf1p4 {
    width: var(--space-24px);
  }
  ._1qhetbf1pa {
    width: var(--space-32px);
  }
  ._1qhetbf1pg {
    width: var(--space-48px);
  }
  ._1qhetbf1pm {
    width: var(--space-56px);
  }
  ._1qhetbf1ps {
    width: var(--space-64px);
  }
  ._1qhetbf1py {
    width: 100%;
  }
  ._1qhetbf1q4 {
    width: auto;
  }
  ._1qhetbf1qa {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1qhetbf1qg {
    width: 420px;
  }
  ._1qhetbf1qm {
    width: 576px;
  }
  ._1qhetbf1qs {
    width: 852px;
  }
  ._1qhetbf1qy {
    width: 968px;
  }
  ._1qhetbf1r4 {
    width: 1080px;
  }
  ._1qhetbf1ra {
    z-index: 0;
  }
  ._1qhetbf1rg {
    z-index: 1;
  }
  ._1qhetbf1rm {
    z-index: 10;
  }
  ._1qhetbf1rs {
    z-index: 20;
  }
  ._1qhetbf1ry {
    z-index: 30;
  }
  ._1qhetbf1s4 {
    z-index: 40;
  }
  ._1qhetbf1sa {
    z-index: 50;
  }
  ._1qhetbf1sg {
    z-index: 75;
  }
  ._1qhetbf1sm {
    z-index: 99;
  }
  ._1qhetbf1ss {
    z-index: 100;
  }
  ._1qhetbf1sy {
    z-index: 9;
  }
  ._1qhetbf1t4 {
    z-index: 10;
  }
  ._1qhetbf1ta {
    z-index: 100;
  }
  ._1qhetbf1tg {
    z-index: auto;
  }
  ._1qhetbf1tm {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf1ts {
    border-radius: var(--radii-0);
  }
  ._1qhetbf1ty {
    border-radius: var(--radii-8px);
  }
  ._1qhetbf1u4 {
    border-radius: var(--radii-12px);
  }
  ._1qhetbf1ua {
    border-radius: var(--radii-20px);
  }
  ._1qhetbf1ug {
    border-radius: var(--radii-32px);
  }
  ._1qhetbf1um {
    border-radius: var(--radii-small);
  }
  ._1qhetbf1us {
    border-radius: var(--radii-default);
  }
  ._1qhetbf1uy {
    border-radius: var(--radii-card);
  }
  ._1qhetbf1v4 {
    border-radius: var(--radii-circle);
  }
  ._1qhetbf1va {
    border-top-left-radius: var(--radii-0);
  }
  ._1qhetbf1vg {
    border-top-left-radius: var(--radii-8px);
  }
  ._1qhetbf1vm {
    border-top-left-radius: var(--radii-12px);
  }
  ._1qhetbf1vs {
    border-top-left-radius: var(--radii-20px);
  }
  ._1qhetbf1vy {
    border-top-left-radius: var(--radii-32px);
  }
  ._1qhetbf1w4 {
    border-top-left-radius: var(--radii-small);
  }
  ._1qhetbf1wa {
    border-top-left-radius: var(--radii-default);
  }
  ._1qhetbf1wg {
    border-top-left-radius: var(--radii-card);
  }
  ._1qhetbf1wm {
    border-top-left-radius: var(--radii-circle);
  }
  ._1qhetbf1ws {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1qhetbf1wy {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1qhetbf1x4 {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1qhetbf1xa {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1qhetbf1xg {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1qhetbf1xm {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1qhetbf1xs {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1qhetbf1xy {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1qhetbf1y4 {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1qhetbf1ya {
    border-top-right-radius: var(--radii-0);
  }
  ._1qhetbf1yg {
    border-top-right-radius: var(--radii-8px);
  }
  ._1qhetbf1ym {
    border-top-right-radius: var(--radii-12px);
  }
  ._1qhetbf1ys {
    border-top-right-radius: var(--radii-20px);
  }
  ._1qhetbf1yy {
    border-top-right-radius: var(--radii-32px);
  }
  ._1qhetbf1z4 {
    border-top-right-radius: var(--radii-small);
  }
  ._1qhetbf1za {
    border-top-right-radius: var(--radii-default);
  }
  ._1qhetbf1zg {
    border-top-right-radius: var(--radii-card);
  }
  ._1qhetbf1zm {
    border-top-right-radius: var(--radii-circle);
  }
  ._1qhetbf1zs {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1qhetbf1zy {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1qhetbf204 {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1qhetbf20a {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1qhetbf20g {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1qhetbf20m {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1qhetbf20s {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1qhetbf20y {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1qhetbf214 {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1qhetbf21a {
    gap: var(--space-0px);
  }
  ._1qhetbf21g {
    gap: var(--space-1rem);
  }
  ._1qhetbf21m {
    gap: var(--space-1px);
  }
  ._1qhetbf21s {
    gap: var(--space-2px);
  }
  ._1qhetbf21y {
    gap: var(--space-6px);
  }
  ._1qhetbf224 {
    gap: var(--space-4px);
  }
  ._1qhetbf22a {
    gap: var(--space-8px);
  }
  ._1qhetbf22g {
    gap: var(--space-12px);
  }
  ._1qhetbf22m {
    gap: var(--space-14px);
  }
  ._1qhetbf22s {
    gap: var(--space-16px);
  }
  ._1qhetbf22y {
    gap: var(--space-20px);
  }
  ._1qhetbf234 {
    gap: var(--space-24px);
  }
  ._1qhetbf23a {
    gap: var(--space-32px);
  }
  ._1qhetbf23g {
    gap: var(--space-48px);
  }
  ._1qhetbf23m {
    gap: var(--space-56px);
  }
  ._1qhetbf23s {
    gap: var(--space-64px);
  }
  ._1qhetbf23y {
    gap: var(--space-8px);
  }
  ._1qhetbf244 {
    gap: var(--space-12px);
  }
  ._1qhetbf24a {
    gap: var(--space-24px);
  }
  ._1qhetbf24g {
    row-gap: var(--space-0px);
  }
  ._1qhetbf24m {
    row-gap: var(--space-1rem);
  }
  ._1qhetbf24s {
    row-gap: var(--space-1px);
  }
  ._1qhetbf24y {
    row-gap: var(--space-2px);
  }
  ._1qhetbf254 {
    row-gap: var(--space-6px);
  }
  ._1qhetbf25a {
    row-gap: var(--space-4px);
  }
  ._1qhetbf25g {
    row-gap: var(--space-8px);
  }
  ._1qhetbf25m {
    row-gap: var(--space-12px);
  }
  ._1qhetbf25s {
    row-gap: var(--space-14px);
  }
  ._1qhetbf25y {
    row-gap: var(--space-16px);
  }
  ._1qhetbf264 {
    row-gap: var(--space-20px);
  }
  ._1qhetbf26a {
    row-gap: var(--space-24px);
  }
  ._1qhetbf26g {
    row-gap: var(--space-32px);
  }
  ._1qhetbf26m {
    row-gap: var(--space-48px);
  }
  ._1qhetbf26s {
    row-gap: var(--space-56px);
  }
  ._1qhetbf26y {
    row-gap: var(--space-64px);
  }
  ._1qhetbf274 {
    row-gap: var(--space-8px);
  }
  ._1qhetbf27a {
    row-gap: var(--space-12px);
  }
  ._1qhetbf27g {
    row-gap: var(--space-24px);
  }
  ._1qhetbf27m {
    column-gap: var(--space-0px);
  }
  ._1qhetbf27s {
    column-gap: var(--space-1rem);
  }
  ._1qhetbf27y {
    column-gap: var(--space-1px);
  }
  ._1qhetbf284 {
    column-gap: var(--space-2px);
  }
  ._1qhetbf28a {
    column-gap: var(--space-6px);
  }
  ._1qhetbf28g {
    column-gap: var(--space-4px);
  }
  ._1qhetbf28m {
    column-gap: var(--space-8px);
  }
  ._1qhetbf28s {
    column-gap: var(--space-12px);
  }
  ._1qhetbf28y {
    column-gap: var(--space-14px);
  }
  ._1qhetbf294 {
    column-gap: var(--space-16px);
  }
  ._1qhetbf29a {
    column-gap: var(--space-20px);
  }
  ._1qhetbf29g {
    column-gap: var(--space-24px);
  }
  ._1qhetbf29m {
    column-gap: var(--space-32px);
  }
  ._1qhetbf29s {
    column-gap: var(--space-48px);
  }
  ._1qhetbf29y {
    column-gap: var(--space-56px);
  }
  ._1qhetbf2a4 {
    column-gap: var(--space-64px);
  }
  ._1qhetbf2aa {
    column-gap: var(--space-8px);
  }
  ._1qhetbf2ag {
    column-gap: var(--space-12px);
  }
  ._1qhetbf2am {
    column-gap: var(--space-24px);
  }
  ._1qhetbf2as {
    grid-auto-rows: auto;
  }
  ._1qhetbf2ay {
    opacity: 0.5;
  }
  ._1qhetbf2b4 {
    opacity: 0.6;
  }
  ._1qhetbf2ba {
    line-height: var(--space-16px);
  }
  ._1qhetbf2bg {
    white: var(--colors-light-white);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    background-alt3: var(--colors-light-backgroundAlt3);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    input-third: var(--colors-light-inputThird);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
    gradient-obsidian: var(--colors-light-gradientObsidian);
  }
  ._1qhetbf2bm {
    white: var(--colors-dark-white);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    background-alt3: var(--colors-dark-backgroundAlt3);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    input-third: var(--colors-dark-inputThird);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
    gradient-obsidian: var(--colors-dark-gradientObsidian);
  }
  ._1qhetbf2bs {
    border-bottom-color: var(--colors-white);
  }
  ._1qhetbf2by {
    border-bottom-color: var(--colors-failure);
  }
  ._1qhetbf2c4 {
    border-bottom-color: var(--colors-failure33);
  }
  ._1qhetbf2ca {
    border-bottom-color: var(--colors-primary);
  }
  ._1qhetbf2cg {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1qhetbf2cm {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1qhetbf2cs {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1qhetbf2cy {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1qhetbf2d4 {
    border-bottom-color: var(--colors-success);
  }
  ._1qhetbf2da {
    border-bottom-color: var(--colors-success19);
  }
  ._1qhetbf2dg {
    border-bottom-color: var(--colors-warning);
  }
  ._1qhetbf2dm {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1qhetbf2ds {
    border-bottom-color: var(--colors-warning33);
  }
  ._1qhetbf2dy {
    border-bottom-color: var(--colors-binance);
  }
  ._1qhetbf2e4 {
    border-bottom-color: var(--colors-overlay);
  }
  ._1qhetbf2ea {
    border-bottom-color: var(--colors-gold);
  }
  ._1qhetbf2eg {
    border-bottom-color: var(--colors-silver);
  }
  ._1qhetbf2em {
    border-bottom-color: var(--colors-bronze);
  }
  ._1qhetbf2es {
    border-bottom-color: var(--colors-secondary);
  }
  ._1qhetbf2ey {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1qhetbf2f4 {
    border-bottom-color: var(--colors-background);
  }
  ._1qhetbf2fa {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1qhetbf2fg {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1qhetbf2fm {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1qhetbf2fs {
    border-bottom-color: var(--colors-backgroundAlt3);
  }
  ._1qhetbf2fy {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1qhetbf2g4 {
    border-bottom-color: var(--colors-contrast);
  }
  ._1qhetbf2ga {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1qhetbf2gg {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1qhetbf2gm {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1qhetbf2gs {
    border-bottom-color: var(--colors-input);
  }
  ._1qhetbf2gy {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1qhetbf2h4 {
    border-bottom-color: var(--colors-inputThird);
  }
  ._1qhetbf2ha {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1qhetbf2hg {
    border-bottom-color: var(--colors-text);
  }
  ._1qhetbf2hm {
    border-bottom-color: var(--colors-text99);
  }
  ._1qhetbf2hs {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1qhetbf2hy {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1qhetbf2i4 {
    border-bottom-color: var(--colors-disabled);
  }
  ._1qhetbf2ia {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1qhetbf2ig {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1qhetbf2im {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1qhetbf2is {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1qhetbf2iy {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1qhetbf2j4 {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1qhetbf2ja {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1qhetbf2jg {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1qhetbf2jm {
    border-bottom-color: var(--colors-gradientObsidian);
  }
  ._1qhetbf2js {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf2jy {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 1200px) {
  ._1qhetbf5 {
    display: block;
  }
  ._1qhetbfb {
    display: flex;
  }
  ._1qhetbfh {
    display: grid;
  }
  ._1qhetbfn {
    display: inline;
  }
  ._1qhetbft {
    display: inline-flex;
  }
  ._1qhetbfz {
    display: inline-block;
  }
  ._1qhetbf15 {
    display: none;
  }
  ._1qhetbf1b {
    flex-direction: column;
  }
  ._1qhetbf1h {
    flex-direction: row;
  }
  ._1qhetbf1n {
    flex-direction: column-reverse;
  }
  ._1qhetbf1t {
    align-items: center;
  }
  ._1qhetbf1z {
    align-items: end;
  }
  ._1qhetbf25 {
    align-items: baseLine;
  }
  ._1qhetbf2b {
    align-items: inherit;
  }
  ._1qhetbf2h {
    align-items: flex-start;
  }
  ._1qhetbf2n {
    align-items: center;
  }
  ._1qhetbf2t {
    align-items: start;
  }
  ._1qhetbf2z {
    align-items: flex-end;
  }
  ._1qhetbf35 {
    align-items: stretch;
  }
  ._1qhetbf3b {
    align-self: flex-start;
  }
  ._1qhetbf3h {
    align-self: center;
  }
  ._1qhetbf3n {
    align-self: start;
  }
  ._1qhetbf3t {
    align-self: flex-end;
  }
  ._1qhetbf3z {
    align-self: stretch;
  }
  ._1qhetbf45 {
    flex-wrap: wrap;
  }
  ._1qhetbf4b {
    flex-wrap: nowrap;
  }
  ._1qhetbf4h {
    flex-grow: 1;
  }
  ._1qhetbf4n {
    overflow: auto;
  }
  ._1qhetbf4t {
    overflow: hidden;
  }
  ._1qhetbf4z {
    overflow: scroll;
  }
  ._1qhetbf55 {
    overflow: unset;
  }
  ._1qhetbf5b {
    overflow-y: auto;
  }
  ._1qhetbf5h {
    overflow-y: hidden;
  }
  ._1qhetbf5n {
    overflow-y: scroll;
  }
  ._1qhetbf5t {
    overflow-x: auto;
  }
  ._1qhetbf5z {
    overflow-x: hidden;
  }
  ._1qhetbf65 {
    overflow-x: scroll;
  }
  ._1qhetbf6b {
    position: absolute;
  }
  ._1qhetbf6h {
    position: fixed;
  }
  ._1qhetbf6n {
    position: relative;
  }
  ._1qhetbf6t {
    position: sticky;
  }
  ._1qhetbf6z {
    text-align: center;
  }
  ._1qhetbf75 {
    text-align: left;
  }
  ._1qhetbf7b {
    text-align: right;
  }
  ._1qhetbf7h {
    justify-content: flex-start;
  }
  ._1qhetbf7n {
    justify-content: center;
  }
  ._1qhetbf7t {
    justify-content: start;
  }
  ._1qhetbf7z {
    justify-content: flex-end;
  }
  ._1qhetbf85 {
    justify-content: stretch;
  }
  ._1qhetbf8b {
    justify-content: space-around;
  }
  ._1qhetbf8h {
    justify-content: space-between;
  }
  ._1qhetbf8n {
    justify-items: flex-start;
  }
  ._1qhetbf8t {
    justify-items: center;
  }
  ._1qhetbf8z {
    justify-items: start;
  }
  ._1qhetbf95 {
    justify-items: flex-end;
  }
  ._1qhetbf9b {
    justify-items: stretch;
  }
  ._1qhetbf9h {
    justify-items: space-around;
  }
  ._1qhetbf9n {
    justify-items: space-between;
  }
  ._1qhetbf9t {
    justify-self: flex-start;
  }
  ._1qhetbf9z {
    justify-self: center;
  }
  ._1qhetbfa5 {
    justify-self: start;
  }
  ._1qhetbfab {
    justify-self: flex-end;
  }
  ._1qhetbfah {
    justify-self: stretch;
  }
  ._1qhetbfan {
    inset: 0px;
  }
  ._1qhetbfat {
    height: var(--space-0px);
  }
  ._1qhetbfaz {
    height: var(--space-1rem);
  }
  ._1qhetbfb5 {
    height: var(--space-1px);
  }
  ._1qhetbfbb {
    height: var(--space-2px);
  }
  ._1qhetbfbh {
    height: var(--space-6px);
  }
  ._1qhetbfbn {
    height: var(--space-4px);
  }
  ._1qhetbfbt {
    height: var(--space-8px);
  }
  ._1qhetbfbz {
    height: var(--space-12px);
  }
  ._1qhetbfc5 {
    height: var(--space-14px);
  }
  ._1qhetbfcb {
    height: var(--space-16px);
  }
  ._1qhetbfch {
    height: var(--space-20px);
  }
  ._1qhetbfcn {
    height: var(--space-24px);
  }
  ._1qhetbfct {
    height: var(--space-32px);
  }
  ._1qhetbfcz {
    height: var(--space-48px);
  }
  ._1qhetbfd5 {
    height: var(--space-56px);
  }
  ._1qhetbfdb {
    height: var(--space-64px);
  }
  ._1qhetbfdh {
    height: 100%;
  }
  ._1qhetbfdn {
    height: auto;
  }
  ._1qhetbfdt {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1qhetbfdz {
    height: 420px;
  }
  ._1qhetbfe5 {
    height: 576px;
  }
  ._1qhetbfeb {
    height: 852px;
  }
  ._1qhetbfeh {
    height: 968px;
  }
  ._1qhetbfen {
    height: 1080px;
  }
  ._1qhetbfet {
    height: 100vh;
  }
  ._1qhetbfez {
    margin-bottom: var(--space-0px);
  }
  ._1qhetbff5 {
    margin-bottom: var(--space-1rem);
  }
  ._1qhetbffb {
    margin-bottom: var(--space-1px);
  }
  ._1qhetbffh {
    margin-bottom: var(--space-2px);
  }
  ._1qhetbffn {
    margin-bottom: var(--space-6px);
  }
  ._1qhetbfft {
    margin-bottom: var(--space-4px);
  }
  ._1qhetbffz {
    margin-bottom: var(--space-8px);
  }
  ._1qhetbfg5 {
    margin-bottom: var(--space-12px);
  }
  ._1qhetbfgb {
    margin-bottom: var(--space-14px);
  }
  ._1qhetbfgh {
    margin-bottom: var(--space-16px);
  }
  ._1qhetbfgn {
    margin-bottom: var(--space-20px);
  }
  ._1qhetbfgt {
    margin-bottom: var(--space-24px);
  }
  ._1qhetbfgz {
    margin-bottom: var(--space-32px);
  }
  ._1qhetbfh5 {
    margin-bottom: var(--space-48px);
  }
  ._1qhetbfhb {
    margin-bottom: var(--space-56px);
  }
  ._1qhetbfhh {
    margin-bottom: var(--space-64px);
  }
  ._1qhetbfhn {
    margin-bottom: auto;
  }
  ._1qhetbfht {
    margin-left: var(--space-0px);
  }
  ._1qhetbfhz {
    margin-left: var(--space-1rem);
  }
  ._1qhetbfi5 {
    margin-left: var(--space-1px);
  }
  ._1qhetbfib {
    margin-left: var(--space-2px);
  }
  ._1qhetbfih {
    margin-left: var(--space-6px);
  }
  ._1qhetbfin {
    margin-left: var(--space-4px);
  }
  ._1qhetbfit {
    margin-left: var(--space-8px);
  }
  ._1qhetbfiz {
    margin-left: var(--space-12px);
  }
  ._1qhetbfj5 {
    margin-left: var(--space-14px);
  }
  ._1qhetbfjb {
    margin-left: var(--space-16px);
  }
  ._1qhetbfjh {
    margin-left: var(--space-20px);
  }
  ._1qhetbfjn {
    margin-left: var(--space-24px);
  }
  ._1qhetbfjt {
    margin-left: var(--space-32px);
  }
  ._1qhetbfjz {
    margin-left: var(--space-48px);
  }
  ._1qhetbfk5 {
    margin-left: var(--space-56px);
  }
  ._1qhetbfkb {
    margin-left: var(--space-64px);
  }
  ._1qhetbfkh {
    margin-left: auto;
  }
  ._1qhetbfkn {
    margin-right: var(--space-0px);
  }
  ._1qhetbfkt {
    margin-right: var(--space-1rem);
  }
  ._1qhetbfkz {
    margin-right: var(--space-1px);
  }
  ._1qhetbfl5 {
    margin-right: var(--space-2px);
  }
  ._1qhetbflb {
    margin-right: var(--space-6px);
  }
  ._1qhetbflh {
    margin-right: var(--space-4px);
  }
  ._1qhetbfln {
    margin-right: var(--space-8px);
  }
  ._1qhetbflt {
    margin-right: var(--space-12px);
  }
  ._1qhetbflz {
    margin-right: var(--space-14px);
  }
  ._1qhetbfm5 {
    margin-right: var(--space-16px);
  }
  ._1qhetbfmb {
    margin-right: var(--space-20px);
  }
  ._1qhetbfmh {
    margin-right: var(--space-24px);
  }
  ._1qhetbfmn {
    margin-right: var(--space-32px);
  }
  ._1qhetbfmt {
    margin-right: var(--space-48px);
  }
  ._1qhetbfmz {
    margin-right: var(--space-56px);
  }
  ._1qhetbfn5 {
    margin-right: var(--space-64px);
  }
  ._1qhetbfnb {
    margin-right: auto;
  }
  ._1qhetbfnh {
    margin-top: var(--space-0px);
  }
  ._1qhetbfnn {
    margin-top: var(--space-1rem);
  }
  ._1qhetbfnt {
    margin-top: var(--space-1px);
  }
  ._1qhetbfnz {
    margin-top: var(--space-2px);
  }
  ._1qhetbfo5 {
    margin-top: var(--space-6px);
  }
  ._1qhetbfob {
    margin-top: var(--space-4px);
  }
  ._1qhetbfoh {
    margin-top: var(--space-8px);
  }
  ._1qhetbfon {
    margin-top: var(--space-12px);
  }
  ._1qhetbfot {
    margin-top: var(--space-14px);
  }
  ._1qhetbfoz {
    margin-top: var(--space-16px);
  }
  ._1qhetbfp5 {
    margin-top: var(--space-20px);
  }
  ._1qhetbfpb {
    margin-top: var(--space-24px);
  }
  ._1qhetbfph {
    margin-top: var(--space-32px);
  }
  ._1qhetbfpn {
    margin-top: var(--space-48px);
  }
  ._1qhetbfpt {
    margin-top: var(--space-56px);
  }
  ._1qhetbfpz {
    margin-top: var(--space-64px);
  }
  ._1qhetbfq5 {
    margin-top: auto;
  }
  ._1qhetbfqb {
    margin: var(--space-0px);
  }
  ._1qhetbfqh {
    margin: var(--space-1rem);
  }
  ._1qhetbfqn {
    margin: var(--space-1px);
  }
  ._1qhetbfqt {
    margin: var(--space-2px);
  }
  ._1qhetbfqz {
    margin: var(--space-6px);
  }
  ._1qhetbfr5 {
    margin: var(--space-4px);
  }
  ._1qhetbfrb {
    margin: var(--space-8px);
  }
  ._1qhetbfrh {
    margin: var(--space-12px);
  }
  ._1qhetbfrn {
    margin: var(--space-14px);
  }
  ._1qhetbfrt {
    margin: var(--space-16px);
  }
  ._1qhetbfrz {
    margin: var(--space-20px);
  }
  ._1qhetbfs5 {
    margin: var(--space-24px);
  }
  ._1qhetbfsb {
    margin: var(--space-32px);
  }
  ._1qhetbfsh {
    margin: var(--space-48px);
  }
  ._1qhetbfsn {
    margin: var(--space-56px);
  }
  ._1qhetbfst {
    margin: var(--space-64px);
  }
  ._1qhetbfsz {
    margin: auto;
  }
  ._1qhetbft5 {
    padding: var(--space-0px);
  }
  ._1qhetbftb {
    padding: var(--space-1rem);
  }
  ._1qhetbfth {
    padding: var(--space-1px);
  }
  ._1qhetbftn {
    padding: var(--space-2px);
  }
  ._1qhetbftt {
    padding: var(--space-6px);
  }
  ._1qhetbftz {
    padding: var(--space-4px);
  }
  ._1qhetbfu5 {
    padding: var(--space-8px);
  }
  ._1qhetbfub {
    padding: var(--space-12px);
  }
  ._1qhetbfuh {
    padding: var(--space-14px);
  }
  ._1qhetbfun {
    padding: var(--space-16px);
  }
  ._1qhetbfut {
    padding: var(--space-20px);
  }
  ._1qhetbfuz {
    padding: var(--space-24px);
  }
  ._1qhetbfv5 {
    padding: var(--space-32px);
  }
  ._1qhetbfvb {
    padding: var(--space-48px);
  }
  ._1qhetbfvh {
    padding: var(--space-56px);
  }
  ._1qhetbfvn {
    padding: var(--space-64px);
  }
  ._1qhetbfvt {
    max-height: var(--space-0px);
  }
  ._1qhetbfvz {
    max-height: var(--space-1rem);
  }
  ._1qhetbfw5 {
    max-height: var(--space-1px);
  }
  ._1qhetbfwb {
    max-height: var(--space-2px);
  }
  ._1qhetbfwh {
    max-height: var(--space-6px);
  }
  ._1qhetbfwn {
    max-height: var(--space-4px);
  }
  ._1qhetbfwt {
    max-height: var(--space-8px);
  }
  ._1qhetbfwz {
    max-height: var(--space-12px);
  }
  ._1qhetbfx5 {
    max-height: var(--space-14px);
  }
  ._1qhetbfxb {
    max-height: var(--space-16px);
  }
  ._1qhetbfxh {
    max-height: var(--space-20px);
  }
  ._1qhetbfxn {
    max-height: var(--space-24px);
  }
  ._1qhetbfxt {
    max-height: var(--space-32px);
  }
  ._1qhetbfxz {
    max-height: var(--space-48px);
  }
  ._1qhetbfy5 {
    max-height: var(--space-56px);
  }
  ._1qhetbfyb {
    max-height: var(--space-64px);
  }
  ._1qhetbfyh {
    max-width: var(--space-0px);
  }
  ._1qhetbfyn {
    max-width: var(--space-1rem);
  }
  ._1qhetbfyt {
    max-width: var(--space-1px);
  }
  ._1qhetbfyz {
    max-width: var(--space-2px);
  }
  ._1qhetbfz5 {
    max-width: var(--space-6px);
  }
  ._1qhetbfzb {
    max-width: var(--space-4px);
  }
  ._1qhetbfzh {
    max-width: var(--space-8px);
  }
  ._1qhetbfzn {
    max-width: var(--space-12px);
  }
  ._1qhetbfzt {
    max-width: var(--space-14px);
  }
  ._1qhetbfzz {
    max-width: var(--space-16px);
  }
  ._1qhetbf105 {
    max-width: var(--space-20px);
  }
  ._1qhetbf10b {
    max-width: var(--space-24px);
  }
  ._1qhetbf10h {
    max-width: var(--space-32px);
  }
  ._1qhetbf10n {
    max-width: var(--space-48px);
  }
  ._1qhetbf10t {
    max-width: var(--space-56px);
  }
  ._1qhetbf10z {
    max-width: var(--space-64px);
  }
  ._1qhetbf115 {
    max-width: 100%;
  }
  ._1qhetbf11b {
    max-width: auto;
  }
  ._1qhetbf11h {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1qhetbf11n {
    max-width: 420px;
  }
  ._1qhetbf11t {
    max-width: 576px;
  }
  ._1qhetbf11z {
    max-width: 852px;
  }
  ._1qhetbf125 {
    max-width: 968px;
  }
  ._1qhetbf12b {
    max-width: 1080px;
  }
  ._1qhetbf12h {
    max-width: none;
  }
  ._1qhetbf12n {
    min-height: var(--space-0px);
  }
  ._1qhetbf12t {
    min-height: var(--space-1rem);
  }
  ._1qhetbf12z {
    min-height: var(--space-1px);
  }
  ._1qhetbf135 {
    min-height: var(--space-2px);
  }
  ._1qhetbf13b {
    min-height: var(--space-6px);
  }
  ._1qhetbf13h {
    min-height: var(--space-4px);
  }
  ._1qhetbf13n {
    min-height: var(--space-8px);
  }
  ._1qhetbf13t {
    min-height: var(--space-12px);
  }
  ._1qhetbf13z {
    min-height: var(--space-14px);
  }
  ._1qhetbf145 {
    min-height: var(--space-16px);
  }
  ._1qhetbf14b {
    min-height: var(--space-20px);
  }
  ._1qhetbf14h {
    min-height: var(--space-24px);
  }
  ._1qhetbf14n {
    min-height: var(--space-32px);
  }
  ._1qhetbf14t {
    min-height: var(--space-48px);
  }
  ._1qhetbf14z {
    min-height: var(--space-56px);
  }
  ._1qhetbf155 {
    min-height: var(--space-64px);
  }
  ._1qhetbf15b {
    min-height: 100%;
  }
  ._1qhetbf15h {
    min-height: auto;
  }
  ._1qhetbf15n {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1qhetbf15t {
    min-height: 420px;
  }
  ._1qhetbf15z {
    min-height: 576px;
  }
  ._1qhetbf165 {
    min-height: 852px;
  }
  ._1qhetbf16b {
    min-height: 968px;
  }
  ._1qhetbf16h {
    min-height: 1080px;
  }
  ._1qhetbf16n {
    min-height: 100vh;
  }
  ._1qhetbf16t {
    min-width: var(--space-0px);
  }
  ._1qhetbf16z {
    min-width: var(--space-1rem);
  }
  ._1qhetbf175 {
    min-width: var(--space-1px);
  }
  ._1qhetbf17b {
    min-width: var(--space-2px);
  }
  ._1qhetbf17h {
    min-width: var(--space-6px);
  }
  ._1qhetbf17n {
    min-width: var(--space-4px);
  }
  ._1qhetbf17t {
    min-width: var(--space-8px);
  }
  ._1qhetbf17z {
    min-width: var(--space-12px);
  }
  ._1qhetbf185 {
    min-width: var(--space-14px);
  }
  ._1qhetbf18b {
    min-width: var(--space-16px);
  }
  ._1qhetbf18h {
    min-width: var(--space-20px);
  }
  ._1qhetbf18n {
    min-width: var(--space-24px);
  }
  ._1qhetbf18t {
    min-width: var(--space-32px);
  }
  ._1qhetbf18z {
    min-width: var(--space-48px);
  }
  ._1qhetbf195 {
    min-width: var(--space-56px);
  }
  ._1qhetbf19b {
    min-width: var(--space-64px);
  }
  ._1qhetbf19h {
    padding-bottom: var(--space-0px);
  }
  ._1qhetbf19n {
    padding-bottom: var(--space-1rem);
  }
  ._1qhetbf19t {
    padding-bottom: var(--space-1px);
  }
  ._1qhetbf19z {
    padding-bottom: var(--space-2px);
  }
  ._1qhetbf1a5 {
    padding-bottom: var(--space-6px);
  }
  ._1qhetbf1ab {
    padding-bottom: var(--space-4px);
  }
  ._1qhetbf1ah {
    padding-bottom: var(--space-8px);
  }
  ._1qhetbf1an {
    padding-bottom: var(--space-12px);
  }
  ._1qhetbf1at {
    padding-bottom: var(--space-14px);
  }
  ._1qhetbf1az {
    padding-bottom: var(--space-16px);
  }
  ._1qhetbf1b5 {
    padding-bottom: var(--space-20px);
  }
  ._1qhetbf1bb {
    padding-bottom: var(--space-24px);
  }
  ._1qhetbf1bh {
    padding-bottom: var(--space-32px);
  }
  ._1qhetbf1bn {
    padding-bottom: var(--space-48px);
  }
  ._1qhetbf1bt {
    padding-bottom: var(--space-56px);
  }
  ._1qhetbf1bz {
    padding-bottom: var(--space-64px);
  }
  ._1qhetbf1c5 {
    padding-left: var(--space-0px);
  }
  ._1qhetbf1cb {
    padding-left: var(--space-1rem);
  }
  ._1qhetbf1ch {
    padding-left: var(--space-1px);
  }
  ._1qhetbf1cn {
    padding-left: var(--space-2px);
  }
  ._1qhetbf1ct {
    padding-left: var(--space-6px);
  }
  ._1qhetbf1cz {
    padding-left: var(--space-4px);
  }
  ._1qhetbf1d5 {
    padding-left: var(--space-8px);
  }
  ._1qhetbf1db {
    padding-left: var(--space-12px);
  }
  ._1qhetbf1dh {
    padding-left: var(--space-14px);
  }
  ._1qhetbf1dn {
    padding-left: var(--space-16px);
  }
  ._1qhetbf1dt {
    padding-left: var(--space-20px);
  }
  ._1qhetbf1dz {
    padding-left: var(--space-24px);
  }
  ._1qhetbf1e5 {
    padding-left: var(--space-32px);
  }
  ._1qhetbf1eb {
    padding-left: var(--space-48px);
  }
  ._1qhetbf1eh {
    padding-left: var(--space-56px);
  }
  ._1qhetbf1en {
    padding-left: var(--space-64px);
  }
  ._1qhetbf1et {
    padding-right: var(--space-0px);
  }
  ._1qhetbf1ez {
    padding-right: var(--space-1rem);
  }
  ._1qhetbf1f5 {
    padding-right: var(--space-1px);
  }
  ._1qhetbf1fb {
    padding-right: var(--space-2px);
  }
  ._1qhetbf1fh {
    padding-right: var(--space-6px);
  }
  ._1qhetbf1fn {
    padding-right: var(--space-4px);
  }
  ._1qhetbf1ft {
    padding-right: var(--space-8px);
  }
  ._1qhetbf1fz {
    padding-right: var(--space-12px);
  }
  ._1qhetbf1g5 {
    padding-right: var(--space-14px);
  }
  ._1qhetbf1gb {
    padding-right: var(--space-16px);
  }
  ._1qhetbf1gh {
    padding-right: var(--space-20px);
  }
  ._1qhetbf1gn {
    padding-right: var(--space-24px);
  }
  ._1qhetbf1gt {
    padding-right: var(--space-32px);
  }
  ._1qhetbf1gz {
    padding-right: var(--space-48px);
  }
  ._1qhetbf1h5 {
    padding-right: var(--space-56px);
  }
  ._1qhetbf1hb {
    padding-right: var(--space-64px);
  }
  ._1qhetbf1hh {
    padding-top: var(--space-0px);
  }
  ._1qhetbf1hn {
    padding-top: var(--space-1rem);
  }
  ._1qhetbf1ht {
    padding-top: var(--space-1px);
  }
  ._1qhetbf1hz {
    padding-top: var(--space-2px);
  }
  ._1qhetbf1i5 {
    padding-top: var(--space-6px);
  }
  ._1qhetbf1ib {
    padding-top: var(--space-4px);
  }
  ._1qhetbf1ih {
    padding-top: var(--space-8px);
  }
  ._1qhetbf1in {
    padding-top: var(--space-12px);
  }
  ._1qhetbf1it {
    padding-top: var(--space-14px);
  }
  ._1qhetbf1iz {
    padding-top: var(--space-16px);
  }
  ._1qhetbf1j5 {
    padding-top: var(--space-20px);
  }
  ._1qhetbf1jb {
    padding-top: var(--space-24px);
  }
  ._1qhetbf1jh {
    padding-top: var(--space-32px);
  }
  ._1qhetbf1jn {
    padding-top: var(--space-48px);
  }
  ._1qhetbf1jt {
    padding-top: var(--space-56px);
  }
  ._1qhetbf1jz {
    padding-top: var(--space-64px);
  }
  ._1qhetbf1k5 {
    font-size: var(--fontSizes-10px);
  }
  ._1qhetbf1kb {
    font-size: var(--fontSizes-12px);
  }
  ._1qhetbf1kh {
    font-size: var(--fontSizes-16px);
  }
  ._1qhetbf1kn {
    font-size: var(--fontSizes-14px);
  }
  ._1qhetbf1kt {
    font-size: var(--fontSizes-20px);
  }
  ._1qhetbf1kz {
    font-size: var(--fontSizes-40px);
  }
  ._1qhetbf1l5 {
    font-size: inherit;
  }
  ._1qhetbf1lb {
    flex: 1 1;
  }
  ._1qhetbf1lh {
    flex: 1 1 auto;
  }
  ._1qhetbf1ln {
    flex: 0 1 auto;
  }
  ._1qhetbf1lt {
    flex: none;
  }
  ._1qhetbf1lz {
    box-shadow: var(--shadows-level1);
  }
  ._1qhetbf1m5 {
    box-shadow: var(--shadows-active);
  }
  ._1qhetbf1mb {
    box-shadow: var(--shadows-success);
  }
  ._1qhetbf1mh {
    box-shadow: var(--shadows-warning);
  }
  ._1qhetbf1mn {
    box-shadow: var(--shadows-danger);
  }
  ._1qhetbf1mt {
    box-shadow: var(--shadows-focus);
  }
  ._1qhetbf1mz {
    box-shadow: var(--shadows-inset);
  }
  ._1qhetbf1n5 {
    box-shadow: var(--shadows-tooltip);
  }
  ._1qhetbf1nb {
    width: var(--space-0px);
  }
  ._1qhetbf1nh {
    width: var(--space-1rem);
  }
  ._1qhetbf1nn {
    width: var(--space-1px);
  }
  ._1qhetbf1nt {
    width: var(--space-2px);
  }
  ._1qhetbf1nz {
    width: var(--space-6px);
  }
  ._1qhetbf1o5 {
    width: var(--space-4px);
  }
  ._1qhetbf1ob {
    width: var(--space-8px);
  }
  ._1qhetbf1oh {
    width: var(--space-12px);
  }
  ._1qhetbf1on {
    width: var(--space-14px);
  }
  ._1qhetbf1ot {
    width: var(--space-16px);
  }
  ._1qhetbf1oz {
    width: var(--space-20px);
  }
  ._1qhetbf1p5 {
    width: var(--space-24px);
  }
  ._1qhetbf1pb {
    width: var(--space-32px);
  }
  ._1qhetbf1ph {
    width: var(--space-48px);
  }
  ._1qhetbf1pn {
    width: var(--space-56px);
  }
  ._1qhetbf1pt {
    width: var(--space-64px);
  }
  ._1qhetbf1pz {
    width: 100%;
  }
  ._1qhetbf1q5 {
    width: auto;
  }
  ._1qhetbf1qb {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1qhetbf1qh {
    width: 420px;
  }
  ._1qhetbf1qn {
    width: 576px;
  }
  ._1qhetbf1qt {
    width: 852px;
  }
  ._1qhetbf1qz {
    width: 968px;
  }
  ._1qhetbf1r5 {
    width: 1080px;
  }
  ._1qhetbf1rb {
    z-index: 0;
  }
  ._1qhetbf1rh {
    z-index: 1;
  }
  ._1qhetbf1rn {
    z-index: 10;
  }
  ._1qhetbf1rt {
    z-index: 20;
  }
  ._1qhetbf1rz {
    z-index: 30;
  }
  ._1qhetbf1s5 {
    z-index: 40;
  }
  ._1qhetbf1sb {
    z-index: 50;
  }
  ._1qhetbf1sh {
    z-index: 75;
  }
  ._1qhetbf1sn {
    z-index: 99;
  }
  ._1qhetbf1st {
    z-index: 100;
  }
  ._1qhetbf1sz {
    z-index: 9;
  }
  ._1qhetbf1t5 {
    z-index: 10;
  }
  ._1qhetbf1tb {
    z-index: 100;
  }
  ._1qhetbf1th {
    z-index: auto;
  }
  ._1qhetbf1tn {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf1tt {
    border-radius: var(--radii-0);
  }
  ._1qhetbf1tz {
    border-radius: var(--radii-8px);
  }
  ._1qhetbf1u5 {
    border-radius: var(--radii-12px);
  }
  ._1qhetbf1ub {
    border-radius: var(--radii-20px);
  }
  ._1qhetbf1uh {
    border-radius: var(--radii-32px);
  }
  ._1qhetbf1un {
    border-radius: var(--radii-small);
  }
  ._1qhetbf1ut {
    border-radius: var(--radii-default);
  }
  ._1qhetbf1uz {
    border-radius: var(--radii-card);
  }
  ._1qhetbf1v5 {
    border-radius: var(--radii-circle);
  }
  ._1qhetbf1vb {
    border-top-left-radius: var(--radii-0);
  }
  ._1qhetbf1vh {
    border-top-left-radius: var(--radii-8px);
  }
  ._1qhetbf1vn {
    border-top-left-radius: var(--radii-12px);
  }
  ._1qhetbf1vt {
    border-top-left-radius: var(--radii-20px);
  }
  ._1qhetbf1vz {
    border-top-left-radius: var(--radii-32px);
  }
  ._1qhetbf1w5 {
    border-top-left-radius: var(--radii-small);
  }
  ._1qhetbf1wb {
    border-top-left-radius: var(--radii-default);
  }
  ._1qhetbf1wh {
    border-top-left-radius: var(--radii-card);
  }
  ._1qhetbf1wn {
    border-top-left-radius: var(--radii-circle);
  }
  ._1qhetbf1wt {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1qhetbf1wz {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1qhetbf1x5 {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1qhetbf1xb {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1qhetbf1xh {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1qhetbf1xn {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1qhetbf1xt {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1qhetbf1xz {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1qhetbf1y5 {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1qhetbf1yb {
    border-top-right-radius: var(--radii-0);
  }
  ._1qhetbf1yh {
    border-top-right-radius: var(--radii-8px);
  }
  ._1qhetbf1yn {
    border-top-right-radius: var(--radii-12px);
  }
  ._1qhetbf1yt {
    border-top-right-radius: var(--radii-20px);
  }
  ._1qhetbf1yz {
    border-top-right-radius: var(--radii-32px);
  }
  ._1qhetbf1z5 {
    border-top-right-radius: var(--radii-small);
  }
  ._1qhetbf1zb {
    border-top-right-radius: var(--radii-default);
  }
  ._1qhetbf1zh {
    border-top-right-radius: var(--radii-card);
  }
  ._1qhetbf1zn {
    border-top-right-radius: var(--radii-circle);
  }
  ._1qhetbf1zt {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1qhetbf1zz {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1qhetbf205 {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1qhetbf20b {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1qhetbf20h {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1qhetbf20n {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1qhetbf20t {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1qhetbf20z {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1qhetbf215 {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1qhetbf21b {
    gap: var(--space-0px);
  }
  ._1qhetbf21h {
    gap: var(--space-1rem);
  }
  ._1qhetbf21n {
    gap: var(--space-1px);
  }
  ._1qhetbf21t {
    gap: var(--space-2px);
  }
  ._1qhetbf21z {
    gap: var(--space-6px);
  }
  ._1qhetbf225 {
    gap: var(--space-4px);
  }
  ._1qhetbf22b {
    gap: var(--space-8px);
  }
  ._1qhetbf22h {
    gap: var(--space-12px);
  }
  ._1qhetbf22n {
    gap: var(--space-14px);
  }
  ._1qhetbf22t {
    gap: var(--space-16px);
  }
  ._1qhetbf22z {
    gap: var(--space-20px);
  }
  ._1qhetbf235 {
    gap: var(--space-24px);
  }
  ._1qhetbf23b {
    gap: var(--space-32px);
  }
  ._1qhetbf23h {
    gap: var(--space-48px);
  }
  ._1qhetbf23n {
    gap: var(--space-56px);
  }
  ._1qhetbf23t {
    gap: var(--space-64px);
  }
  ._1qhetbf23z {
    gap: var(--space-8px);
  }
  ._1qhetbf245 {
    gap: var(--space-12px);
  }
  ._1qhetbf24b {
    gap: var(--space-24px);
  }
  ._1qhetbf24h {
    row-gap: var(--space-0px);
  }
  ._1qhetbf24n {
    row-gap: var(--space-1rem);
  }
  ._1qhetbf24t {
    row-gap: var(--space-1px);
  }
  ._1qhetbf24z {
    row-gap: var(--space-2px);
  }
  ._1qhetbf255 {
    row-gap: var(--space-6px);
  }
  ._1qhetbf25b {
    row-gap: var(--space-4px);
  }
  ._1qhetbf25h {
    row-gap: var(--space-8px);
  }
  ._1qhetbf25n {
    row-gap: var(--space-12px);
  }
  ._1qhetbf25t {
    row-gap: var(--space-14px);
  }
  ._1qhetbf25z {
    row-gap: var(--space-16px);
  }
  ._1qhetbf265 {
    row-gap: var(--space-20px);
  }
  ._1qhetbf26b {
    row-gap: var(--space-24px);
  }
  ._1qhetbf26h {
    row-gap: var(--space-32px);
  }
  ._1qhetbf26n {
    row-gap: var(--space-48px);
  }
  ._1qhetbf26t {
    row-gap: var(--space-56px);
  }
  ._1qhetbf26z {
    row-gap: var(--space-64px);
  }
  ._1qhetbf275 {
    row-gap: var(--space-8px);
  }
  ._1qhetbf27b {
    row-gap: var(--space-12px);
  }
  ._1qhetbf27h {
    row-gap: var(--space-24px);
  }
  ._1qhetbf27n {
    column-gap: var(--space-0px);
  }
  ._1qhetbf27t {
    column-gap: var(--space-1rem);
  }
  ._1qhetbf27z {
    column-gap: var(--space-1px);
  }
  ._1qhetbf285 {
    column-gap: var(--space-2px);
  }
  ._1qhetbf28b {
    column-gap: var(--space-6px);
  }
  ._1qhetbf28h {
    column-gap: var(--space-4px);
  }
  ._1qhetbf28n {
    column-gap: var(--space-8px);
  }
  ._1qhetbf28t {
    column-gap: var(--space-12px);
  }
  ._1qhetbf28z {
    column-gap: var(--space-14px);
  }
  ._1qhetbf295 {
    column-gap: var(--space-16px);
  }
  ._1qhetbf29b {
    column-gap: var(--space-20px);
  }
  ._1qhetbf29h {
    column-gap: var(--space-24px);
  }
  ._1qhetbf29n {
    column-gap: var(--space-32px);
  }
  ._1qhetbf29t {
    column-gap: var(--space-48px);
  }
  ._1qhetbf29z {
    column-gap: var(--space-56px);
  }
  ._1qhetbf2a5 {
    column-gap: var(--space-64px);
  }
  ._1qhetbf2ab {
    column-gap: var(--space-8px);
  }
  ._1qhetbf2ah {
    column-gap: var(--space-12px);
  }
  ._1qhetbf2an {
    column-gap: var(--space-24px);
  }
  ._1qhetbf2at {
    grid-auto-rows: auto;
  }
  ._1qhetbf2az {
    opacity: 0.5;
  }
  ._1qhetbf2b5 {
    opacity: 0.6;
  }
  ._1qhetbf2bb {
    line-height: var(--space-16px);
  }
  ._1qhetbf2bh {
    white: var(--colors-light-white);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    background-alt3: var(--colors-light-backgroundAlt3);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    input-third: var(--colors-light-inputThird);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
    gradient-obsidian: var(--colors-light-gradientObsidian);
  }
  ._1qhetbf2bn {
    white: var(--colors-dark-white);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    background-alt3: var(--colors-dark-backgroundAlt3);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    input-third: var(--colors-dark-inputThird);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
    gradient-obsidian: var(--colors-dark-gradientObsidian);
  }
  ._1qhetbf2bt {
    border-bottom-color: var(--colors-white);
  }
  ._1qhetbf2bz {
    border-bottom-color: var(--colors-failure);
  }
  ._1qhetbf2c5 {
    border-bottom-color: var(--colors-failure33);
  }
  ._1qhetbf2cb {
    border-bottom-color: var(--colors-primary);
  }
  ._1qhetbf2ch {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1qhetbf2cn {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1qhetbf2ct {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1qhetbf2cz {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1qhetbf2d5 {
    border-bottom-color: var(--colors-success);
  }
  ._1qhetbf2db {
    border-bottom-color: var(--colors-success19);
  }
  ._1qhetbf2dh {
    border-bottom-color: var(--colors-warning);
  }
  ._1qhetbf2dn {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1qhetbf2dt {
    border-bottom-color: var(--colors-warning33);
  }
  ._1qhetbf2dz {
    border-bottom-color: var(--colors-binance);
  }
  ._1qhetbf2e5 {
    border-bottom-color: var(--colors-overlay);
  }
  ._1qhetbf2eb {
    border-bottom-color: var(--colors-gold);
  }
  ._1qhetbf2eh {
    border-bottom-color: var(--colors-silver);
  }
  ._1qhetbf2en {
    border-bottom-color: var(--colors-bronze);
  }
  ._1qhetbf2et {
    border-bottom-color: var(--colors-secondary);
  }
  ._1qhetbf2ez {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1qhetbf2f5 {
    border-bottom-color: var(--colors-background);
  }
  ._1qhetbf2fb {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1qhetbf2fh {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1qhetbf2fn {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1qhetbf2ft {
    border-bottom-color: var(--colors-backgroundAlt3);
  }
  ._1qhetbf2fz {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1qhetbf2g5 {
    border-bottom-color: var(--colors-contrast);
  }
  ._1qhetbf2gb {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1qhetbf2gh {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1qhetbf2gn {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1qhetbf2gt {
    border-bottom-color: var(--colors-input);
  }
  ._1qhetbf2gz {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1qhetbf2h5 {
    border-bottom-color: var(--colors-inputThird);
  }
  ._1qhetbf2hb {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1qhetbf2hh {
    border-bottom-color: var(--colors-text);
  }
  ._1qhetbf2hn {
    border-bottom-color: var(--colors-text99);
  }
  ._1qhetbf2ht {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1qhetbf2hz {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1qhetbf2i5 {
    border-bottom-color: var(--colors-disabled);
  }
  ._1qhetbf2ib {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1qhetbf2ih {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1qhetbf2in {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1qhetbf2it {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1qhetbf2iz {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1qhetbf2j5 {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1qhetbf2jb {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1qhetbf2jh {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1qhetbf2jn {
    border-bottom-color: var(--colors-gradientObsidian);
  }
  ._1qhetbf2jt {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1qhetbf2jz {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@keyframes _6h9tv90 {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
._6h9tv92 {
  animation: _6h9tv90 3s ease infinite;
  background-size: 400% 400%;
}
._6h9tv94 {
  display: flex;
}
._6h9tv95 {
  width: 100%;
  margin-bottom: 0;
}
._6h9tv96 {
  max-width: 100%;
}
._6h9tv97 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 10px;
  column-gap: 8px;
}
._6h9tv98 {
  width: 50px;
  height: 50px;
  border-radius: 12px;
}
@media screen and (min-width: 852px) {
  ._6h9tv95 {
    height: 490px;
  }
}
@media screen and (min-width: 576px) {
  ._6h9tv96 {
    max-width: 360px;
  }
  ._6h9tv97 {
    row-gap: 24px;
    column-gap: 16px;
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (min-width: 968px) {
  ._6h9tv95 {
    width: 792px;
  }
  ._6h9tv96 {
    max-width: 408px;
  }
  ._6h9tv97 {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_0d0bd9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_0d0bd9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_0d0bd9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c3bc380753a8436c-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_0d0bd9';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/0484562807a97172-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_0d0bd9';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_0d0bd9 {font-family: '__Poppins_0d0bd9', '__Poppins_Fallback_0d0bd9';font-style: normal
}.__variable_0d0bd9 {--font-poppins: '__Poppins_0d0bd9', '__Poppins_Fallback_0d0bd9'
}

